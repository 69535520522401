import React from "react";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";

const TargetList = ({ userWorkData }) => {
  // console.log("USER WORK DATA: ", userWorkData);
  return (
    <div className="persion-flex workload-block ay-persion-flex">
      {userWorkData.map((item, index) => {
        return (
          <>
            <div className="d-flex" key={index}>
              <UserNameImage
                name={item.firstname}
                image={item.profile_image_thumbnail}
                width="42px"
                height="42px"
              />
              <div className="margin_left">
                <h6>
                  {item.firstname}
                  {item.lastname}
                </h6>
              </div>
            </div>
            <div className="filter-border mb-2 mt-2"></div>
          </>
        );
      })}
    </div>
  );
};

export default TargetList;
