import React, { useEffect, useState } from "react";
import { Select, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getfilterData, getsampleLog } from "../../../store/user/actions";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Link } from "react-router-dom";

const LogFilter = ({
  filterValue,
  handleState,
  selectBox,
  page,
  setIsCleared,
  hiddenInput = { user: false },
}) => {
  const initialValues = {
    userId: "",
    request_id: "",
    sample_collection_id: "",
    sampleCategoryId: "",
    currentPendingNodeId: "",
    nextNodeId: "",
    type: "",
    node_id: "",
    modified_by: "",
    modified_date_form: "",
    modified_date_to: "",
    message: "",
  };
  const rangeDate = [new Date(), new Date()];
  const [formValues, setFormValue] = useState(initialValues);
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getfilterData());
  }, [dispatch]);

  useEffect(() => {
    filterValue(formValues);
  }, [formValues]);

  const onChange = (name, date) => {
    // let data = { ...rangePickerDate };
    // data[name] = date;
    handleState(true);
    setrangePickerDate(date);
    var currentDate = new Date(date[0]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, "0");
    // Format the date in "Y-m-d" format
    var toDate = `${year}-${month}-${day}`;

    var currentDate = new Date(date[1]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, "0");

    // Format the date in "Y-m-d" format
    var fromDate = `${year}-${month}-${day}`;
    let formdata = { ...formValues };
    formdata[name + "from"] = toDate;
    formdata[name + "to"] = fromDate;
    // formdata[name]=date
    setFormValue(formdata);
  };

  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);

    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to trigger the API call after 500ms
    const newTimeout = setTimeout(() => {
      handleState(true); // Call the API with the updated form data
    }, 500);

    setTypingTimeout(newTimeout);
  };

  // const typeOption = Object.entries(filterData.type).map(([value, label]) => ({
  //   value: parseInt(value, 10),
  //   label,
  // }));
  const sampleRequestOption = Object.entries(filterData.sample_request).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const nodeNameOption = Object.entries(filterData.sample_categories).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const userOption = Object.entries(filterData.user).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const typeOption = [
    {
      value: "1",
      label: "Node Active",
    },
    {
      value: "2",
      label: "Node Inactive",
    },
    {
      value: "3",
      label: "Hold",
    },
    {
      value: "4",
      label: "Unhold",
    },
    {
      value: "5",
      label: "Node User Change",
    },
  ];

  // console.log("SELECT BOX FILTER: ", selectBox);

  const handleSubmitFilter = () => {
    dispatch(getsampleLog({ page: page, ...selectBox }));
  };

  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Request Number</label>
          <Select
            defaultValue="Sample Request Number"
            value={formValues.request_id}
            onChange={(e) => handleChange("request_id", e)}
            options={sampleRequestOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, sampleRequestOption) =>
              (sampleRequestOption?.label ?? "").includes(input)
            }
            filterSort={(sampleRequestOption) =>
              (sampleRequestOption?.label ?? "")
                .toLowerCase()
                .localeCompare((sampleRequestOption?.label ?? "").toLowerCase())
            }
          />
        </div>

        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Collection Name</label>
          <Select
            defaultValue="Collection Name"
            value={formValues.sample_collection_id}
            onChange={(e) => handleChange("sample_collection_id", e)}
            options={sampleCollectionOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Type</label>
          <Select
            defaultValue="type"
            value={formValues.type}
            onChange={(e) => handleChange("type", e)}
            options={typeOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Node Name</label>
          <Select
            defaultValue="Next Node"
            value={formValues.node_id}
            onChange={(e) => handleChange("node_id", e)}
            options={sampleNodesOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>

        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Modify By</label>
          <Select
            defaultValue="Next Node"
            value={formValues.modified_by}
            onChange={(e) => handleChange("modified_by", e)}
            options={userOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Modify Date From and To</label>
          <DateRangePicker
            placeholder="Current Planned Date"
            onChange={(e) => onChange("modified_date_", e)}
            value={rangePickerDate}
            yearAriaLabel="Year"
            clearIcon={false}
          />
        </div>

        <div className="col-md-6 col-lg-6 col-12 mb-2">
          <label className="select-label"></label>
          <textarea
            placeholder="Type here"
            className="text_input"
            onChange={(e) => handleChange("message", e.target.value)}
            value={formValues.message}
          />
        </div>
        {/* <div className="col-md-2 col-lg-2 col-2 mb-2">
          <Button onClick={() => handleSubmitFilter()} className="submit-cta">
            Submit
          </Button>
        </div> */}
        <div className="col-md-2 col-lg-2 col-2 mb-2 d-flex flex-column align-items-center justify-content-center">
          <div className="clearDatas pt-0">
            <Link
              onClick={() => {
                setFormValue({ ...initialValues });

                setrangePickerDate([...rangeDate]);
                filterValue(initialValues);
                setIsCleared(true);
              }}
              className="clear_data"
            >
              Clear Data
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogFilter;
