import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Select } from "antd";
import { getcollectionGraph, getfilterData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../component/Loader";

const DonutChart = (props) => {
  const { show } = props;
  const initialValues = {
    sample_collection_id: "",
  };
  const [label, setLablel] = useState([]);
  const [color, setColor] = useState([]);
  const [series, setSeries] = useState([]);
  const [personname, setPersonname] = useState([]);
  const [medal, setMedal] = useState();
  const [formValues, setFormValue] = useState(initialValues);
  const collectionGraph = useSelector((state) => state.collectionGraph);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getcollectionGraph());
  // }, []);

  useEffect(() => {
    if (!show) {
      dispatch(
        getcollectionGraph({
          sampleCollectionId: formValues.sample_collection_id,
        })
      );
    }
  }, [show, dispatch, formValues]);

  // useEffect(() => {
  //   if (show) {
  //     dispatch(getcollectionGraph());
  //   }
  // }, []);

  useEffect(() => {
    dispatch(getfilterData(formValues));
    // props.filterValue(formValues)
  }, [formValues]);

  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  // useEffect(() => {
  //   dispatch(
  //     getcollectionGraph({
  //       sampleCollectionId: formValues.sample_collection_id,
  //     })
  //   );
  // }, [formValues]);

  useEffect(() => {
    const getdata = async () => {
      const personname = [];
      const getmedal = [];
      const reqData = await fetch("");
      const resData = await reqData.json();
      for (let i = 0; i < resData.length; i++) {
        personname.push(resData[i].person);
        getmedal.push(parseInt(reqData[i].medals));
      }
      setPersonname(personname);
      setMedal(getmedal);
    };
    getdata();
  }, []);

  function getRandomColorCode() {
    // Generate random values for the red, green, and blue components
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Convert the decimal values to hexadecimal and format the color code
    const colorCode = `#${red.toString(16)}${green.toString(16)}${blue.toString(
      16
    )}`;

    return colorCode;
  }
  // useEffect(() => {
  //   setSeries([]);
  //   setLablel([]);
  //   setColor([]);
  //   collectionGraph.data.delayByUser.forEach((element) => {
  //     setSeries((prevDataArray) => [
  //       ...prevDataArray,
  //       element.noumberOfInstances,
  //     ]);
  //     setLablel((prevDataArray) => [
  //       ...prevDataArray,
  //       element.name + " - " + element.noumberOfInstances,
  //     ]);
  //     setColor((prevDataArray) => [...prevDataArray, getRandomColorCode()]);
  //   });
  // }, [collectionGraph]);

  useEffect(() => {
    if (collectionGraph.data?.delayByUser) {
      const newSeries = [];
      const newLabels = [];
      const newColors = [];

      collectionGraph.data.delayByUser.forEach((element) => {
        newSeries.push(element.noumberOfInstances);
        newLabels.push(`${element.name} - ${element.id}`);
        newColors.push(getRandomColorCode());
      });

      setSeries(newSeries);
      setLablel(newLabels);
      setColor(newColors);
    }
  }, [collectionGraph]);

  console.log("LABEL: ", label, series);

  return (
    <div className="chartBg mb-4">
      <div className="chart-heading">
        <div>
          <p>Statistics</p>
          <h3>Collection - Delay instance</h3>
        </div>
        <div className="col-lg-2 col-md-2">
          <Select
            width={248}
            background={"#F3F5F6"}
            defaultValue="Collection Name"
            onChange={(e) => handleChange("sample_collection_id", e)}
            options={sampleCollectionOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, sampleCollectionOption) =>
              (sampleCollectionOption?.label ?? "").includes(input)
            }
            filterSort={(sampleCollectionOption) =>
              (sampleCollectionOption?.label ?? "")
                .toLowerCase()
                .localeCompare(
                  (sampleCollectionOption?.label ?? "").toLowerCase()
                )
            }
          />
        </div>
      </div>
      <div className="position-relative">
        {collectionGraph.isLoading && (
          <div className="loader_bg">
            <Loader />
          </div>
        )}
        {/* <ReactApexChart
          type="donut"
          width={745}
          height={345}
          // series={series}
          options={{
            chart: {
              type: "donut",
            },
            plotOptions: {
              pie: {
                donut: {
                  size: "65%",
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      showAlways: true,
                      fontSize: 30,
                      color: "#000",
                      fontWeight: "700",
                      fontFamily: "Poppins",
                    },
                  },
                },
              },
            },
            labels: label,
            dataLabels: {
              enabled: false,
            },
          }}
        /> */}

        {series?.length > 0 || label?.length > 0 ? (
          <ReactApexChart
            type="donut"
            width={745}
            height={345}
            series={series}
            options={{
              chart: {
                type: "donut",
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "65%",
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        showAlways: true,
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "700",
                        fontFamily: "Poppins",
                      },
                    },
                  },
                },
              },
              labels: label, // These labels now contain element.name and element.id
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val; // Return the value as is or customize as needed
                  },
                  title: {
                    formatter: function (seriesName) {
                      return seriesName; // Show only the name, not the ID
                    },
                  },
                },
              },
              legend: {
                show: true,
                labels: {
                  colors: ["#000"],
                  useSeriesColors: false,
                },
              },
            }}
          />
        ) : (
          <div className="no-data">
            <h1>No Data To Show</h1>
            <p>There is no Data in the collection.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DonutChart;
