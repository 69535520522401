import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getcalenderNewDetail } from "../../../store/user/actions";
import { IMAGE_URL } from "../../../BaseUri/BaseUri";

const CalendarSidebar = ({
  onCloseSidebar,
  dateRange,
  type,
  date,
  selectBox,
}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const calenderNewDetail = useSelector((state) => state.calenderNewDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getcalenderNewDetail({
        type: type,
        date: date,
        ...selectBox,
        date_range: dateRange.date_range,
      })
    );
  }, [dateRange, dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredData = Object.entries(calenderNewDetail.data.data)
    .map(([userId, item]) => {
      const filteredTasks = item.task.filter(
        (task) =>
          task.node.toLowerCase().includes(searchQuery) ||
          item.user_name.toLowerCase().includes(searchQuery)
      );

      if (filteredTasks.length > 0) {
        return { ...item, task: filteredTasks };
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);

  return (
    <div className="calendar_sidebar_box">
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search by node or user name..."
      />
      <div className="box_body">
        <div className="close-icon close_calendr-box " onClick={onCloseSidebar}>
          <img src="/Images/closeBlack.svg" alt="Close" />
        </div>

        <div className="accordion mt-4">
          {filteredData.map((item, index) => (
            <div
              key={index}
              className={`accordion-items ${openIndex === index ? "open" : ""}`}
            >
              <div
                className="accordion-headerr"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <div className="header-wrapper">
                  <img src={IMAGE_URL + item.user_image} alt={item.user_name} />
                  <span>{item.user_name}</span>
                </div>
                <div className="arrow-icon">
                  {openIndex === index ? (
                    <img
                      src="/Images/arrow-up.svg"
                      className="arrow_size"
                      alt="Collapse"
                    />
                  ) : (
                    <img
                      className="arrow_size"
                      src="/Images/arrow-down.svg"
                      alt="Expand"
                    />
                  )}
                </div>
              </div>
              {openIndex === index && (
                <div className="accordion-content">
                  {item.task.map((task, idx) => (
                    <div key={idx} className="header-wrapper d-flex mt-4">
                      <img src={IMAGE_URL + task.image} alt={task.node} />
                      <div>
                        <h6>{task.node}</h6>
                        <small>{task.date}</small>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarSidebar;
