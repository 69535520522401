import React, { useEffect, useState } from "react";
import { getfilterData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

const TargetSelect = ({ selectBox }) => {
  const initialValues = {
    collectionId: "",
    nodeId: "",
    userId: "",
    percentage: "",
    originalDateFrom: "",
    originalDateTo: "",
  };

  const [FromPercentage, setFromPercentage] = useState("");
  const [Topercentage, setToPercentage] = useState("");
  const [formValues, setFormValue] = useState(initialValues);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getfilterData(formValues));
  }, [formValues, dispatch]);

  useEffect(() => {
    selectBox(formValues);
  }, [formValues, selectBox]);

  const handleChange = (name, value) => {
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const formattedDate = formatDate(date);
    setFormValue((prev) => ({
      ...prev,
      originalDateFrom: formattedDate,
    }));

    // Reset end date if the new start date is after the current end date
    if (endDate && date > endDate) {
      setEndDate(null);
      setFormValue((prev) => ({ ...prev, originalDateTo: "" })); // Reset ToDate
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const formattedDate = formatDate(date);
    setFormValue((prev) => ({
      ...prev,
      originalDateTo: formattedDate,
    }));
  };

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Format in "YYYY-MM-DD"
  };

  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );

  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));

  const userList = Object.entries(filterData.user).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));

  const percentArray = Array.from({ length: 101 }, (_, index) => index);
  const percentObjects = percentArray.map((value) => ({
    value,
    label: `${value} %`,
  }));

  return (
    <div>
      <div className="row mt-4 mb-4">
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Collection</label>
          <Select
            defaultValue="Collection Name"
            value={formValues.collectionId}
            onChange={(e) => handleChange("collectionId", e)}
            options={sampleCollectionOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Assign User</label>
          <Select
            defaultValue="Collection Name"
            value={formValues.userId}
            onChange={(e) => handleChange("userId", e)}
            options={userList}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Node</label>
          <Select
            defaultValue="Next Node"
            value={formValues.nodeId}
            onChange={(e) => handleChange("nodeId", e)}
            options={sampleNodesOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2 date-picker-row-node-target">
          <label className="select-label">From Date</label>
          <DatePicker
            className="date-picker-node-target"
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()} // Prevent future dates for "From"
            placeholderText="Select Start Date"
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2 date-picker-row-node-target">
          <label className="select-label">To Date</label>
          <br />
          <DatePicker
            className="date-picker-node-target"
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate ? new Date(startDate) : null} // Disable dates before "From"
            maxDate={
              startDate
                ? new Date(startDate).setMonth(startDate.getMonth() + 1)
                : null
            } // Disable dates more than a month after "From"
            placeholderText="Select End Date"
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">
            Highlights columns where targets Competition is from percentage{" "}
          </label>
          <Select
            defaultValue="%"
            onChange={(e) => {
              setFromPercentage(e);
              handleChange("percentage", e);
            }}
            options={percentObjects}
            showSearch
            optionFilterProp="children"
            value={FromPercentage}
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">
            Highlights columns where targets Competition is to percentage{" "}
          </label>
          <Select
            defaultValue="%"
            onChange={(e) => {
              setToPercentage(e);
              handleChange("Topercentage", e);
            }}
            value={Topercentage}
            options={percentObjects}
            showSearch
            optionFilterProp="children"
          />
        </div>
        <div className="clearDatas mb-3">
          <Link
            onClick={() => {
              setFromPercentage("");
              setToPercentage("");
              setStartDate(null);
              setEndDate(null);
              setFormValue(initialValues);
            }}
            className="clear_data"
          >
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TargetSelect;
