import React, { useEffect, useState } from "react";
import {
  getfilterData,
  getsettingParameter,
  getsettingParameterAdd,
  getsettingParameterDelete,
} from "../../../store/user/actions";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

const PredictionParameters = () => {
  const initialValues = [
    {
      id: null,
      text_value: "",
      input_type: "",
      value: "",
    },
  ];
  const [formValues, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState([]);
  // const filterData = useSelector((state) => state.filterData.data);
  const settingParameter = useSelector((state) => state.settingParameter);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getfilterData(formValues));
    // eslint-disable-next-line
  }, [formValues]);

  useEffect(() => {
    dispatch(getsettingParameter({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (settingParameter.isSuccess) {
      const data = settingParameter.data.map((item) => {
        return {
          id: item.id,
          text_value: item.text_value,
          input_type: item.input_type,
          value: item.value,
        };
      });
      setFormValue(data);
    }
  }, [settingParameter]);

  // const handleChange = (name,index, value) => {
  //     let data = [ ...formValues ];
  //     data[index][name] = value;
  //     // setFormValue((prev)=>[...prev,{${prev[index][name]}:value}]);
  //     setFormValue(data);
  //   };
  const handleChange = (name, index, value) => {
    // console.log(name, index, value);

    // Check if the new value already exists in any of the existing form values
    // const isDuplicateValue = formValues.some(
    //   (item, i) => i !== index && item.text_value === value
    // );

    // If the value is a duplicate, don't update the state and display an error message
    // if (isDuplicateValue) {
    //   setFormErrors((prevErrors) => {
    //     const newErrors = [...prevErrors];
    //     newErrors[index] = { ...newErrors[index], [name]: "Duplicate value" };
    //     return newErrors;
    //   });
    // } else {
    // If not a duplicate, update the state
    let data = [...formValues];
    data[index][name] = value;
    setFormValue(data);
    // }
  };

  // const sampleTrailOption = Object.entries(filterData.sample_trail).map(
  //   ([value, label]) => ({
  //     value: value,
  //     label,
  //   })
  // );

  const addInput = () => {
    setFormValue([
      ...formValues,
      { text_value: "", input_type: "", value: "", id: null },
    ]);
  };

  const handleSubmit = () => {
    let hasError = false;

    // console.log("FORMVALUES: ", formValues);

    // Loop through form values to check for duplicates
    formValues.forEach((item, index) => {
      const isDuplicateValue = formValues.some(
        (subItem, subIndex) =>
          index !== subIndex && subItem.text_value === item.text_value
      );

      if (isDuplicateValue) {
        hasError = true;
        setFormErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[index] = {
            ...newErrors[index],
            text_value: "Duplicate value",
          };
          return newErrors;
        });
      }
    });

    if (!hasError) {
      // If no duplicates, do something, e.g., submit the form
      // return alert("Ok");

      let check = validate(formValues);
      // console.log(check);
      setFormErrors(check.errors);
      if (check.isValid) {
        dispatch(getsettingParameterAdd({ data: formValues }));
      }
    }
  };
  // const handleSubmit = () => {
  //   // Check for duplicate values
  //   const duplicateMap = {};
  //   let hasDuplicates = false;

  //   formValues.forEach((item) => {
  //     if (duplicateMap[item.text_value]) {
  //       hasDuplicates = true;
  //     } else {
  //       duplicateMap[item.text_value] = true;
  //     }
  //   });

  //   // If duplicates are found, prevent submission and display an error message
  //   if (hasDuplicates) {
  //     // Display error message
  //     setFormErrors([{ text_value: "Duplicate values found. Cannot submit form." }]);
  //     return; // Prevent form submission
  //   }

  //   // If no duplicates, proceed with form submission
  //   let check = validate(formValues);
  //   setFormErrors(check.errors);
  //   if (check.isValid) {
  //     dispatch(getsettingParameterAdd({ data: formValues }))
  //       .then(() => {
  //         // Handle success
  //       })
  //       .catch((error) => {
  //         if (error.response && error.response.status === 400 && error.response.data.message === "Please change parameter") {
  //           // Display error message for duplicate parameter
  //           setFormErrors([{ text_value: "Duplicate parameter. Please change parameter." }]);
  //         } else {
  //           // Handle other errors
  //           console.error("Error:", error);
  //         }
  //       });
  //   }
  // };

  const removeInput = (index, id) => {
    const oldData = [...formValues];
    oldData.splice(index, 1);
    setFormValue(oldData);
    if (id != null) {
      dispatch(getsettingParameterDelete({ id }));
    }
  };

  const validate = (value) => {
    let isValid = true;
    const errors = [];
    // eslint-disable-next-line
    value.map((item, index) => {
      errors[index] = { id: null, text_value: "", input_type: "", value: "" };

      if (!item.text_value) {
        isValid = false;
        errors[index].text_value = "text value is required ";
      }

      if (!item.input_type) {
        isValid = false;
        errors[index].input_type = "input type is required ";
      }

      if (!item.value) {
        isValid = false;
        errors[index].value = "input type is required ";
      }
    });
    return {
      isValid,
      errors,
    };
  };
  return (
    <div>
      {formValues.map((item, index) => (
        <div className="row">
          <div className="col-md-4 col-lg-4 col-12 mb-2 mt-1 px-4">
            <div className="d-flex justify-content-between align-items-center">
              <label className="select-label-bold">Text Value</label>
              <div className="table_top">
                {
                  // eslint-disable-next-line
                  index == 0 ? (
                    <i
                      className="fa fa-plus-circle"
                      aria-hidden="true"
                      onClick={() => addInput()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-minus-circle"
                      aria-hidden="true"
                      onClick={() => removeInput(index, item.id)}
                    ></i>
                  )
                }
              </div>
            </div>
            <input
              placeholder="Text Value"
              className="input-custom"
              onChange={(e) =>
                handleChange("text_value", index, e.target.value)
              }
              value={item.text_value}
              style={{
                height: "48px",
                width: "100%",
              }}
            />
            <span className="error-msg">{formErrors[index]?.text_value}</span>
          </div>
          <div className="col-md-4 col-lg-4 col-12 mb-2 mt-3">
            <label className="select-label-bold">Input Type</label>
            <Select
              defaultValue="Trial"
              value={item.input_type}
              onChange={(e) => handleChange("input_type", index, e)}
              options={[
                { value: "radio_button", label: "Radio Button" },
                { value: "select", label: "Select" },
                // { value: "check_box", label: "Check box" },
                { value: "input", label: "Input" },
              ]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, sampleTrailOption) =>
                (sampleTrailOption?.label ?? "").includes(input)
              }
              filterSort={(sampleTrailOption) =>
                (sampleTrailOption?.label ?? "")
                  .toLowerCase()
                  .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
              }
            />
            <span className="error-msg">{formErrors[index]?.input_type}</span>
          </div>
          <div className="col-md-4 col-lg-4 col-12 mb-2 mt-3">
            <label className="select-label-bold">Value</label>
            <input
              placeholder="value"
              className="input-custom"
              onChange={(e) => handleChange("value", index, e.target.value)}
              value={item.value}
              style={{
                height: "48px",
                width: "100%",
              }}
            />
            <span className="error-msg">{formErrors[index]?.value}</span>
          </div>
        </div>
      ))}
      <div className="remark-buttons">
        <button
          className="dark-but"
          type="submit"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PredictionParameters;
