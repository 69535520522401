import { Button, Select } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "../CreateFit/CreateFit.css";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategorySizeDataActions,
  getfilterData,
  getMasterGarmentFitUpdateActions,
} from "../../store/user/actions";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import FitTypeAdd from "../Dashboard/component/FitTypeAdd";
import { getToken } from "../../util/Common";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../BaseUri/BaseUri";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

// Convert Array of Object into Dropdown like Format
export const convertDropdown = (data) => {
  return Object.entries(data && data)?.map(([key, value]) => ({
    label: value,
    value: key,
    id: key,
  }));
};

const MasterFit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  //   Filter Data state
  const filterData = useSelector((state) => state.filterData);

  // Category Size Data State
  const getCategorySizeData = useSelector((state) => state.getCategorySizeData);

  const [referenceUrl, setReferenceUrl] = useState([""]);
  const [index, setIndex] = useState([]);
  const [fit_name, setFitName] = useState("");
  const [product_category_id, setproduct_category_id] = useState("");
  //   const [fabric_kind, setfabric_kind] = useState("");
  const [size_id, setsize_id] = useState("");
  //   const [fit_type, setFitType] = useState("");

  const handleAdd = (i) => {
    setReferenceUrl([...referenceUrl, ""]);
    if (!index.includes(i)) {
      setIndex([...index, i]);
    }
  };

  const handleInputChange = (j, value) => {
    const updatedReferenceUrl = [...referenceUrl];
    updatedReferenceUrl[j] = value;
    setReferenceUrl(updatedReferenceUrl);
  };

  const handleRemoveClick = (i) => {
    const updatedReferenceUrl = [...referenceUrl];
    updatedReferenceUrl.splice(i, 1);
    setReferenceUrl(updatedReferenceUrl);

    const updateIndex = [...index];
    updateIndex.splice(i, 1);
    setIndex(updateIndex);
  };

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        // setUploadedImage(acceptedFiles[0]);
        // setDropzoneVisibility(false); // Hide the dropzone
      }
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt=""
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  console.log("FILES:", files[0]);

  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  //   const [master_fit, setMasterFit] = useState("");

  // Dropdown Arr
  const [product_cat, setProductCat] = useState([]);
  //   const [fabric_kind_arr, setFabricKind_arr] = useState([]);
  const [size_arr, setSize_arr] = useState([]);
  const [measurement_unit_arr, setMeasurement_unitArr] = useState([]);
  //   const [fit_type_arr, setFit_Type_arr] = useState([]);
  const [Measurement_Data, setMeasurementData] = useState([]);
  //   const [MasterFit_arr, setMasterFit_arr] = useState([]);

  const [Measurement_type, setMeasurement_type] = useState([]);

  // Dropdown Arr

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let product_categories_arr = convertDropdown(
        filterData?.data?.product_categories
      );
      setProductCat(product_categories_arr);

      let measurement_type = convertDropdown(
        filterData?.data?.measurement_type
      );
      // console.log("MEASUREMENT TYPE: ", measurement_type);
      setMeasurement_type(measurement_type);

      //   let fabric_kind_arr = convertDropdown(filterData?.data?.fabric_kind);
      //   setFabricKind_arr(fabric_kind_arr);

      // let size_arr = convertDropdown(filterData?.data?.size);
      // setSize_arr(size_arr);

      let Measurement_arr = convertDropdown(
        filterData?.data?.measurement_units
      );
      setMeasurement_unitArr(Measurement_arr);

      //   let FitType_arr = convertDropdown(filterData?.data?.fit_type);
      //   const Add_Option = {
      //     label: "Click To Add",
      //     value: "Click To Add",
      //     id: "Click To Add",
      //   };
      //   setFit_Type_arr([Add_Option, ...FitType_arr]);
      //   console.log("FIT Type Arr: ", FitType_arr);

      let Measurement_Data_arr = convertDropdown(
        filterData?.data?.measurement_points
      );
      setMeasurementData(Measurement_Data_arr);

      //   let Master_Fit_arr = convertDropdown(filterData?.data?.master_fit);
      //   console.log("Master Fit: ", Master_Fit_arr);
      //   setMasterFit_arr(Master_Fit_arr);
    }
    // xxxxxxxxxxxx
  }, [filterData]);

  const handleProductCategory = (val) => {
    dispatch(getCategorySizeDataActions(val));
    // console.log("PRODUCT CATEGORY: ", val);
    setproduct_category_id(val);
  };

  //   const handleFabricKind = (val) => {
  //     // console.log("FABRIC KIND: ", val);
  //     setfabric_kind(val);
  //   };

  //   const handleMasterFit = (val) => {
  //     setMasterFit(val);
  //   };

  const handleReferenceSize = (val) => {
    // console.log("REFERENCE SIZE: ", val);
    setsize_id(val);
  };

  //   const handleFitType = (val) => {
  //     if (val === "Click To Add") return setShow(!show);
  //     // console.log("FIT TYPE: ", val);
  //     setFitType(val);
  //   };

  const [measurementRows, setMeasurementRows] = useState([
    {
      id: "", // Added for storing Dynamic IDS NEW CHANGE
      name: "",
      from: "",
      to: "",
      measurement_value: "",
      unit: "",
      measurement_type: "",
      grading: "",
      remark: "",
    },
  ]);

  // console.log("MEASUREMENT ROWS: ", measurementRows);

  const addMeasurementRow = () => {
    setMeasurementRows([
      ...measurementRows,
      {
        id: "",
        name: "",
        from: "",
        to: "",
        measurement_value: "",
        unit: "",
        measurement_type: "",
        grading: "",
        remark: "",
      },
    ]);
  };

  const handleInputChanges = (index, field, value) => {
    const newMeasurementRows = [...measurementRows];
    if (field === "grading") {
      if (value < 0) {
        toast.error("Negative Integer Not allowed..");
        return;
      }
      const measurementValue = parseFloat(
        newMeasurementRows[index].measurement_value
      );
      const inputValue = parseFloat(value);

      if (measurementValue && inputValue > measurementValue) {
        toast.error(
          "Grading value must be less than or equal to Measurement value"
        );
        return;
      }
    }
    newMeasurementRows[index][field] = value;
    setMeasurementRows(newMeasurementRows);
  };

  const handleSelectChange = (index, field, selectedOption) => {
    const newMeasurementRows = [...measurementRows];
    if (field === "from" || field === "to") {
      const otherField = field === "from" ? "to" : "from";
      if (newMeasurementRows[index][otherField] === selectedOption) {
        toast.error("From and To values cannot be the same");
        return;
      }
    }
    newMeasurementRows[index][field] = selectedOption;
    setMeasurementRows(newMeasurementRows);
  };

  // const DeleteMeasureRow = useSelector((state) => state.DeleteMeasureRow);

  // const [isDeleted, setIsDeleted] = useState(false);

  const handleRemoveRow = async (index, ix) => {
    if (ix !== "") {
      const token = await getToken();
      const headers = {
        Authorization: "Bearer " + token,
      };

      axios
        .post(
          BASE_URL + `/sample/master_garment_fit/delete/${id}`,
          {},
          { headers }
        )
        .then((response) => {
          // console.log("RESPONSE STATUS: ", response?.data);
          if (response && response?.data?.status === 1) {
            toast.success(response?.data?.message);
          } else if (response && response?.data?.status === 0) {
            toast.error(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const newMeasurementRows = measurementRows.filter(
        (_, rowIndex) => rowIndex !== index
      );
      setMeasurementRows(newMeasurementRows);
    }
  };

  // useEffect(() => {
  //   if (DeleteMeasureRow && DeleteMeasureRow?.isSuccess === true && isDeleted) {
  //     dispatch(getMasterGarmentFitUpdateActions(id));
  //     setIsDeleted(false);
  //   }
  // }, [DeleteMeasureRow, dispatch, id, isDeleted]);

  const [isLoading, setIsLoading] = useState(false);

  const handleCreateFit = async () => {
    if (!fit_name) {
      toast.error("fit_name is required");
      return;
    }
    if (!product_category_id) {
      toast.error("product_category_id is required");
      return;
    }

    for (let i = 0; i < referenceUrl.length; i++) {
      const row = referenceUrl[i];
      if (!row) {
        toast.error(`Reference URL is required in ${i + 1} Input Box`);
        return;
      }
    }

    if (files.length === 0) {
      toast.error("image_document is required");
      return;
    }

    if (!size_id) {
      toast.error("size_id is required");
      return;
    }

    for (let i = 0; i < measurementRows.length; i++) {
      const row = measurementRows[i];
      if (
        !row.name ||
        !row.from ||
        !row.to ||
        !row.unit ||
        !row.measurement_value ||
        !row.grading ||
        !row.remark
      ) {
        toast.error(`All fields are required in Measurement Row ${i + 1}`);
        return;
      }
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.set("fit_name", fit_name);
    formData.set("product_category_id", product_category_id);
    // formData.set("fit_type_id", fit_type);
    formData.set("size_id", size_id);
    // formData.set("fabric_kind", fabric_kind);
    formData.set("reference_url", referenceUrl.join(", "));
    formData.set("image_document", files[0]);
    measurementRows.forEach((i, index) => {
      formData.append(`measurements[${index}][name]`, i.name);
      formData.append(`measurements[${index}][point_form_id]`, i.from);
      formData.append(`measurements[${index}][point_to_id]`, i.to);
      formData.append(`measurements[${index}][measurement_unit_id]`, i.unit);
      formData.append(
        `measurements[${index}][measurement_type]`,
        i.measurement_type
      );
      formData.append(
        `measurements[${index}][measurements_value]`,
        i.measurement_value
      );
      formData.append(`measurements[${index}][grading]`, i.grading);
      formData.append(`measurements[${index}][remark]`, i.remark);
    });

    const token = await getToken();
    const headers = {
      Authorization: "Bearer " + token,
    };

    axios
      .post(BASE_URL + `/sample/master_garment_fit/add`, formData, { headers })
      .then((response) => {
        if (response && response?.data?.status === 1) {
          toast.success(response?.data?.message);
          navigate("/masterfit");
          setIsLoading(false);
        } else if (response && response?.data?.status === 0) {
          toast.error(response?.data?.data?.error?.fit_name[0]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const [isUpdate_Loading, setIsUpdate_Loading] = useState(false);

  const handleUpdateMasterFit = async () => {
    setIsUpdate_Loading(true);
    const formData = new FormData();
    formData.set("fit_name", fit_name);
    formData.set("product_category_id", product_category_id);
    formData.set("size_id", size_id);
    formData.set("reference_url", referenceUrl.join(", "));
    formData.set("image_document", files[0]);
    measurementRows.forEach((i, index) => {
      if (i.id !== "") {
        formData.append(`measurements[${index}][id]`, i.id);
      }
      formData.append(`measurements[${index}][name]`, i.name);
      formData.append(`measurements[${index}][point_form_id]`, i.from);
      formData.append(`measurements[${index}][point_to_id]`, i.to);
      formData.append(`measurements[${index}][measurement_unit_id]`, i.unit);
      formData.append(
        `measurements[${index}][measurement_type]`,
        i.measurement_type
      );
      formData.append(
        `measurements[${index}][measurements_value]`,
        i.measurement_value
      );
      formData.append(`measurements[${index}][grading]`, i.grading);
      formData.append(`measurements[${index}][remark]`, i.remark);
    });

    // Calling Api
    const token = await getToken();
    const headers = {
      Authorization: "Bearer " + token,
    };

    axios
      .post(BASE_URL + `/sample/master_garment_fit/update/${id}`, formData, {
        headers,
      })
      .then((response) => {
        if (response && response?.data?.status === 1) {
          toast.success(response?.data?.message);
          setIsUpdate_Loading(false);
        } else if (response && response?.data?.status === 0) {
          toast.error(response?.data?.data?.error?.fit_name[0]);
          setIsUpdate_Loading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsUpdate_Loading(false);
      });
  };

  useEffect(() => {
    if (getCategorySizeData && getCategorySizeData?.isSuccess === true) {
      let size_arr = convertDropdown(
        getCategorySizeData && getCategorySizeData?.data
      );
      setSize_arr(size_arr);
    }
  }, [getCategorySizeData]);

  // console.log("CATEGORY SIZE DATA: ", getCategorySizeData);

  // Get Single Master Garment Fit state
  const masterGarmentFitUpdateGet = useSelector(
    (state) => state.masterGarmentFitUpdateGet
  );

  const getCategoryNameById = (id, arr) => {
    const category = arr?.find((cat) => parseInt(cat.id) === parseInt(id));
    // console.log("CATEGORY", arr);
    return category ? category.label : null;
  };

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(getMasterGarmentFitUpdateActions(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (
      masterGarmentFitUpdateGet &&
      masterGarmentFitUpdateGet?.isSuccess === true
    ) {
      if (id !== null && id !== undefined) {
        setFitName(
          masterGarmentFitUpdateGet && masterGarmentFitUpdateGet?.data?.fit_name
        );
        setproduct_category_id(
          masterGarmentFitUpdateGet &&
            masterGarmentFitUpdateGet?.data?.categorie_id
        );

        dispatch(
          getCategorySizeDataActions(
            masterGarmentFitUpdateGet &&
              masterGarmentFitUpdateGet?.data?.categorie_id
          )
        );

        let reference_string =
          masterGarmentFitUpdateGet &&
          masterGarmentFitUpdateGet?.data?.reference_url;
        const urlArray = reference_string
          ?.split(",")
          ?.map((url) => url?.trim());
        // console.log("REFERENCE URL: ", urlArray);
        setReferenceUrl(urlArray);

        setsize_id(
          masterGarmentFitUpdateGet && masterGarmentFitUpdateGet?.data?.type_id
        );

        const transformedMeasurements =
          masterGarmentFitUpdateGet?.data?.measurement?.map((i) => ({
            id: i.id,
            from: i.from_quality_manager_measurement_point_id,
            grading: i.grading,
            measurement_value: i.measurements_value,
            measurement_type: i.measurement_type,
            name: i.name,
            remark: i.remark,
            to: i.to_quality_manager_measurement_point_id,
            unit: i.measurement_unit_id,
          }));

        setMeasurementRows(transformedMeasurements);
      }
    }
  }, [masterGarmentFitUpdateGet, id, dispatch]);

  // console.log("GET VALUE: ", masterGarmentFitUpdateGet?.data?.measurement);
  // console.log("MEASUREMENT ROWS: ", measurementRows);

  return (
    <div className="create-fit-wrapper">
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading heading-row-master-fit">
          <img
            onClick={() => navigate(-1)}
            src="/Images/arrow-left.svg"
            alt="Back_Icon"
          />
          <h2>
            {id !== null && id !== undefined
              ? "Edit Master Fit"
              : "Create a Master Fit"}
          </h2>
        </div>
        <div className="download-but-excel">
          {/* <button>ADD MEASUREMENTS</button> */}
        </div>
      </div>

      {/* Form Component */}
      {masterGarmentFitUpdateGet?.isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <div className="garment-create-fit-container">
          <div className="row">
            <div className="mb-2 input-create-fit">
              <label htmlFor="" className="select-label-bold">
                Fit Name
              </label>
              <br />
              <input
                value={fit_name}
                onChange={(e) => setFitName(e.target.value)}
                type="text"
                name=""
                id=""
              />
            </div>
            <div className="mb-2">
              <label className="select-label-bold">Product Category</label>
              <Select
                placeholder="Product Category"
                value={getCategoryNameById(product_category_id, product_cat)}
                onChange={(val) => handleProductCategory(val)}
                options={product_cat}
                showSearch
                loading={filterData?.isLoading}
                optionFilterProp="children"
              />
            </div>
            {/* <div className="col-md-3 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">Fit type</label>
            <Select
              placeholder="Fit type"
              // value={formValues.sample_category_id}
              onChange={(val) => handleFitType(val)}
              options={fit_type_arr}
              loading={filterData?.isLoading}
              showSearch
              optionFilterProp="children"
            />
          </div> */}
            {/* <div className="col-md-3 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">
              Designed for Fabric Kind
            </label>
            <Select
              placeholder="Designed for Fabric Kind"
              value={fabric_kind}
              onChange={(val) => handleFabricKind(val)}
              options={fabric_kind_arr}
              showSearch
              loading={filterData?.isLoading}
              optionFilterProp="children"
            />
          </div>
          <div className="col-md-3 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">Master Fit</label>
            <Select
              placeholder="Master Fit"
              value={fabric_kind}
              onChange={(val) => handleMasterFit(val)}
              options={MasterFit_arr}
              showSearch
              loading={filterData?.isLoading}
              optionFilterProp="children"
            />
          </div> */}
            {/* <div className="row"> */}
            {referenceUrl &&
              referenceUrl.map((i, j) => (
                <div key={j} className="input-create-fit">
                  <div className="label-row">
                    <label htmlFor="" className="select-label-bold">
                      Fit Reference URL
                    </label>
                    <Button
                      style={{
                        backgroundColor: index.includes(j)
                          ? "rgb(255, 0, 0,0.5)"
                          : "#4549e847",
                      }}
                      onClick={() =>
                        index.includes(j) ? handleRemoveClick(j) : handleAdd(j)
                      }
                      className="plus-icon"
                    >
                      {index.includes(j) ? (
                        <MinusOutlined style={{ fontSize: "10px" }} />
                      ) : (
                        <PlusOutlined style={{ fontSize: "10px" }} />
                      )}
                    </Button>
                  </div>{" "}
                  <input
                    type="text"
                    onChange={(e) => handleInputChange(j, e.target.value)}
                    placeholder="Fit Reference URL"
                    value={i}
                  />
                </div>
              ))}
            {/* </div> */}

            {/* Sample Category Node Parameter Relationship */}
          </div>

          <div className="upload-pdf-row">
            <div className="col-md-3 col-lg-4 col-12 mb-2">
              <div {...getRootProps({ className: "upload-file" })}>
                <input
                  {...getInputProps()}
                  multiple
                  onChange={handleFileChange}
                />
                {files.length === 0 ? (
                  <div>
                    <img src="/Images/cloud.svg" alt="" />
                    <h5>Upload Image</h5>
                    <p>
                      Drag and Drop file here Or <span>Choose File</span>
                    </p>
                    <small>
                      Support formats: JPG, PNG | Minimum size: 5 MB
                    </small>
                  </div>
                ) : (
                  <></>
                )}
                <aside style={thumbsContainer}>{thumbs}</aside>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12 col-12 mb-2">
              <label className="select-label-bold">Reference Size</label>
              <Select
                placeholder="Reference Size"
                value={getCategoryNameById(size_id, size_arr)}
                onChange={(val) => handleReferenceSize(val)}
                options={size_arr}
                loading={filterData?.isLoading}
                showSearch
                optionFilterProp="children"
              />
            </div>
          </div>

          {/* grid row starts here */}
          <div className="add-measurement-grid-row mt-4">
            <div>
              <label htmlFor="" className="select-label-bold">
                Measurement Name
              </label>{" "}
              <br />
            </div>
            <div>
              <label htmlFor="" className="select-label-bold">
                Measurement Point From
              </label>{" "}
              <br />
            </div>
            <div>
              <label htmlFor="" className="select-label-bold">
                Measurement Point To
              </label>{" "}
              <br />
            </div>
            <div>
              <label htmlFor="" className="select-label-bold">
                Measurement Length
              </label>{" "}
              <br />
            </div>
            <div>
              <label htmlFor="" className="select-label-bold">
                Grading
              </label>{" "}
              <br />
            </div>
            <div>
              <label htmlFor="" className="select-label-bold">
                Measurement Type
              </label>{" "}
              <br />
            </div>
            <div>
              <label htmlFor="" className="select-label-bold">
                Remarks
              </label>{" "}
              <br />
            </div>
          </div>

          {measurementRows?.map((row, index) => (
            <div
              className="add-measurement-grid-row measurement-col-row"
              key={index}
            >
              <div>
                <Button onClick={() => handleRemoveRow(index, row.id)}>
                  <MinusOutlined style={{ fontSize: "10px" }} />
                </Button>
              </div>
              <div className="input-create-fit">
                <input
                  type="text"
                  value={row.name}
                  onChange={(e) =>
                    handleInputChanges(index, "name", e.target.value)
                  }
                />
              </div>
              <div>
                <Select
                  placeholder="Measurement Point From"
                  value={row.from}
                  onChange={(e) => handleSelectChange(index, "from", e)}
                  options={Measurement_Data}
                  showSearch
                  optionFilterProp="children"
                />
              </div>
              <div>
                <Select
                  placeholder="Measurement Point To"
                  value={row.to}
                  onChange={(e) => handleSelectChange(index, "to", e)}
                  options={Measurement_Data}
                  showSearch
                  optionFilterProp="children"
                />
              </div>
              <div>
                <div className="input-create-fit d-flexY">
                  <input
                    type="number"
                    value={row.measurement_value}
                    onChange={(e) =>
                      handleInputChanges(
                        index,
                        "measurement_value",
                        e.target.value
                      )
                    }
                  />
                  <Select
                    value={row.unit}
                    onChange={(e) => handleSelectChange(index, "unit", e)}
                    options={measurement_unit_arr}
                    showSearch
                    optionFilterProp="children"
                  />
                </div>
              </div>
              <div className="input-create-fit">
                <input
                  type="number"
                  max={row.measurement_value}
                  value={row.grading}
                  onChange={(e) =>
                    handleInputChanges(index, "grading", e.target.value)
                  }
                />
              </div>
              <div className="input-create-fit">
                <Select
                  value={row.measurement_type}
                  onChange={(e) =>
                    handleSelectChange(index, "measurement_type", e)
                  }
                  options={Measurement_type}
                  showSearch
                  optionFilterProp="children"
                />
              </div>
              <div className="input-create-fit">
                <input
                  type="text"
                  value={row.remark}
                  onChange={(e) =>
                    handleInputChanges(index, "remark", e.target.value)
                  }
                />
              </div>
            </div>
          ))}

          <div className="create-fit-row-cta">
            <div>
              <Button onClick={addMeasurementRow}>Add</Button>
              {id !== null && id !== undefined ? (
                <Button onClick={handleUpdateMasterFit}>
                  {isUpdate_Loading ? <Loader /> : "Update"}
                </Button>
              ) : (
                <Button onClick={handleCreateFit}>
                  {isLoading ? <Loader /> : "submit"}
                </Button>
              )}
            </div>
          </div>

          {/* grid row ends here */}
        </div>
      )}
      {/* Form Component */}

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Fit Type</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              alt=""
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <FitTypeAdd mClose={setShow} />
      </DefaultModal>
    </div>
  );
};

export default MasterFit;
