import { Button } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { handleApiRequest } from "../../../store/user/actions";
import { toast } from "react-toastify";
import Loader from "../../../component/Loader";
import { BASE_URL } from "../../../BaseUri/BaseUri";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100px",
  height: "100px",
  objectFit: "cover",
  overflow: "hidden",
  overflowClipMargin: "unset",
};

const FitTypeAdd = (props) => {
  const [files, setFiles] = useState([]);
  const [fitName, setFitName] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        // setUploadedImage(acceptedFiles[0]);
        // setDropzoneVisibility(false); // Hide the dropzone
      }
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt=""
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (!fitName) {
      toast.error("Fitname is required");
      return;
    }
    if (files?.length === 0) {
      toast.error("Image is required");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.set("name", fitName);
    formData.set("image", files[0]);

    handleApiRequest(BASE_URL + `/sample/fit_type/add`, formData)
      .then((response) => {
        // console.log("RESPONSE DATA: ", response);
        if (
          response &&
          response?.id !== "" &&
          response?.id !== null &&
          response?.id !== undefined
        ) {
          toast.success("Successfully Created");
          setIsLoading(false);
          props.mClose(false);
        } else {
          toast.error(response?.data?.message);
          // console.log("ERROR MESSAGE: ", response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <div className="remark-select mt-1">
      <form>
        <div className="input-create-fit">
          <label htmlFor="" className="select-label-bold">
            Enter Fit Name
          </label>
          <input
            type="text"
            value={fitName}
            onChange={(e) => setFitName(e.target.value)}
          />
        </div>
        <div>
          <div {...getRootProps({ className: "upload-file" })}>
            <input {...getInputProps()} multiple onChange={handleFileChange} />
            {files.length === 0 ? (
              <div>
                <img alt="" src="/Images/cloud.svg" />
                <h5>Upload Image</h5>
                <p>
                  Drag and Drop file here Or <b>Choose file</b>
                </p>
                <small>Support formats: JPG, PNG | Minimum size: 5 MB</small>
              </div>
            ) : (
              <></>
            )}
            <aside style={thumbsContainer}>{thumbs}</aside>
          </div>
        </div>
        <div className="fitType-add-cta">
          <Button onClick={handleSubmit} type="submit">
            {isLoading ? <Loader /> : "SUBMIT"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FitTypeAdd;
