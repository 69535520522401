import React, { useEffect, useState } from "react";
import Table from "../../../component/Table/Table";
import AudioPlayer from "../../ViewSubmissionDetails/component/AudioPlayer";
import { useDispatch, useSelector } from "react-redux";
import {
  getcurrentNodeSubmission,
  getresubmissionRequiestStatus,
} from "../../../store/user/actions";
import { Link } from "react-router-dom";
import IMG_DEFAULT from "../../../no-image.jpg";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { IMAGE_URL } from "../../../BaseUri/BaseUri";

const ViewSubmissonModal = ({
  id,
  trainId,
  status,
  setViewNode,
  refreshdata,
}) => {
  const [tableData, setTableData] = useState([]);
  const currentNodeSubmission = useSelector(
    (state) => state.currentNodeSubmission
  );
  const resubmissionRequiestStatus = useSelector(
    (state) => state.resubmissionRequiestStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getcurrentNodeSubmission(trainId));
  }, []);

  const statusUpdate = (request_status) => {
    dispatch(
      getresubmissionRequiestStatus({ request_id: id, status: request_status })
    );
  };

  useEffect(() => {
    if (resubmissionRequiestStatus.isSuccess) {
      setViewNode(false);
      refreshdata();
    }
  }, [resubmissionRequiestStatus]);

  const [image, setImage] = useState([]);
  const [ImageView, setImageView] = useState(false);

  useEffect(() => {
    const FakeTableData =
      currentNodeSubmission &&
      currentNodeSubmission.data.map((item, i) => {
        return [
          <div className="currnt-nodes">
            <UserNameImage
              width="40px"
              height="40px"
              margin="0 auto"
              name={item.user_name}
              image={item.user_image}
            />
            <h6>{item.node_name}</h6>
            <h6>{item.user_name}</h6>
          </div>,
          <h6>{item.submit_datetime}</h6>,
          <div>
            <h6>{parseInt(item.delay) < 0 ? 0 : item.delay} Days</h6>
          </div>,
          <div className="view_im">
            {/* splice here */}
            <div className="d-grid-row">
              {item &&
                item.file?.length > 0 &&
                item.file.slice(0, 3).map((i, index) => {
                  // console.log("IXS: ", i);
                  return (
                    <div key={i + index}>
                      <img
                        onClick={() => {
                          setImageView(true);
                          setImage(item?.file);
                        }}
                        src={IMAGE_URL + i}
                        alt="im"
                      />
                    </div>
                  );
                })}
            </div>
            {/* splice here */}
          </div>,
          <div className="audo_io">
            <AudioPlayer audioSrc={IMAGE_URL + item.audio} />
          </div>,
          <h6>{item.remark}</h6>,
          <>
            {status == 0 ? (
              <>
                <div className="view_a_green rejection-submission-cta-row">
                  <Link onClick={() => statusUpdate("approve")}>
                    Rejection Accepted
                  </Link>
                </div>
                <div className="view_a_red rejection-submission-cta-row">
                  <Link onClick={() => statusUpdate("rejected")}>
                    Rejection Declined{" "}
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
          </>,
        ];
      });
    setTableData(FakeTableData);
  }, [currentNodeSubmission]);

  const TemplateTableData = [
    { heading: "Username" },
    { heading: "Submit Date" },
    { heading: "Delay Time" },
    { heading: "Image" },
    { heading: "Voice Note" },
    { heading: "Remark" },
    { heading: "Action" },
  ];

  return (
    <div>
      <div className="">
        <Table
          gridTemplateColumnsHeader="15% 10% 10% 20% 10% 18% 17%"
          gridTemplateColumnsBody="15% 10% 10% 20% 10% 18% 17%"
          columns={TemplateTableData}
          data={tableData}
        />
        {/* <Pagination
          current={current}
          onChange={onChange}
          total={totalData} /> */}
      </div>
      {/* Modal */}
      <DefaultModal
        show={ImageView}
        onHide={() => setImageView(false)}
        className="image-view-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Image View</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setImageView(false)}
              alt=""
            />
          </div>
        </div>
        {/* Image View Modal */}
        {/* <img src={image} className="w-50" alt="" /> */}
        <div className="d-grid-row d-grid-modal-row mt-3">
          {image &&
            image?.length > 0 &&
            image?.map((i, index) => {
              return (
                <div key={i + index} className="mb-5">
                  <img className="w-100" src={IMAGE_URL + i} alt="im" />
                </div>
              );
            })}
        </div>
        {/* Image View Modal */}
      </DefaultModal>
      {/* Modal */}
    </div>
  );
};

export default ViewSubmissonModal;
