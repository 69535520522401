import React, { useCallback, useEffect, useState } from "react";
import RequestSelect from "./component/RequestSelect";
import "./style.css";
import IMG_DEFAULT from "../../no-image.jpg";
import { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import PlusIcon from "../../component/Icon/plus_icon.png";
import { useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from "antd";
import Loader from "../../component/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../util/Common";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import AddProductIdeal from "./component/AddProductIdeal";
import { Helmet } from "react-helmet";
import { BASE_URL, IMAGE_URL } from "../../BaseUri/BaseUri";

const IdealConsumption = () => {
  const { id, isTrue } = useParams();
  const navigate = useNavigate();
  const [IdealConsumption_Arr, setIdealConsumption_Arr] = useState([]);

  const [product_group_id, setProduct_group_id] = useState("");
  const [show, setShow] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });
  // const [files, setFiles] = useState([]);

  // const [qty, setQty] = useState("");
  const [qtyMap, setQtyMap] = useState({});
  // const [product_ids, setProduct_ids] = useState("");

  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleQty = (val, id, pId) => {
    // const updatedArr = [...IdealConsumption_Arr];
    // console.log("UPDATED ARR: ", val, id, pId);
    // setQty(val);
    setQtyMap((prev) => ({
      ...prev,
      [`${id}-${pId}`]: val,
    }));

    // Clear the previous timeout if there's one
    if (typingTimeout) clearTimeout(typingTimeout);

    // Set a new timeout to trigger the API call after a delay
    const newTimeout = setTimeout(() => {
      // Call your API here
      handleEnterPress({ key: "Enter" }, id, pId, val);
    }, 1000); // Delay of 1000ms (1 second)

    setTypingTimeout(newTimeout);
  };

  const [Loading, setLoading] = useState(false);

  // console.log("IDEAL CONSUMPTION ARR: ", IdealConsumption_Arr);

  const handleRedirect = (event, id) => {
    event.preventDefault();
    // toast.success("OK");
    navigate(`/add-consumption-product/${id}/${product_group_id}`);
  };

  // const [isLoading, setIsLoading] = useState(false);

  const handleEnterPress = async (event, id, pId, currentyQty) => {
    if (event.key === "Enter") {
      const formData = new FormData();

      formData.append(`product_ids[0]`, pId);
      formData.append("request_id", product_group_id);
      formData.append("product_sub_group_id", id);
      formData.append("qty", currentyQty);

      // setIsLoading(true);
      await axios({
        method: "POST",
        url: BASE_URL + `/sample/submit_ideal_consumption_product`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        data: formData,
      })
        .then((response) => {
          if (response?.data?.status === 0) {
            toast.error(response?.data?.message);
            // setIsLoading(false);
          } else if (response?.data?.status === 1) {
            toast.success("Data Saved Successfully");
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          // setIsLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const [product_ids, setproduct_ids] = useState("");
  const [product_sub_group_id, setproduct_sub_group_id] = useState("");

  const [prefill_Product_Ideal, setPrefill_Product_Ideal] = useState([]);

  const handleModal = (id, pId, j) => {
    setShow(!show);
    setproduct_ids(id);
    setproduct_sub_group_id(pId);
    // console.log("PRODUCT: ", j);
    setPrefill_Product_Ideal(j);
  };

  // console.log("ID ISTRUE: ", id, isTrue);

  const [sample_req_number, setSample_req_number] = useState("");
  const [style_number, setStyleNumber] = useState("");

  const handleRefreshed = useCallback(async () => {
    const token = await getToken();
    const headers = { Authorization: "Bearer " + token };
    axios
      .post(
        BASE_URL + `/sample/list_ideal_consumption${id ? `${id}` : ""}`,
        {},
        { headers }
      )
      .then((response) => {
        console.log("RESPONSE: ", response?.data?.data);
        setIdealConsumption_Arr(response?.data?.data?.list);
        setSample_req_number(response?.data?.data?.sample_request_id);
        setStyleNumber(response?.data?.data?.style_type_id);
        setProduct_group_id(response?.data?.data?.sample_request_id);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, []);

  useEffect(() => {
    const fetchIdealConsumption = () => {
      const token = getToken();
      const headers = { Authorization: "Bearer " + token };
      axios
        .post(
          BASE_URL + `/sample/list_ideal_consumption/${id}`,
          {},
          { headers }
        )
        .then((response) => {
          console.log("RESPONSE: ", response?.data?.data);
          setIdealConsumption_Arr(response?.data?.data?.list);
          setSample_req_number(response?.data?.data?.sample_request_id);
          setStyleNumber(response?.data?.data?.style_type_id);
          setProduct_group_id(response?.data?.data?.sample_request_id);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    };

    if (id !== null && id !== undefined) {
      fetchIdealConsumption();
    }
  }, [id]);

  // Updated Api

  const [isSubmit, setIsSubmit] = useState(false);

  const handleIdealSubmit = () => {
    setIsSubmit(true);
    const token = getToken();
    const headers = { Authorization: "Bearer " + token };
    axios
      .post(
        BASE_URL + `/sample_request/submisson_ideal_consumption`,
        {
          style_type_id: style_number,
          sample_request_id: sample_req_number,
        },
        { headers }
      )
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsSubmit(false);
        } else if (response?.data?.status === 1) {
          toast.success(response?.data?.message);
          setIsSubmit(false);
          navigate("/pending-task");
        }
        // console.log("RESPONSE: ", response?.data);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
        setIsSubmit(false);
      });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ideal Consumption | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Ideal Consumption</h2>
        </div>
        <div className="download-but">
          <button>
            <img src="Images/document-download.svg" alt="" />
            Download Excel
          </button>
        </div>
      </div>
      <div className="ideal-consumption-wrapper">
        <RequestSelect
          setIdealConsumption_Arr={setIdealConsumption_Arr}
          setProduct_group_id={setProduct_group_id}
          setLoading={setLoading}
          isActives={id}
          sample_req_number={sample_req_number}
          style_number={style_number}
        />

        {/* 70 30 grid col component starts here */}
        {Loading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <div>
            {IdealConsumption_Arr && IdealConsumption_Arr?.length > 0 ? (
              <div>
                {IdealConsumption_Arr &&
                  IdealConsumption_Arr?.length > 0 &&
                  IdealConsumption_Arr?.map((i, index) => (
                    <div key={index} className="grid-ideal-consumption-row">
                      <div>
                        <div className="ideal-img-grid-row">
                          <div>
                            <img
                              src={
                                i.thumbnail !== null
                                  ? IMAGE_URL + i.thumbnail
                                  : IMG_DEFAULT
                              }
                              alt=""
                            />
                            <div className="product-name-ideal-consumption">
                              <p>{i.name}</p>
                            </div>
                          </div>
                          <div>
                            <div
                              {...getRootProps({
                                className: "upload-file redirect-file",
                                onClick: (event) => handleRedirect(event, i.id),
                              })}
                            >
                              <input {...getInputProps()} multiple />
                              <div className="consumption-row">
                                <img src={PlusIcon} alt="" />
                                <h5>Add Consumption Product</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* grid component starts here */}
                        <div className="ideal-consumption-grid-row">
                          {i?.product?.map((j, Jindex) => {
                            console.log(
                              "PRODUCT JINDEX: ",
                              j.critical,
                              j.grouping,
                              j.process_node_id,
                              j.uom
                            );
                            return (
                              <Fragment key={Jindex}>
                                <div className="ideal-consumption-grid-col">
                                  <img
                                    src={
                                      j.image !== null
                                        ? IMAGE_URL + j?.image?.thumbnail
                                        : IMG_DEFAULT
                                    }
                                    alt=""
                                  />
                                  <div className="input-grid-row">
                                    <input
                                      type="text"
                                      // value={j.qty}
                                      value={
                                        qtyMap[`${i.id}-${j.product_id}`] ||
                                        j.qty
                                      }
                                      // onKeyDown={(e) =>
                                      //   handleEnterPress(
                                      //     e,
                                      //     i.id,
                                      //     j.product_id,
                                      //     index
                                      //   )
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handleEnterPress(
                                      //     e,
                                      //     i.id,
                                      //     j.product_id,
                                      //     index
                                      //   )
                                      // }
                                      onChange={(e) =>
                                        handleQty(
                                          e.target.value,
                                          i.id,
                                          j.product_id,
                                          index
                                        )
                                      }
                                    />
                                    <Button
                                      onClick={() =>
                                        handleModal(i.id, j.product_id, j)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </Button>
                                  </div>

                                  {j.consumption_type == null ||
                                  j.critical == null ||
                                  j.grouping == null ||
                                  j.process_node_id == null ||
                                  j.uom == null ||
                                  j.for_mrn == null ? null : (
                                    <p className="red-prefilled-row">
                                      *Prefilled
                                    </p>
                                  )}
                                </div>
                              </Fragment>
                            );
                          })}
                        </div>
                        {/* grid component ends here */}
                      </div>
                    </div>
                  ))}
              </div>
            ) : null}
          </div>
        )}
        {/* 70 30 grid col component ends here */}
        {parseInt(isTrue) === 1 ? (
          <div className="submit-cta-container">
            <Button
              onClick={handleIdealSubmit}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              {isSubmit ? <Loader /> : "Submit"}
            </Button>
          </div>
        ) : null}
      </div>

      {/* Modal Component starts here */}
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>ADD PRODUCT IDEAL</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <AddProductIdeal
          mClose={setShow}
          request_id={product_group_id}
          product_ids={product_ids}
          product_sub_group_id={product_sub_group_id}
          prefill_Product_Ideal={prefill_Product_Ideal}
          handleRefreshed={handleRefreshed}
        />
      </DefaultModal>
      {/* Modal Component ends here */}
    </div>
  );
};

export default IdealConsumption;
