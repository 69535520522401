import React, { useState } from "react";
import LogTable from "./component/LogTable";
import LogFilter from "./component/LogFilter";
import { Helmet } from "react-helmet";

const Logs = () => {
  const [selectBox, setSelectBox] = useState(true);
  const [activePage, setActivePage] = useState(false);

  const [page, setPage] = useState(1);

  const filterValue = (selectValue) => {
    setSelectBox(selectValue);
  };

  const handleState = (val) => {
    setActivePage(val);
  };

  const [isCleared, setIsCleared] = useState(false);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Logs | Sample Dashboard</title>
      </Helmet>
      <div className="heading">
        <h2>Logs</h2>
      </div>
      <LogFilter
        filterValue={filterValue}
        handleState={handleState}
        selectBox={selectBox}
        page={page}
        setIsCleared={setIsCleared}
      />
      <LogTable
        selectBox={selectBox}
        activePage={activePage}
        setActivePage={setActivePage}
        setPage={setPage}
        isCleared={isCleared}
        setIsCleared={setIsCleared}
      />
    </div>
  );
};

export default Logs;
