import React, { useState } from "react";
import IMG_DEFAULT from "../../no-image.jpg";
import { IMAGE_URL } from "../../BaseUri/BaseUri";

const UserNameImage = ({
  image,
  name,
  width,
  height,
  margin,
  title,
  fontSize,
  onData,
  nodeId,
  isDesignationModal,
}) => {
  const getInitials = (name) => {
    const words = name.trim().split(" ");
    const initials = words.slice(0, 2).map((word) => word[0]);
    return initials.join("").toUpperCase();
  };

  const handleDesignationModal = () => {
    onData(!isDesignationModal, nodeId);
  };

  // console.log("IMAGE VIEW X123: ", image);
  return (
    <div style={{ width, height, margin, fontSize }} title={title}>
      {image == "" ? (
        <div onClick={handleDesignationModal} className="user_image_box">
          {getInitials(name)}
        </div>
      ) : (
        <img
          src={IMAGE_URL + image}
          onError={(e) => (e.target.src = IMG_DEFAULT)}
        />
      )}
    </div>
  );
};

export default UserNameImage;
