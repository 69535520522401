import React, { useEffect, useState } from "react";
import {
  getfilterData,
  getsettingNodeTarget,
  getsettingNodeTargetList,
} from "../../../store/user/actions";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../component/Table/Table";

const NodeTarget = () => {
  const initialValues = {
    id: "",
    per_day: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const filterData = useSelector((state) => state.filterData.data);
  const settingNodeTargetList = useSelector(
    (state) => state.settingNodeTargetList
  );
  const settingNodeTarget = useSelector((state) => state.settingNodeTarget);
  const [formErrors, setFormErrors] = useState({});
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getfilterData(formValues));
    dispatch(getsettingNodeTargetList({}));
    // filterValue(formValues)
    // eslint-disable-next-line
  }, [formValues]);

  useEffect(() => {
    // let sno = 0;
    const FakeTableData =
      settingNodeTargetList &&
      settingNodeTargetList.data.map((item, i) => {
        return [
          <>
            <h6>{i + 1} </h6>
          </>,
          <div>
            <h6>{item?.name}</h6>
          </div>,
          <>
            <h6>{item?.target?.par_day}</h6>
          </>,
        ];
      });
    setTableData(FakeTableData);
  }, [settingNodeTargetList]);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  const sample_nodes = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );
  const handleSubmit = () => {
    let check = validate(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
      dispatch(getsettingNodeTarget(formValues));
    }
  };

  useEffect(() => {
    if (settingNodeTarget.isSuccess) {
      dispatch(getsettingNodeTargetList({}));
    }
    // eslint-disable-next-line
  }, [settingNodeTarget]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.id) {
      isValid = false;
      errors.id = "Node is required ";
    }
    if (!values.per_day) {
      isValid = false;
      errors.per_day = "Per Day is required";
    }
    return {
      isValid,
      errors,
    };
  };

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Sample Node Name",
    },
    {
      heading: "Per Day",
    },
  ];

  const isValidNumber = (value) => {
    return /^\d*$/.test(value); // Only allow numeric digits
  };
  // console.log(settingNodeTargetList);
  return (
    <div>
      <div className="row">
        <div className="col-md-4 col-lg-4 col-12 mb-2 px-4">
          <label className="select-label-bold">Select Node</label>
          <Select
            defaultValue="Trial"
            value={formValues.id}
            onChange={(e) => handleChange("id", e)}
            options={sample_nodes}
            showSearch
            optionFilterProp="children"
            filterOption={(input, sampleTrailOption) =>
              (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(sampleTrailOption) =>
              (sampleTrailOption?.label ?? "")
                .toLowerCase()
                .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
            }
          />
          <span className="error-msg">{formErrors.id}</span>
        </div>

        <div className="col-md-4 col-lg-4 col-12 mb-2">
          <label className="select-label-bold">Per Day</label>
          <input
            placeholder="Per Day"
            className="input-custom"
            // onChange={(e) => handleChange("per_day", e.target.value)}
            onChange={(e) => {
              const value = e.target.value;
              if (isValidNumber(value) || value === "") {
                // Only accept input if it's a valid number or an empty string
                if (value !== "0") {
                  handleChange("per_day", value);
                }
              }
            }}
            value={formValues.per_day}
            type="text"
            // min="1"
            style={{
              height: "48px",
              width: "100%",
            }}
          />
          <span className="error-msg">{formErrors.per_day}</span>
        </div>
      </div>
      <div className="remark-buttons">
        <button
          className="dark-but"
          type="submit"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>

      <div className="">
        <Table
          gridTemplateColumnsHeader="33.33% 33.33% 33.33%"
          gridTemplateColumnsBody="33.33% 33.33% 33.33%"
          columns={TemplateTableData}
          data={tableData}
        />
      </div>
    </div>
  );
};

export default NodeTarget;
