import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../component/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { getsampleLog } from "../../../store/user/actions";
import IMG_DEFAULT from "../../../no-image.jpg";
import Loader from "../../../component/Loader";
import { IMAGE_URL } from "../../../BaseUri/BaseUri";

const LogTable = ({
  selectBox,
  activePage = false,
  setActivePage,
  setPage,
  isCleared,
  setIsCleared,
}) => {
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const sampleLog = useSelector((state) => state.sampleLog);
  const dispatch = useDispatch();

  const onChange = (page) => {
    setCurrent(page);
    setPage(page);
    dispatch(getsampleLog({ ...selectBox, page }));
  };

  useEffect(() => {
    if (activePage) {
      dispatch(getsampleLog({ page: 1, ...selectBox }));
      setActivePage(false);
    }

    if (isCleared) {
      dispatch(getsampleLog({ page: 1, ...selectBox }));
      setIsCleared(false);
    }
  }, [dispatch, activePage, isCleared]);

  useEffect(() => {
    dispatch(getsampleLog({ page: 1, ...selectBox }));
  }, [dispatch]);

  useEffect(() => {
    if (activePage) {
      setCurrent(1);
      setActivePage(false);
    }
    setTotal(sampleLog.data.total);
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = sampleLog.data.data.map((item, i) => [
      <>
        <h6>{sno + i}</h6>
      </>,
      <>
        <h6>{item.request_no}</h6>
      </>,

      <>
        <h6>{item.node_name}</h6>
      </>,
      <div className="currnt-nodes">
        <img
          src={IMAGE_URL + item.user_image}
          onError={(e) => (e.target.src = IMG_DEFAULT)}
        />
        <h6>{item.user_name}</h6>
      </div>,
      <>
        <h6>{item.date}</h6>
      </>,
      <div className="logs-but">
        <Link
          className={
            item.type == 1
              ? "NodeActive"
              : item.type == 2
              ? "NodeDeactive"
              : item.type == 3
              ? "NodeDeactive"
              : item.type == 4
              ? "NodeActive"
              : item.type == 5
              ? "NodeActive"
              : ""
          }
        >
          {item.type == 1
            ? "Node Active"
            : item.type == 2
            ? "Node Deactive"
            : item.type == 3
            ? "Node Hold"
            : item.type == 4
            ? "Node Unhold"
            : item.type == 5
            ? "Node User Change"
            : ""}
        </Link>
      </div>,
      <div className="currnt-nodes">
        <img
          src={IMAGE_URL + item.modify_user_image}
          onError={(e) => (e.target.src = IMG_DEFAULT)}
        />
        <h6>{item.modify_user_name}</h6>
      </div>,
      <>
        <h6>{item.modify_date}</h6>
      </>,

      <>
        <h6>{item.message}</h6>
      </>,
    ]);
    setTableData(FakeTableData);
  }, [sampleLog]);

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Request Number",
    },
    {
      heading: "Node Name",
    },
    {
      heading: "User",
    },
    {
      heading: "Date and Time",
    },
    {
      heading: "Type",
    },
    {
      heading: "Modify User",
    },
    {
      heading: "Modify Date and Time",
    },
    {
      heading: "Message",
    },
  ];
  return (
    <div className="position-relative">
      {sampleLog.isLoading && (
        <div className="loader_bg">
          <Loader />
        </div>
      )}
      <div className="table-bg over-scroll-row">
        <Table
          gridTemplateColumnsHeader="5% 12% 12% 20% 15% 15% 20% 22% 20%"
          gridTemplateColumnsBody="5% 12% 12% 20% 15% 15% 20% 22% 20%"
          columns={TemplateTableData}
          data={tableData}
          isActives={true}
        />
        <Pagination current={current} onChange={onChange} total={total} />
      </div>
    </div>
  );
};

export default LogTable;
