import React, { useEffect, useState } from "react";
import DataTable from "../../../component/DataTable/Table.component";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCostRangeMasterList,
  getfilterData,
} from "../../../store/user/actions";
import Loader from "../../../component/Loader";

const Header = [
  "Sr.No.",
  "Cost Range Name",
  "Category",
  "Created By",
  "Created On",
];

const RequestTable = ({
  fit_name,
  categorie_id,
  created_by,
  created_from,
  created_to,
  isRefreshed,
  setIsRefreshed,
}) => {
  const dispatch = useDispatch();

  const costRangeListData = useSelector((state) => state.costRangeList);

  // Filter Data
  // const filterData = useSelector((state) => state.filterData);

  const [page, setPage] = useState(1);

  const onChange = (page) => {
    const params = { page: page };

    if (fit_name) {
      params.costrange_id = fit_name;
    }

    if (categorie_id) {
      params.categorie_id = categorie_id;
    }

    if (created_by) {
      params.created_by = created_by;
    }

    if (created_from) {
      params.created_from = created_from;
    }

    if (created_to) {
      params.created_to = created_to;
    }

    setPage(page);
    dispatch(getCostRangeMasterList(params));
  };

  useEffect(() => {
    dispatch(getCostRangeMasterList({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (isRefreshed) {
      dispatch(getCostRangeMasterList({ page: 1 }));
      setIsRefreshed(false);
    }
  }, [dispatch, isRefreshed]);

  // useEffect(() => {
  //   dispatch(getfilterData({}));
  // }, [dispatch]);

  // console.log("COST RANGE LIST DATA: ", costRangeListData?.data?.data);

  // console.log("FIT NAME: ", fit_name);
  // console.log("CATEGORIE ID: ", categorie_id);
  // console.log("CREATED BY: ", created_by);
  // console.log("CREATED FROM: ", created_from);
  // console.log("CREATED TO: ", created_to);

  useEffect(() => {
    const params = { page: 1 };

    if (fit_name) {
      params.costrange_id = fit_name;
    }

    if (categorie_id) {
      params.categorie_id = categorie_id;
    }

    if (created_by) {
      params.created_by = created_by;
    }

    if (created_from) {
      params.created_from = created_from;
    }

    if (created_to) {
      params.created_to = created_to;
    }

    if (fit_name || categorie_id || created_by || created_from || created_to) {
      dispatch(getCostRangeMasterList(params));
    }
  }, [fit_name, categorie_id, created_by, created_from, created_to]);

  return (
    <div>
      {/* {costRangeListData?.isLoading ? (
        <p>Loading...</p>
      ) : ( */}
      <div className="table-wrapper-pagination">
        {costRangeListData?.isLoading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <>
            <div className="table-container-pagination">
              {costRangeListData?.data?.data &&
              costRangeListData?.data?.data?.length > 0 ? (
                <DataTable
                  header={Header}
                  costRangeList={costRangeListData?.data?.data}
                  itemsPerPage={10}
                  current={page}
                />
              ) : null}
            </div>
            <div className="mt-3">
              <Pagination
                current={costRangeListData?.data?.current_page}
                onChange={onChange}
                total={costRangeListData && costRangeListData?.data?.total}
              />
            </div>
          </>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default RequestTable;
