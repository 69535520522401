import React, { useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getcategoryNode,
  getcreateTrailData,
  gettrailData,
} from "../../../store/user/actions";
import { resettrailDataState } from "../../../store/user/userSlice";

const AddTrail = ({ sampleTrail, trail, trailAddM }) => {
  const initialValues = {
    trail_name: "",
  };

  const [newNode, setNewNode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const trailData = useSelector((state) => state.trailData);
  const createTrailData = useSelector((state) => state.createTrailData);
  const dispatch = useDispatch();

  useEffect(() => {
    setNewNode(false);
    if (trailData.isSuccess) {
      trailAddM(false);
      trail(trailData.data);
      // dispatch(resettrailDataState());
    }
  }, [trailData.isSuccess]);

  const [isNew, setIsNew] = useState(false);

  const handleTrailChange = (value) => {
    // console.log("VALUE: ", value);
    setIsNew(true);
    if (value == 0) {
      setNewNode(true);
    } else {
      dispatch(gettrailData(value));
      dispatch(getcategoryNode({ id: value, trail }));
    }
  };

  // console.log("RECT: ", trailData);

  useEffect(() => {
    if (createTrailData.isSuccess && isNew) {
      trail(createTrailData.data);
      setNewNode(false);
      trailAddM(false);
      setIsNew(false);
    }
  }, [createTrailData, isNew]);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const handleSubmit = (e, name, value) => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    let data = { ...initialValues };
    data[name] = value;
    e.preventDefault();
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
      if (newNode) {
        dispatch(getcreateTrailData({ name: formValues.trail_name }));
      }
    }
  };
  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.trail_name) {
      isValid = false;
      errors.trail_name = "Enter Trail Name";
    }
    return {
      isValid,
      errors,
    };
  };

  return (
    <div className="remark-select">
      <h6>Trail</h6>
      <Select
        defaultValue="Select Trail"
        onChange={handleTrailChange}
        options={sampleTrail}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(option) =>
          (option?.label ?? "")
            .toLowerCase()
            .localeCompare((option?.label ?? "").toLowerCase())
        }
      />
      {newNode ? (
        <div>
          <h6>Trail Name</h6>
          <input
            placeholder="Trail Name"
            className="input-custom form-control"
            onChange={(e) => handleChange("trail_name", e.target.value)}
            style={{
              height: "48px",
              width: "100%",
            }}
          />
          <span className="error-msg">{formErrors.trail_name}</span>
        </div>
      ) : (
        ""
      )}
      <div className="remark-buttons mt-4">
        <button className="dark-but" onClick={handleSubmit}>
          submit
        </button>
      </div>
    </div>
  );
};

export default AddTrail;
