import React, { Fragment, useEffect, useState } from "react";
import { Select } from "antd";
import "../../../css/setting.css";
import axios from "axios";
import { getToken } from "../../../util/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  SendDatesRelationshipData,
  getfilterData,
} from "../../../store/user/actions";
import { convertDropdown } from "../../CreateFit/CreateFit";
import Loader from "../../../component/Loader";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../BaseUri/BaseUri";

const NodeSubmission = () => {
  const dispatch = useDispatch();
  const [relationShip_listDates, setRelationShipListDate] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [sample_NodesArr, setSampleNodesArr] = useState([]);
  const [nodesMap, setNodesMap] = useState({});
  const filterData = useSelector((state) => state.filterData);
  const AddDatesRelationshipData = useSelector(
    (state) => state.AddDatesRelationshipData
  );
  const [isLoading, setIsLoading] = useState(false);

  // Fetch relationship dates
  useEffect(() => {
    const fetchRelationshipDatesList = () => {
      const token = getToken();
      const headers = { Authorization: "Bearer " + token };
      setIsLoading(true);
      axios
        .post(
          BASE_URL + `/sample/important_dates_relationship_list`,
          {},
          { headers }
        )
        .then((response) => {
          const data = response?.data;
          setRelationShipListDate(data);
          const initialSelectedNodes = data.reduce((acc, item) => {
            acc[item.id] = item.nodes; // Store nodes array directly
            return acc;
          }, {});
          setSelectedNodes(initialSelectedNodes);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          setIsLoading(false);
        });
    };

    fetchRelationshipDatesList();
  }, []);

  const findIdByName = (name) => {
    // console.log(name);
    const node = sample_NodesArr.find((node) => node.label === name);
    // console.log(node, "xm");
    return node ? node.id : null;
  };

  // Handle select change
  const handleSelectChange = (value, id) => {
    // console.log(value, id);
    setSelectedNodes((prev) => ({
      ...prev,
      [id]: value.map((nodeId) => ({
        id: findIdByName(nodeId),
        name: nodesMap[nodeId] || nodeId, // Map to correct name
      })),
    }));
  };

  const transformData = (data) => {
    return {
      data: data.map((item) => ({
        id: item.id.toString(), // Convert to string if needed
        node_id: [...new Set(item.node_id.map((node) => parseInt(node.id)))], // Extract IDs and remove duplicates
      })),
    };
  };

  const [isCreated, setIsCreated] = useState(false);
  // Handle form submission
  const handleSubmit = () => {
    const data = relationShip_listDates
      .map((item) => ({
        id: item.id,
        node_id: selectedNodes[item.id] || [], // Map back to IDs
      }))
      .filter((item) => item.node_id.length > 0);

    // console.log("DATA RELATIONSHIP DATES: ", relationShip_listDates);

    const transformedData = transformData(data);

    // console.log("TRANSFORMED DATA: ", transformedData);
    if (data?.length > 0) {
      setIsCreated(true);
      dispatch(SendDatesRelationshipData(transformedData));
    } else {
      toast.error("Please Choose Data");
    }
  };

  // Fetch filter data
  useEffect(() => {
    dispatch(getfilterData());
  }, [dispatch]);

  // console.log(AddDatesRelationshipData, "AddRelationshipData");

  useEffect(() => {
    if (
      AddDatesRelationshipData &&
      AddDatesRelationshipData?.isSuccess === true &&
      isCreated
    ) {
      toast.success("Data Saved Successfully");
      setIsCreated(false);
    }
  }, [AddDatesRelationshipData, isCreated]);

  // Prepare options and nodes map
  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let sample_nodesArr = convertDropdown(filterData?.data?.sample_nodes);
      setSampleNodesArr(sample_nodesArr);

      // Create a map of node ID to node label
      const nodesMap = sample_nodesArr.reduce((acc, node) => {
        acc[node.value] = node.label;
        return acc;
      }, {});
      // console.log("NODES MAP:", nodesMap);
      setNodesMap(nodesMap);
    }
  }, [filterData]);

  // console.log("Relationship Data: ", sample_NodesArr);

  // Helper function to get labels for selected IDs

  return (
    <div>
      {isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <div className="row">
          {relationShip_listDates &&
            relationShip_listDates.map((i) => (
              <Fragment key={i.id}>
                <div className="col-md-4 col-lg-4 col-12 mb-2 px-4">
                  <label className="select-label-bold">{i.name}</label>
                  <Select
                    mode="multiple"
                    options={sample_NodesArr.map((node) => ({
                      value: node.label,
                      label: node.label,
                      id: node.value,
                    }))}
                    placeholder={i.name}
                    value={selectedNodes[i.id]?.map((node) => node.name) || []} // Set selected IDs here
                    onChange={(value) => handleSelectChange(value, i.id)}
                  />
                </div>
              </Fragment>
            ))}
          <div className="remark-buttons category-size-row">
            <button className="dark-but" type="button" onClick={handleSubmit}>
              {AddDatesRelationshipData?.isLoading ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeSubmission;
