import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getsampleCategoryList,
  getsampleRequestAdd,
  getsampleRequestCreate,
  getsampleRequestPreview,
  getsettingParameter,
} from "../../store/user/actions";
import { Helmet } from "react-helmet";
import { Pagination, Select } from "antd";
import Table from "../../component/Table/Table";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import Flow from "./component/Flow";
import {
  resetsampleRequestAddState,
  resetsampleRequestCreateState,
} from "../../store/user/userSlice";
import Loader from "../../component/Loader";

const SampleRequestNode = ({
  files,
  cat_id,
  formValues,
  back,
  sizeData,
  referenceImg,
  referenceUrl,
}) => {
  // const {id,cat_id} = useParams()
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [trail, setTrail] = useState({});
  const [parameter, setParameter] = useState([]);
  const sampleCategoryList = useSelector((state) => state.sampleCategoryList);
  const settingParameter = useSelector((state) => state.settingParameter);
  const sampleRequestPreview = useSelector(
    (state) => state.sampleRequestPreview
  );
  const sampleRequestCreate = useSelector((state) => state.sampleRequestCreate);
  const sampleRequestAdd = useSelector((state) => state.sampleRequestAdd);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setPage(1);
    dispatch(getsampleCategoryList({ sample_category_id: cat_id, page: 1 }));
    dispatch(getsettingParameter({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPage(1);
    dispatch(
      getsampleCategoryList({
        sample_category_id: cat_id,
        parameter: JSON.stringify(parameter),
        page: 1,
      })
    );
    // eslint-disable-next-line
  }, [parameter]);

  useEffect(() => {
    let sno = 1;
    const FakeTableData = sampleCategoryList.data.data.map((item, i) => [
      <>
        <h6>{sno + i} </h6>
      </>,
      <>
        <h6>{item.node_name} </h6>
      </>,
      <>
        {item?.parameter_value?.map((parameter) => (
          <>
            {parameter.name}:{parameter.value},{" "}
          </>
        ))}
      </>,
      <>{item.leadtime}</>,
      <>{item?.before?.join(" | ")}</>,
      <>{item?.after?.join(" | ")}</>,
    ]);
    setTableData(FakeTableData);
  }, [sampleCategoryList]);

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Sample Node Name",
    },
    {
      heading: "Sample Node Parameters Values",
    },
    {
      heading: "Leadtime",
    },
    {
      heading: "Before Node",
    },
    {
      heading: "After Node",
    },
  ];

  // const handleChange = (id, value) => {
  //   const oldData = [...parameter];
  //   const index = oldData.findIndex((obj) => obj.id === id);
  //   if (index >= 0) {
  //     oldData[index] = { id: id, value: value };
  //   } else {
  //     oldData[oldData.length] = { id: id, value: value };
  //   }
  //   setParameter(oldData);
  // };

  const handleChange = (id, selectedOption) => {
    const value = selectedOption ? selectedOption : ""; // Get the value from the selected option
    // console.log("VALUE: ", value, selectedOption);
    setParameter((prevParameters) => {
      const updatedParameters = prevParameters.map((param) => {
        if (param.id === id) {
          return { ...param, value }; // Update the value for the matching id
        }
        return param; // Keep existing parameter if id does not match
      });

      // Check if we need to add a new parameter
      if (!updatedParameters.find((param) => param.id === id)) {
        updatedParameters.push({ id, value }); // Add a new entry if id doesn't exist
      }

      return updatedParameters; // Return the updated array to set state
    });
  };

  const preview = () => {
    dispatch(
      getsampleRequestPreview({
        data: { parameter: JSON.stringify(parameter) },
        id: formValues.sample_cat_id,
      })
    );
  };

  const createRequest = () => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`specsheet[${index}]`, file);
    });
    formData.append("type", formValues.type);
    if (formValues.party_name) {
      formData.append("party_name", formValues.party_name);
    }
    formData.append("sample_collection", formValues.sample_collection);
    formData.append("sample_cat_id", formValues.sample_cat_id);
    formData.append("fast_track", formValues.fast_track);
    formData.append("categorie_id", formValues.categorie_id);
    formData.append("product_group_id", formValues.product_group_id);
    formData.append("garment_fit_id", formValues.garment_fit_id);
    formData.append("brand_id", formValues.brand_id);
    formData.append("sample_cost_range_id", formValues.sample_cost_range_id);
    formData.append("order_qty", formValues.order_qty);
    formData.append("fabric_kind", formValues.fabric_kind);
    formData.append("gender", formValues.gender);
    formData.append("fabric_id", formValues.fabric_id);

    formData.append(
      "reference_url",
      referenceUrl?.map((item) => item).join(", ")
    );
    formData.append("parties_id", formValues.parties_id);
    formData.append("reference_user_by", formValues.reference_user_by);

    sizeData.forEach((size, index) => {
      formData.append(`size[${index}][id]`, size.id);
      formData.append(`size[${index}][quantity]`, size.quantity);
    });
    formData.append("reference_image", referenceImg[0]);
    dispatch(getsampleRequestAdd(formData));
    // dispatch(getsampleRequestCreate({data:{},id}))
  };

  // console.log("FROM VALUES: ", formValues);

  useEffect(() => {
    if (sampleRequestAdd.isSuccess) {
      dispatch(resetsampleRequestAddState());
      dispatch(
        getsampleRequestCreate({
          data: { parameter: JSON.stringify(parameter) },
          id: sampleRequestAdd.data.id,
        })
      );

      navigate("/node-wise-task");
    }
    // eslint-disable-next-line
  }, [sampleRequestAdd]);

  useEffect(() => {
    if (sampleRequestPreview.isSuccess) {
      setShow(true);
      setTrail(sampleRequestPreview.data);
    }
  }, [sampleRequestPreview]);

  useEffect(() => {
    if (sampleRequestCreate.isSuccess) {
      dispatch(resetsampleRequestCreateState());
      navigate("/sample-request-add");
      // window.location.href = '/sample-request-add';
    }
    // eslint-disable-next-line
  }, [sampleRequestCreate]);
  const handleGoBack = () => {
    // Navigate back to the previous item
    // navigate(-1);
    back(false);
  };

  const handleClear = () => {
    setParameter([]);
  };

  // console.log("Parameter: ", parameter);

  const [page, setPage] = useState(1);

  const onChange = (p) => {
    setPage(p);
    dispatch(
      getsampleCategoryList({
        sample_category_id: cat_id,
        parameter: JSON.stringify(parameter),
        page: p,
      })
    );
  };

  // console.log(
  //   "SETTING PARAMETER: ",
  //   sampleCategoryList?.data?.data?.length <= 0
  // );

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sample Request Node</title>
      </Helmet>
      <div className="d-flex align-items-baseline pb-4 sample-request-nodes">
        <div className="heading">
          <div onClick={handleGoBack}>
            <img src="/Images/arrow-left.svg" alt="" />
          </div>
        </div>
        <h2>Sample Request Add</h2>
      </div>
      <div className="request_add">
        {/* <RequestSelect/> */}
        {/* <div className="row">
          {settingParameter.data.map((item, index) => {
            const valuesArray = item.value.split(",");

            const transformedArray = valuesArray.map((value) => ({
              id: value,
              value: value,
            }));
            console.log("ITEMS: ", item);
            return (
              <div className="col-md-3 col-lg-3 col-12 mb-2">
                <label className="select-label-bold">{item.text_value}</label>
                <Select
                  // defaultValue={item.text_value}
                  // value={formValues.sampleRequestNo}
                  value={parameter[index]?.value || ""}
                  onChange={(e) => handleChange(item.id, e)}
                  options={transformedArray}
                  // showSearch
                  optionFilterProp="children"
                  filterOption={(input, sampleRequestOption) =>
                    ((sampleRequestOption?.label).toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleRequestOption) =>
                    (sampleRequestOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleRequestOption?.label ?? "").toLowerCase()
                      )
                  }
                />
              </div>
            );
          })}
          <p className="clear-btn-action" onClick={handleClear}>
            <span>Clear Data</span>
          </p>
          <div className="remark-buttons">
            <button
              className="dark-but"
              type="button"
              onClick={() => preview()}
            >
              Preview Nodes
            </button>
          </div>
        </div> */}

        <div className="row">
          {settingParameter.data.map((item) => {
            const valuesArray = item.value.split(",");

            const transformedArray = valuesArray.map((value) => ({
              id: value,
              value: value,
            }));

            // Find the value for the current item's ID from parameter state
            const currentParameter = parameter.find(
              (param) => param.id === item.id
            );

            return (
              <div className="col-md-3 col-lg-3 col-12 mb-2" key={item.id}>
                <label className="select-label-bold">{item.text_value}</label>
                <Select
                  value={
                    currentParameter
                      ? {
                          value: currentParameter.value,
                          label: currentParameter.value,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(item.id, selectedOption)
                  }
                  options={transformedArray}
                  optionFilterProp="children"
                  filterOption={(input, sampleRequestOption) =>
                    ((sampleRequestOption?.label).toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleRequestOption) =>
                    (sampleRequestOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleRequestOption?.label ?? "").toLowerCase()
                      )
                  }
                />
              </div>
            );
          })}
          <p className="clear-btn-action" onClick={handleClear}>
            <span>Clear Data</span>
          </p>
          <div className="remark-buttons">
            <button className="dark-but" type="button" onClick={preview}>
              Preview Nodes
            </button>
          </div>
        </div>
      </div>
      <div className="table_text">
        <h4>Based on parameters values following nodes are in activated.</h4>
        <div className="position-relative">
          <div className="table-bg">
            <Table
              gridTemplateColumnsHeader="16.66% 16.66% 16.66% 16.66% 16.66% 16.66%"
              gridTemplateColumnsBody="16.66% 16.66% 16.66% 16.66% 16.66% 16.66%"
              columns={TemplateTableData}
              data={tableData}
            />
            <Pagination
              // pageSize={1}
              current={page}
              onChange={onChange}
              total={sampleCategoryList?.data?.total}
            />

            <div className="text-center create-node-btn mt-3">
              {sampleCategoryList?.data?.data?.length <= 0 ? null : (
                <button
                  className="dark-but"
                  type="button"
                  onClick={() => createRequest()}
                >
                  {sampleRequestAdd?.isLoading ? <Loader /> : "Create Node"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="connection-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          {/* <h4></h4> */}
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              alt=""
              onClick={() => setShow(false)}
            />
          </div>
        </div>

        <Flow sampleNode={trail} />
      </DefaultModal>
    </div>
  );
};

export default SampleRequestNode;
