import { Button, Select } from "antd";
import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getfilterData,
  getsampleRequestCheck,
  getSampleRequestShow,
  SampleRequestUpdate,
} from "../../store/user/actions";
import Loader from "../../component/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resetsampleRequestCheckState } from "../../store/user/userSlice";
import SampleRequestNode from "../SampleRequestNodes/SampleRequestNodes";
import { convertDropdown } from "../CreateFit/CreateFit";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
import { BASE_URL } from "../../BaseUri/BaseUri";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 8,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img = {
  display: "block",
  width: "100%",
  borderRadius: "2px",
  height: "100%",
};

export const Liable_arr = [
  { id: 0, value: "No" },
  { id: 1, value: "Yes" },
];

const SampleRequestAdd = () => {
  const { id } = useParams();
  const initialValues = {
    type: "",
    sample_collection: "",
    sample_cat_id: "",
    fast_track: "",
    categorie_id: "",
    product_group_id: "",
    garment_fit_id: "",
    brand_id: "",
    sample_cost_range_id: "",
    order_qty: "",
    fabric_kind: "",
    gender: "",
    fabric_id: "",
    parties_id: "",
    reference_user_by: "",
    party_name: "",
  };

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValue] = useState(initialValues);
  const [files, setFiles] = useState([]);
  const [referenceImg, setReferenceImg] = useState([]);
  const filterData = useSelector((state) => state.filterData.data);

  const Loading = useSelector((state) => state.filterData.isLoading);

  const sampleRequestShow = useSelector((state) => state.sampleRequestShow);

  const sampleRequestAdd = useSelector((state) => state.sampleRequestAdd);
  const sampleRequestCheck = useSelector((state) => state.sampleRequestCheck);
  // eslint-disable-next-line
  const [uploadedImage, setUploadedImage] = useState(null);
  // eslint-disable-next-line
  const [isDropzoneVisible, setDropzoneVisibility] = useState(true);
  const [sapleNode, setSampleNode] = useState(false);
  // eslint-disable-next-line
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getSampleRequestShow(id));
    }
  }, [id, dispatch]);

  const sampleCategoriesOption = Object.entries(
    filterData.sample_categories
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));

  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));

  const [productCatArr, setProductCatArr] = useState([]);
  const [productGroupArr, setProductGroupArr] = useState([]);
  const [fitSelectionArr, setFitSelectionArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [costRangeArr, setCostRangeArr] = useState([]);
  const [fabricKindArr, setFabricKindArr] = useState([]);
  const [fabricNameArr, setFabricNameArr] = useState([]);
  const [partiesArr, setPartiesArr] = useState([]);
  const [usersArr, setUsersArr] = useState([]);

  const [index, setIndex] = useState([]);
  const [referenceUrl, setReferenceUrl] = useState([""]);
  const [genderArr] = useState([
    {
      id: "Male",
      value: "Male",
      label: "Male",
    },
    {
      id: "Female",
      value: "Female",
      label: "Female",
    },
  ]);

  const handleAdd = (i) => {
    setReferenceUrl([...referenceUrl, ""]);
    if (!index.includes(i)) {
      setIndex([...index, i]);
    }
  };

  const handleInputChange = (j, value) => {
    const updatedReferenceUrl = [...referenceUrl];
    updatedReferenceUrl[j] = value;
    setReferenceUrl(updatedReferenceUrl);
  };

  const handleRemoveClick = (i) => {
    const updatedReferenceUrl = [...referenceUrl];
    updatedReferenceUrl.splice(i, 1);
    setReferenceUrl(updatedReferenceUrl);

    const updateIndex = [...index];
    updateIndex.splice(i, 1);
    setIndex(updateIndex);
  };

  useEffect(() => {
    if (filterData && filterData?.product_categories) {
      let productCategories = convertDropdown(filterData?.product_categories);
      setProductCatArr(productCategories);
    }
    if (filterData && filterData?.product_group) {
      let productGroupArr = convertDropdown(filterData?.product_group);
      setProductGroupArr(productGroupArr);
    }
    if (filterData && filterData?.garment_fit) {
      let fitSelectionArr = convertDropdown(filterData?.garment_fit);
      setFitSelectionArr(fitSelectionArr);
    }
    if (filterData && filterData?.brand) {
      let brandArr = convertDropdown(filterData?.brand);
      setBrandArr(brandArr);
    }
    if (filterData && filterData?.cost_range) {
      let costRangeArr = convertDropdown(filterData?.cost_range);
      setCostRangeArr(costRangeArr);
    }
    if (filterData && filterData?.fabric_kind) {
      let FabricKindArr = convertDropdown(filterData?.fabric_kind);
      setFabricKindArr(FabricKindArr);
    }
    if (filterData && filterData?.fabric) {
      let FabricNameArr = convertDropdown(filterData?.fabric);
      setFabricNameArr(FabricNameArr);
    }
    if (filterData && filterData?.party) {
      let partyName = convertDropdown(filterData?.party);
      setPartiesArr(partyName);
    }
    if (filterData && filterData?.user) {
      let userArr = convertDropdown(filterData?.user);
      setUsersArr(userArr);
    }
  }, [filterData]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        setUploadedImage(acceptedFiles[0]);
        setDropzoneVisibility(false); // Hide the dropzone
      }
    },
  });

  const { getRootProps: getRootPropsRef, getInputProps: getInputPropsRef } =
    useDropzone({
      accept: {
        "image/*": [],
      },
      multiple: true,
      onDrop: (acceptedFiles) => {
        const mappedFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setReferenceImg(mappedFiles);
      },
    });

  const referenceThumbs = referenceImg.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt=""
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  // const handleReferenceFileChange = (e) => {
  //   const selectedFiles = e.target.files;
  //   setReferenceImg([...selectedFiles]);
  // };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt=""
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const [sizes, setSizes] = useState([]);
  const [sizeData, setSizeData] = useState([]);

  const handleChange = async (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);

    if (name === "categorie_id") {
      const token = await getToken();
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios
        .post(
          BASE_URL + `/sample/single_category_size_ratio_list/${value}`,
          {},
          { headers }
        )
        .then((response) => {
          // console.log(response?.data?.size);
          // console.log("RESPONSE: ", response?.data?.size);
          // const sizes =
          //   response?.data?.size?.map((size) => ({
          //     id: size.id,
          //     name: size.name,
          //     quantity: size.ratio,
          //   })) || [];

          // setSizes(sizes);
          // setSizeData(sizes);
          const sizes =
            response?.data?.size
              ?.filter((size) => size.ratio > 0) // Filter sizes with ratio > 0
              .map((size) => ({
                id: size.id,
                name: size.name,
                quantity: size.ratio,
              })) || [];

          setSizes(sizes);
          setSizeData(sizes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleInputSizeChange = (index, event) => {
    const newSizeData = [...sizeData];
    newSizeData[index].quantity = event.target.value;
    setSizeData(newSizeData);
  };

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    /*if (!values.sample_node_id) {
          let err = 0;
          if (!values.node_name) {
            isValid = false;
            errors.node_name = "Enter node name is required";
            err = 1;
          } else if (!values.node_group) {
            isValid = false;
            errors.node_group = "Select node group is required";
            err = 1;
          }
          if (err = 0) {
            isValid = false;
            errors.sample_node_id = "select node is required";
          }
        }*/

    if (!values.type) {
      isValid = false;
      errors.type = "Type is required ";
      // eslint-disable-next-line
    } else if (values.type == "External") {
      if (!values.party_name) {
        isValid = false;
        errors.party_name = "Party Name is required ";
      }
    }
    if (!values.sample_collection) {
      isValid = false;
      errors.sample_collection = "Sample Collection is required ";
    }
    if (!values.fast_track) {
      isValid = false;
      errors.fast_track = "Fast Track is required";
    }
    if (!values.sample_cat_id) {
      isValid = false;
      errors.sample_cat_id = "Sample Category is required ";
    }
    if (!values.categorie_id) {
      isValid = false;
      errors.categorie_id = "Product Category is required";
    }
    if (!values.product_group_id) {
      isValid = false;
      errors.product_group_id = "Product Group is required";
    }
    if (!values.garment_fit_id) {
      isValid = false;
      errors.garment_fit_id = "Fit selection is required";
    }
    if (!values.brand_id) {
      isValid = false;
      errors.brand_id = "Brand is required";
    }
    if (!values.sample_cost_range_id) {
      isValid = false;
      errors.sample_cost_range_id = "Cost Range is required";
    }
    if (!values.order_qty) {
      isValid = false;
      errors.order_qty = "Order Qty is required";
    }
    if (!values.fabric_kind) {
      isValid = false;
      errors.fabric_kind = "Fabric Kind is required";
    }
    if (!values.gender) {
      isValid = false;
      errors.gender = "Gender is required";
    }
    if (!values.fabric_id) {
      isValid = false;
      errors.fabric_id = "Fabric Name is required";
    }
    // if (!values.cost_price_from) {
    //   isValid = false;
    //   errors.cost_price_from = "Cost Price From is required ";
    // }

    // if (!values.cost_price_to) {
    //   isValid = false;
    //   errors.cost_price_to = "Cost Price To is required ";
    // }
    // eslint-disable-next-line
    // if (files.length == 0) {
    //   isValid = false;
    //   errors.files = "Image File is required ";
    // }

    // if (referenceImg.length == 0) {
    //   isValid = false;
    //   errors.referenceImg = "Reference Image is required";
    // }

    return {
      isValid,
      errors,
    };
  };

  // const handleSubmit = () => {
  //   setFormErrors(null);
  //   let check = validate(formValues);
  //   setFormErrors(check.errors);
  //   if (check.isValid) {
  //     const formData = new FormData();
  //     files.forEach((file, index) => {
  //         formData.append(`specsheet[${index}]`, file);
  //     });
  //     formData.append("type", formValues.type)
  //     formData.append("sample_collection", formValues.sample_collection)
  //     formData.append("sample_cat_id", formValues.sample_cat_id)
  //     formData.append("cost_price_from", formValues.cost_price_from)
  //     formData.append("cost_price_to", formValues.cost_price_to)
  //     formData.append("fast_track", formValues.fast_track)
  //     dispatch(getsampleRequestCheck(formData));
  //     // setSampleNode(true)
  //   }
  // }
  const handleSubmit = () => {
    setFormErrors(null);
    let check = validate(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
      // if (
      //   parseFloat(formValues.cost_price_from) >=
      //   parseFloat(formValues.cost_price_to)
      // ) {
      //   setFormErrors({
      //     cost_price_to: "Cost Price To must be greater than Cost Price From",
      //   });
      // } else {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`specsheet[${index}]`, file);
      });
      formData.append("type", formValues.type);
      if (formValues.party_name) {
        formData.append("party_name", formValues.party_name);
      }
      formData.append("sample_collection", formValues.sample_collection);
      formData.append("sample_cat_id", formValues.sample_cat_id);
      formData.append("fast_track", formValues.fast_track);
      formData.append("categorie_id", formValues.categorie_id);
      formData.append("product_group_id", formValues.product_group_id);
      formData.append("garment_fit_id", formValues.garment_fit_id);
      formData.append("brand_id", formValues.brand_id);
      formData.append("sample_cost_range_id", formValues.sample_cost_range_id);
      formData.append("order_qty", formValues.order_qty);
      formData.append("fabric_kind", formValues.fabric_kind);
      formData.append("gender", formValues.gender);
      formData.append("fabric_id", formValues.fabric_id);
      formData.append(
        "reference_url",
        referenceUrl?.map((item) => item).join(", ")
      );
      formData.append("parties_id", formValues.parties_id);
      formData.append("reference_user_by", formValues.reference_user_by);

      sizeData.forEach((size, index) => {
        formData.append(`size[${index}][id]`, size.id);
        formData.append(`size[${index}][quantity]`, size.quantity);
      });
      formData.append("reference_image", referenceImg[0]);

      dispatch(getsampleRequestCheck(formData));
    }
    // }
  };

  useEffect(() => {
    if (sampleRequestCheck.isSuccess) {
      dispatch(resetsampleRequestCheckState());
      setSampleNode(true);
    }
    // eslint-disable-next-line
  }, [sampleRequestCheck]);

  // console.log("FORMVALUES:", sampleRequestShow);

  const location = useLocation();
  const routeName = location.pathname;

  // console.log("ROUTE NAME: ", routeName);

  useEffect(() => {
    if (routeName === "/sample-request-add") {
      // Reset the form fields for 'add' mode
      setFormValue({
        type: "",
        sample_cat_id: "",
        sample_collection: "",
        categorie_id: "",
        fast_track: "",
        product_group_id: "",
        garment_fit_id: "",
        brand_id: "",
        sample_cost_range_id: "",
        order_qty: "",
        fabric_kind: "",
        gender: "",
        fabric_id: "",
        parties_id: "",
        reference_user_by: "",
        party_name: "",
      });
      setReferenceUrl([""]);
      setSizeData([]);
      setSizes([]);
    } else {
      if (sampleRequestShow && sampleRequestShow?.isSuccess) {
        let url_comma = sampleRequestShow?.data?.reference_url
          ?.split(",")
          .map((item) => item.trim());
        setReferenceUrl(url_comma);
        setFormValue((prevValues) => ({
          ...prevValues,
          type: sampleRequestShow?.data?.type || prevValues.type,
          sample_cat_id:
            sampleRequestShow?.data?.sample_cat_id || prevValues.sample_cat_id,
          sample_collection:
            sampleRequestShow?.data?.sample_collection_id ||
            prevValues.sample_collection,
          categorie_id:
            sampleRequestShow?.data?.categorie_id || prevValues.categorie_id,
          fast_track:
            sampleRequestShow?.data?.fast_track || prevValues.fast_track,
          product_group_id:
            sampleRequestShow?.data?.product_group_id ||
            prevValues.product_group_id,
          garment_fit_id:
            sampleRequestShow?.data?.garment_fit_id ||
            prevValues.garment_fit_id,
          brand_id: sampleRequestShow?.data?.brand_id || prevValues.brand_id,
          sample_cost_range_id:
            sampleRequestShow?.data?.sample_cost_range_id ||
            prevValues.sample_cost_range_id,
          order_qty: sampleRequestShow?.data?.order_qty || prevValues.order_qty,
          fabric_kind:
            sampleRequestShow?.data?.fabric_kind || prevValues.fabric_kind,
          gender: sampleRequestShow?.data?.gender || prevValues.gender,
          fabric_id: sampleRequestShow?.data?.fabric_id || prevValues.fabric_id,
          parties_id:
            sampleRequestShow?.data?.parties_id || prevValues.parties_id,
          reference_user_by:
            sampleRequestShow?.data?.reference_user_By ||
            prevValues.reference_user_by,
          party_name:
            sampleRequestShow?.data?.party_name || prevValues.party_name,
        }));
      }
    }
  }, [sampleRequestShow, routeName]);

  useEffect(() => {
    if (sampleRequestShow && sampleRequestShow?.isSuccess === true) {
      const token = getToken();
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios
        .post(
          BASE_URL +
            `/sample/single_category_size_ratio_list/${sampleRequestShow?.data?.categorie_id}`,
          {},
          { headers }
        )
        .then((response) => {
          const showSizes = sampleRequestShow?.data?.sample_size?.map(
            (size) => ({
              id: size.size_id,
              quantity: size.quantity,
            })
          );

          // console.log("MATCH SIZES: ", showSizes);

          // console.log("RESPONSE: ", response?.data?.size);
          // const sizes =
          //   response?.data?.size?.map((size) => ({
          //     id: size.id,
          //     name: size.name,
          //     quantity: size.ratio,
          //   })) || [];

          const sizes =
            response?.data?.size
              ?.filter((size) => size.ratio > 0) // Filter sizes with ratio > 0
              .map((size) => ({
                id: size.id,
                name: size.name,
                quantity: size.ratio,
              })) || [];

          // console.log("SIZES: ", sizes);

          const updatedSizes = sizes.map((size) => {
            // Find the corresponding size in showSizes
            const matchingSize = showSizes.find(
              (showSize) => parseInt(showSize.id) === parseInt(size.id)
            );

            // If a matching size is found, update the quantity
            if (matchingSize) {
              return {
                ...size,
                quantity: matchingSize.quantity,
              };
            }

            // If no matching size is found, return the original size
            return size;
          });

          // console.log("UPDATED SIZEs: ", updatedSizes);

          setSizes(updatedSizes);
          setSizeData(updatedSizes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [sampleRequestShow]);

  // console.log("DATA: ", sampleRequestShow);

  const filterName = (value, arr, liable, nonInteger) => {
    // console.log(value, arr, liable, nonInteger, "sss");
    if (liable) {
      return arr.filter((i) => parseInt(i.id) === parseInt(value));
    } else if (nonInteger) {
      return arr.filter((i) => i.value === value);
    } else {
      return arr.filter((i) => parseInt(i.value) === parseInt(value));
    }
  };

  // console.log("SIZES: ", sizes);

  //  Sample Request Update State
  const SampleReqUpdate = useSelector((state) => state.SampleReqUpdate);

  const [isUpdated, setIsUpdated] = useState(false);

  const handleUpdate = async () => {
    // console.log("ID: ", id);
    // console.log("TYPE: ", formValues.type);
    // console.log("SAMPLE COLLECTION: ", formValues.sample_collection);
    // console.log("SAMPLE CAT ID: ", formValues.sample_cat_id);
    // console.log("FAST TRACK: ", formValues.fast_track);
    // console.log("CATEGORIE ID: ", formValues.categorie_id);
    // console.log("GARMENT FIT ID: ", formValues.garment_fit_id);
    // console.log("BRAND ID: ", formValues.brand_id);
    // console.log("SAMPLE COST RANGE ID: ", formValues.sample_cost_range_id);
    // console.log("FABRIC ID: ", formValues.fabric_id);
    // console.log("PRODUCT GROUP ID: ", formValues.product_group_id);
    // console.log("ORDER QTY: ", formValues.order_qty);
    // console.log("GENDER: ", formValues.gender);
    // console.log("FABRIC ID: ", formValues.fabric_id);
    // console.log("SIZE: ", sizeData);

    setFormErrors(null);
    let check = validate(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
      setIsUpdated(true);
      const formData = new FormData();

      formData.append("id", id);
      files.forEach((file, index) => {
        formData.append(`specsheet[${index}]`, file);
      });
      if (formValues.party_name) {
        formData.append("party_name", formValues.party_name);
      }
      formData.append("type", formValues.type);
      formData.append("sample_collection", formValues.sample_collection);
      formData.append("sample_cat_id", formValues.sample_cat_id);
      formData.append("fast_track", formValues.fast_track);
      formData.append("categorie_id", formValues.categorie_id);
      formData.append("product_group_id", formValues.product_group_id);
      formData.append("garment_fit_id", formValues.garment_fit_id);
      formData.append("brand_id", formValues.brand_id);
      formData.append("sample_cost_range_id", formValues.sample_cost_range_id);
      formData.append("order_qty", formValues.order_qty);
      formData.append("fabric_kind", formValues.fabric_kind);
      formData.append("gender", formValues.gender);
      formData.append("fabric_id", formValues.fabric_id);
      formData.append(
        "reference_url",
        referenceUrl?.map((item) => item).join(", ")
      );
      formData.append("parties_id", formValues.parties_id);
      formData.append("reference_user_by", formValues.reference_user_by);

      sizeData.forEach((size, index) => {
        formData.append(`size[${index}][id]`, size.id);
        formData.append(`size[${index}][quantity]`, size.quantity);
      });
      if (referenceImg[0] !== undefined) {
        formData.append("reference_image", referenceImg[0]);
      }

      dispatch(SampleRequestUpdate(formData));
    }
  };

  // console.log("SAMPLE REQUEST UPDATE: ", SampleReqUpdate);

  useEffect(() => {
    if (SampleReqUpdate && SampleReqUpdate?.isSuccess === true && isUpdated) {
      toast.success("Updated Successfully");
      setIsUpdated(false);
    } else if (
      SampleReqUpdate &&
      SampleReqUpdate?.isSuccess === false &&
      isUpdated
    ) {
      toast.error(SampleReqUpdate?.errorMessage);
      setIsUpdated(false);
    }
  }, [SampleReqUpdate, isUpdated]);

  // console.log("ID: ", id);

  // console.log("sampleRequestCheck", sampleRequestCheck);

  console.log("FORMVALUES: ", id);

  return (
    <>
      {sapleNode === true ? (
        <SampleRequestNode
          back={setSampleNode}
          formValues={formValues}
          referenceUrl={referenceUrl}
          files={files}
          cat_id={formValues.sample_cat_id}
          sizeData={sizeData}
          referenceImg={referenceImg}
        />
      ) : (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {id === null || id === undefined
                ? "Sample Request Add | Sample Dashboard"
                : "Sample Request Edit | Sample Dashboard"}
            </title>
          </Helmet>
          <div className="d-flex align-items-baseline justify-content-between pb-2">
            <div className="heading">
              <h2>
                {id === null || id === undefined
                  ? "Sample Request Add"
                  : "Sample Request Edit"}
              </h2>
            </div>
          </div>
          <div className="bg-white px-5 py-4 sample-request-add">
            <div className="row">
              <div className="col-md-4">
                <label className="select-label-bold">Type</label>
                <Select
                  value={formValues.type}
                  defaultValue="Select Type"
                  onChange={(e) => handleChange("type", e)}
                  options={[
                    { id: "Internal", value: "Internal" },
                    { id: "External", value: "External" },
                  ]}
                  // showSearch
                  loading={Loading}
                  optionFilterProp="children"
                />
                <span className="error-msg">{formErrors.type}</span>
              </div>
              {formValues.type === "External" && (
                <div className="col-md-4">
                  <label className="select-label-bold">Party Name</label>
                  <input
                    value={formValues.party_name}
                    placeholder="Party Name"
                    className="input-custom form-control"
                    onChange={(e) => handleChange("party_name", e.target.value)}
                  />
                  <span className="error-msg">{formErrors.party_name}</span>
                </div>
              )}
              <div className="col-md-4">
                <label className="select-label-bold">Sample Category</label>
                <Select
                  value={filterName(
                    formValues.sample_cat_id,
                    sampleCategoriesOption
                  )}
                  disabled={id != null || id != undefined ? true : false}
                  defaultValue="Sample Category"
                  onChange={(e) => handleChange("sample_cat_id", e)}
                  options={sampleCategoriesOption}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.sample_cat_id}</span>
              </div>
              <div className="col-md-4">
                <label className="select-label-bold">
                  Sample Collection Name
                </label>
                <Select
                  value={filterName(
                    formValues.sample_collection,
                    sampleCollectionOption
                  )}
                  defaultValue="Select Collection"
                  onChange={(e) => handleChange("sample_collection", e)}
                  disabled={id != null || id != undefined ? true : false}
                  options={sampleCollectionOption}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">
                  {formErrors.sample_collection}
                </span>
              </div>
              {/* Addition select Box */}
              <div className="col-md-4">
                <label className="select-label-bold">Product category</label>
                <Select
                  value={filterName(formValues.categorie_id, productCatArr)}
                  defaultValue="Product Category"
                  onChange={(e) => handleChange("categorie_id", e)}
                  options={productCatArr}
                  disabled={id != null || id != undefined ? true : false}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.categorie_id}</span>
              </div>
              {/* Addition select Box */}
              {/* <div className="col-md-4">
                <label className="select-label-bold">Cost Price From</label>
                <input
                  placeholder="Cost Price From"
                  type="number"
                  min="0"
                  className="input-custom form-control"
                  onChange={(e) =>
                    handleChange("cost_price_from", e.target.value)
                  }
                />
                <span className="error-msg">{formErrors.cost_price_from}</span>
              </div>
              <div className="col-md-4">
                <label className="select-label-bold">Cost Price To</label>
                <input
                  placeholder="Cost Price To"
                  type="number"
                  min="0"
                  className="input-custom form-control"
                  onChange={(e) =>
                    handleChange("cost_price_to", e.target.value)
                  }
                />
                <span className="error-msg">{formErrors.cost_price_to}</span>
              </div> */}
              <div className="col-md-4">
                <label className="select-label-bold">Fast Track</label>
                <Select
                  value={
                    parseInt(formValues.fast_track) === 0
                      ? "No"
                      : parseInt(formValues.fast_track) === 1
                      ? "Yes"
                      : formValues.fast_track
                  }
                  // defaultValue={filterName(
                  //   formValues.fast_track,
                  //   Liable_arr,
                  //   true
                  // )}
                  onChange={(e) => handleChange("fast_track", e)}
                  options={Liable_arr}
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.fast_track}</span>
              </div>
              {/* Addition select Box */}
              <div className="col-md-4">
                <label className="select-label-bold">
                  Style / Product Group
                </label>
                <Select
                  value={filterName(
                    formValues.product_group_id,
                    productGroupArr
                  )}
                  disabled={id != null || id != undefined ? true : false}
                  defaultValue="Style / Product Group"
                  onChange={(e) => handleChange("product_group_id", e)}
                  options={productGroupArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.product_group_id}</span>
              </div>
              {/* Addition select Box */}
              {/* Addition select Box */}
              <div className="col-md-4">
                <label className="select-label-bold">Fit Selection</label>
                <Select
                  value={filterName(formValues.garment_fit_id, fitSelectionArr)}
                  defaultValue="Fit Selection"
                  onChange={(e) => handleChange("garment_fit_id", e)}
                  options={fitSelectionArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.garment_fit_id}</span>
              </div>
              <div className="col-md-4">
                <label className="select-label-bold">Brand</label>
                <Select
                  value={filterName(formValues.brand_id, brandArr)}
                  defaultValue="Brand"
                  onChange={(e) => handleChange("brand_id", e)}
                  options={brandArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.brand_id}</span>
              </div>
              <div className="col-md-4">
                <label className="select-label-bold">Cost Range</label>
                <Select
                  value={filterName(
                    formValues.sample_cost_range_id,
                    costRangeArr
                  )}
                  defaultValue="Cost Range"
                  onChange={(e) => handleChange("sample_cost_range_id", e)}
                  options={costRangeArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">
                  {formErrors.sample_cost_range_id}
                </span>
              </div>

              {/* Order Qty */}
              <div className="col-md-4">
                <label className="select-label-bold">Order Qty</label>
                <input
                  type="number"
                  className="input-style"
                  placeholder="Enter Order Qty"
                  value={formValues.order_qty}
                  min={0}
                  onChange={(e) => handleChange("order_qty", e.target.value)}
                />
                <span className="error-msg">{formErrors.order_qty}</span>
              </div>

              {/* Fabric Kind */}
              <div className="col-md-4">
                <label className="select-label-bold">Fabric Kind</label>
                <Select
                  value={filterName(
                    formValues.fabric_kind,
                    fabricKindArr,
                    null,
                    true
                  )}
                  defaultValue="Fabric Kind"
                  onChange={(e) => handleChange("fabric_kind", e)}
                  options={fabricKindArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.fabric_kind}</span>
              </div>
              {/* Fabric Kind */}

              <div className="col-md-4">
                <label className="select-label-bold">Gender</label>
                <Select
                  value={filterName(formValues.gender, genderArr, null, true)}
                  defaultValue="Gender"
                  onChange={(e) => handleChange("gender", e)}
                  options={genderArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.gender}</span>
              </div>

              <div className="col-md-4">
                <label className="select-label-bold">Fabric Name</label>
                <Select
                  value={filterName(formValues.fabric_id, fabricNameArr)}
                  defaultValue="Fabric Name"
                  onChange={(e) => handleChange("fabric_id", e)}
                  options={fabricNameArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.fabric_id}</span>
              </div>

              <div className="col-md-4">
                <label className="select-label-bold">
                  Reference Party Name
                </label>
                <Select
                  value={filterName(formValues.parties_id, partiesArr)}
                  defaultValue="Reference Party Name"
                  onChange={(e) => handleChange("parties_id", e)}
                  options={partiesArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.fabric_id}</span>
              </div>

              <div className="col-md-4">
                <label className="select-label-bold">Reference By</label>
                <Select
                  value={filterName(formValues.reference_user_by, usersArr)}
                  defaultValue="Reference By"
                  onChange={(e) => handleChange("reference_user_by", e)}
                  options={usersArr}
                  showSearch
                  loading={Loading}
                  optionFilterProp="children"
                  filterOption={(input, sampleTrailOption) =>
                    (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(sampleTrailOption) =>
                    (sampleTrailOption?.label ?? "")
                      .toLowerCase()
                      .localeCompare(
                        (sampleTrailOption?.label ?? "").toLowerCase()
                      )
                  }
                />
                <span className="error-msg">{formErrors.fabric_id}</span>
              </div>

              <div className="row">
                {sizes.map((size, index) => (
                  <div
                    key={index}
                    className="input-create-fit col-md-2 col-lg-2 col-2 mb-2"
                  >
                    <div className="label-row">
                      <label htmlFor="" className="select-label-bold">
                        {size.name}
                      </label>
                    </div>
                    <input
                      type="text"
                      placeholder="Fit Reference URL"
                      value={sizeData[index].quantity}
                      onChange={(e) => handleInputSizeChange(index, e)}
                    />
                  </div>
                ))}
              </div>

              <div className="row">
                {referenceUrl &&
                  referenceUrl.map((i, j) => (
                    <div
                      key={j}
                      className="input-create-fit col-md-3 col-lg-4 col-12 mb-2"
                    >
                      <div className="label-row">
                        <label htmlFor="" className="select-label-bold">
                          Fit Reference URL
                        </label>
                        <Button
                          style={{
                            backgroundColor: index.includes(j)
                              ? "rgb(255, 0, 0,0.5)"
                              : "#4549e847",
                          }}
                          onClick={() =>
                            index.includes(j)
                              ? handleRemoveClick(j)
                              : handleAdd(j)
                          }
                          className="plus-icon"
                        >
                          {index.includes(j) ? (
                            <MinusOutlined style={{ fontSize: "10px" }} />
                          ) : (
                            <PlusOutlined style={{ fontSize: "10px" }} />
                          )}
                        </Button>
                      </div>{" "}
                      <input
                        type="text"
                        onChange={(e) => handleInputChange(j, e.target.value)}
                        placeholder="Fit Reference URL"
                        value={i}
                      />
                    </div>
                  ))}
              </div>

              {/* Addition select Box */}
              <div className="col-md-4">
                <label className="select-label-bold">Spec Sheet (Image)</label>
                <div {...getRootProps({ className: "upload-file" })}>
                  <input
                    {...getInputProps()}
                    multiple
                    onChange={handleFileChange}
                  />
                  {files.length === 0 ? (
                    <div>
                      <img src="/Images/cloud.svg" alt="" />
                      <h5>Upload Image</h5>
                      <p>
                        Drag and Drop file here Or <b>Choose file</b>
                      </p>
                      <small>
                        Support formats: JPG, PNG | Minimum size: 5 MB
                      </small>
                    </div>
                  ) : (
                    <></>
                  )}
                  <aside style={thumbsContainer}>{thumbs}</aside>
                </div>
                <span className="error-msg">{formErrors.files}</span>
              </div>

              <div className="col-md-4">
                <label className="select-label-bold">
                  Reference Image Upload
                </label>
                <div {...getRootPropsRef({ className: "upload-file" })}>
                  <input {...getInputPropsRef()} />
                  {referenceImg.length === 0 ? (
                    <div>
                      <img src="/Images/cloud.svg" alt="" />
                      <h5>Upload Image</h5>
                      <p>
                        Drag and Drop file here Or <b>Choose file</b>
                      </p>
                      <small>
                        Support formats: JPG, PNG | Minimum size: 5 MB
                      </small>
                    </div>
                  ) : (
                    <></>
                  )}
                  <aside style={thumbsContainer}>{referenceThumbs}</aside>
                </div>
                <span className="error-msg">{formErrors.referenceImg}</span>
              </div>
            </div>
            <Fragment>
              {id !== null && id !== undefined ? (
                <div className="remark-buttons">
                  <Button onClick={handleUpdate} className="dark-but">
                    Update
                  </Button>
                </div>
              ) : (
                <>
                  <div className="remark-buttons">
                    <button
                      className="dark-but"
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      {sampleRequestCheck?.isLoading ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </>
              )}
            </Fragment>
          </div>
        </div>
      )}
    </>
  );
};

export default SampleRequestAdd;
