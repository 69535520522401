import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableHead, Typography } from "@material-ui/core";
import "./Table.styles.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { IMAGE_URL } from "../../BaseUri/BaseUri";

const DataTable = React.memo(
  ({
    header,
    garments,
    costRangeList,
    PreviewSKU,
    Batch_Preview,
    garmentFitShow,
    isVariation,
    masterFitData,
    itemsPerPage,
    current,
    new_launches_arr,
  }) => {
    const navigate = useNavigate();
    const parseUrls = (urls) => {
      if (typeof urls !== "string") {
        return [];
      }
      return urls.split(", ").map((url) => url.trim());
    };

    // console.log("COST RANGE LIST: ", costRangeList);
    return (
      <Fragment>
        <TableContainer>
          <Table
            className={`table ${isVariation ? "variation-row" : ""}`}
            aria-label="dynamic table"
          >
            {/* Table Head */}
            <TableHead>
              <TableRow>
                {header &&
                  header.map((i, index) => (
                    <TableCell key={index}>{i}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            {/* Table Head */}
            <TableBody>
              {/* Garments Fit Table component starts here */}
              {garments &&
                garments?.map((i, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {index + 1 + itemsPerPage * (current - 1)}
                    </TableCell>
                    <TableCell>{i?.fit_name}</TableCell>
                    <TableCell>{i?.categorie?.name}</TableCell>
                    <TableCell>{i?.fit_type?.name}</TableCell>
                    <TableCell>
                      {/* <Link to={`/${i?.fitRefUrl}`}>{i?.fitRefUrl}</Link> */}
                      {parseUrls(i?.reference_url).map((url, urlIndex) => (
                        <div key={urlIndex}>
                          <Link to={`${url}`} target="_blank">
                            {url}
                          </Link>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>{i?.size?.code}</TableCell>
                    <TableCell>{i?.fabric_kind}</TableCell>
                    <TableCell>
                      {i?.created_by?.firstname || ""}{" "}
                      {i.created_by?.lastname || ""}
                      <br />
                      {i?.created}
                    </TableCell>
                    <TableCell>{i?.measurement_count}</TableCell>
                    <TableCell>
                      <div className="garment-cta-container">
                        <Button
                          onClick={() => navigate(`/create-new-fit/${i.id}`)}
                        >
                          Edit
                        </Button>
                        <Button>View Media</Button>
                        <Button>View Chart</Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              {/* Garments Fit Table component ends here */}

              {/* Cost Range Component starts here */}
              {costRangeList &&
                costRangeList?.map((i, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {index + 1 + itemsPerPage * (current - 1)}
                    </TableCell>
                    <TableCell>{i?.name}</TableCell>
                    <TableCell>{i?.product_category?.name}</TableCell>
                    <TableCell>
                      {i?.created_by?.firstname} {i?.created_by?.lastname}
                    </TableCell>
                    <TableCell>{new Date(i?.created).toDateString()}</TableCell>
                  </TableRow>
                ))}
              {/* Cost Range Component ends here */}

              {/* Preview SKU Component starts here */}
              {PreviewSKU && PreviewSKU?.length > 0
                ? PreviewSKU?.map((i, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {JSON.parse(i?.image)
                          .filter((item) => item.select === true)
                          .map((img, imageIndex) => (
                            <img
                              className="image-icons"
                              key={imageIndex}
                              src={IMAGE_URL + img.url}
                              alt=""
                            />
                          ))}
                      </TableCell>
                      <TableCell>{i?.product_group}</TableCell>
                      <TableCell>{i?.color}</TableCell>
                      <TableCell>{i?.sku}</TableCell>
                    </TableRow>
                  ))
                : null}
              {/* Preview SKU Component ends here */}

              {/* Preview Batch SKU Component starts here */}
              {Batch_Preview && Batch_Preview?.length > 0
                ? Batch_Preview?.map((i, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {JSON.parse(i?.image)
                          ?.filter((item) => item.select === true)
                          ?.map((img, imageIndex) => (
                            <img
                              className="image-icons"
                              key={imageIndex}
                              src={IMAGE_URL + img.url}
                              alt=""
                            />
                          ))}
                      </TableCell>
                      <TableCell>{i?.sku}</TableCell>
                      <TableCell>{i?.qty}</TableCell>
                      <TableCell>{i?.size}</TableCell>
                      <TableCell>{i?.color}</TableCell>
                    </TableRow>
                  ))
                : null}
              {/* Preview Batch SKU Component ends here */}

              {/* Garment Fit Show component starts here */}
              {garmentFitShow && garmentFitShow?.length > 0
                ? garmentFitShow?.map((i, index) => (
                    <TableRow className="garment-fit-show" key={index}>
                      <TableCell>
                        <div>{i?.name}</div>
                      </TableCell>
                      <TableCell>
                        <div>{i?.point_form}</div>
                      </TableCell>
                      <TableCell>
                        <div>{i?.point_to}</div>
                      </TableCell>
                      <TableCell>
                        <div>{i?.measurements_value}</div>
                      </TableCell>
                      <TableCell>
                        <div>{i?.grading}</div>
                      </TableCell>
                      <TableCell>
                        <div>{i?.remark}</div>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
              {/* Garment Fit Show component ends here */}

              {/* Master Fit Show Component starts here */}
              {masterFitData && masterFitData?.length > 0
                ? masterFitData?.map((i, index) => {
                    const urlsArray = i.reference_url
                      .split(",")
                      .map((url) => url.trim());
                    return (
                      <TableRow className="garment-fit-show" key={index}>
                        <TableCell>
                          {index + 1 + itemsPerPage * (current - 1)}
                        </TableCell>
                        <TableCell>{i.fit_name}</TableCell>
                        <TableCell>{i?.categorie?.name}</TableCell>
                        <TableCell>
                          {urlsArray.map((url, urlIndex) => (
                            <React.Fragment key={urlIndex}>
                              <Link to={url}>{url}</Link>
                              {urlIndex < urlsArray.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </TableCell>
                        <TableCell>{i?.size?.code}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => navigate(`/master-fit/${i.id}`)}
                            className="edit-cta"
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              {/* Master Fit Show Component ends here */}

              {new_launches_arr?.map((item, index) => (
                <React.Fragment key={item.sample_request_id}>
                  <TableRow>
                    <TableCell rowSpan={item.other.length || 1}>
                      {index + 1}
                    </TableCell>
                    <TableCell rowSpan={item.other.length || 1}>
                      <img
                        src={IMAGE_URL + item.image}
                        alt={item.base_info.sample_request_no}
                        style={{ width: "100px" }}
                      />
                    </TableCell>
                    <TableCell rowSpan={item.other.length || 1}>
                      <span>{item.base_info.sample_request_no}</span>
                      <span>{item.base_info.group_name}</span>
                      <span>{item.base_info.name}</span>
                      <span>{item.base_info.name1}</span>
                    </TableCell>
                    {/* Render the first item in `other` */}
                    {item.other.length > 0 && (
                      <>
                        <TableCell>{item.other[0].phase}</TableCell>
                        <TableCell>{item.other[0].qty}</TableCell>
                        <TableCell>{item.other[0].color_ratio}</TableCell>
                        <TableCell>
                          {item.other[0].inventory_date_and_qty}
                        </TableCell>
                        <TableCell>{item.other[0].qty_current_node}</TableCell>
                        <TableCell>
                          <Link>
                            Sales: {item.other[0].sale_and_return.sale} |
                            Returns: <br />
                            {item.other[0].sale_and_return.return} | Final
                            Inventory: <br />
                            {item.other[0].sale_and_return.final_inventry}
                          </Link>
                        </TableCell>
                      </>
                    )}
                    {item.other.length === 0 && (
                      <TableCell colSpan={6}>No Data Available</TableCell>
                    )}
                  </TableRow>
                  {/* Render additional rows for remaining items in `other` */}
                  {item.other.slice(1).map((phase, phaseIndex) => (
                    <TableRow key={phaseIndex}>
                      <TableCell>{phase.phase}</TableCell>
                      <TableCell>{phase.qty}</TableCell>
                      <TableCell>{phase.color_ratio}</TableCell>
                      <TableCell>{phase.inventory_date_and_qty}</TableCell>
                      <TableCell>{phase.qty_current_node}</TableCell>
                      <TableCell>
                        <Link>
                          Sales: {phase.sale_and_return.sale} | Returns: <br />
                          {phase.sale_and_return.return} | Final Inventory:{" "}
                          <br />
                          {phase.sale_and_return.final_inventry}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
);

export default DataTable;
