import React, { useEffect, useState } from "react";
import DataTable from "../../../component/DataTable/Table.component";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGarmentList } from "../../../store/user/actions";
import Loader from "../../../component/Loader";

const Header = [
  "Sr.No.",
  "Fit Name",
  "Category",
  "Fit Type",
  <>
    Fit Reference <br /> URL
  </>,
  "Reference Size",
  <>
    Designed for <br /> Fabric Kind
  </>,
  <>
    Fit Create <br /> by and On
  </>,
  <>
    Total Number of <br /> Styles using this fit
  </>,
  "Action",
];

// const GarmentsFitArr = [
//   {
//     id: 1,
//     fitName: "Fit Name 01",
//     category: "Polo T-Shirt",
//     fitType: "Chest",
//     fitRefUrl: "www.url.com",
//     refSize: "01",
//     fabricKind: "Knitted",
//     createBy: "Rohit Joshi 14 May, 2023",
//     noStyle: "20",
//   },
//   {
//     id: 2,
//     fitName: "Fit Name 01",
//     category: "Polo T-Shirt",
//     fitType: "Chest",
//     fitRefUrl: "www.url.com",
//     refSize: "01",
//     fabricKind: "Woven",
//     createBy: "Rohit Joshi 14 May, 2023",
//     noStyle: "20",
//   },
// ];

const RequestTable = ({
  fit_name,
  categorie_id,
  fit_type_id,
  created_by,
  type_id,
  fabric_kind,
  reference_url,
}) => {
  const dispatch = useDispatch();

  const garmentFitList = useSelector((state) => state.garmentFitList);
  const [page, setPage] = useState(1);

  // const onChange = (page) => {
  //   setPage(page);
  //   dispatch(getGarmentList({ page }));
  // };

  const onChange = (page) => {
    const params = { page };

    if (fit_name) {
      params.garmentfit_id = fit_name;
    }

    if (categorie_id) {
      params.categorie_id = categorie_id;
    }

    if (fit_type_id) {
      params.fit_type_id = fit_type_id;
    }

    if (created_by) {
      params.created_by = created_by;
    }

    if (type_id) {
      params.size_id = type_id;
    }

    if (fabric_kind) {
      params.fabric_kind = fabric_kind;
    }

    if (reference_url) {
      params.reference_url = reference_url;
    }

    setPage(page);
    dispatch(getGarmentList(params));
  };

  useEffect(() => {
    dispatch(getGarmentList({ page: 1 }));
  }, [dispatch]);

  // console.log("GARMENT FIT LIST: ", garmentFitList?.data?.last_page);

  useEffect(() => {
    const params = { page: 1 };

    if (fit_name) {
      params.garmentfit_id = fit_name;
    }

    if (categorie_id) {
      params.categorie_id = categorie_id;
    }

    if (fit_type_id) {
      params.fit_type_id = fit_type_id;
    }

    if (created_by) {
      params.created_by = created_by;
    }

    if (type_id) {
      params.size_id = type_id;
    }

    if (fabric_kind) {
      params.fabric_kind = fabric_kind;
    }

    if (reference_url) {
      params.reference_url = reference_url;
    }

    if (
      fit_name ||
      categorie_id ||
      fit_type_id ||
      created_by ||
      type_id ||
      fabric_kind ||
      reference_url
    ) {
      dispatch(getGarmentList(params));
    }
  }, [
    fit_name,
    categorie_id,
    fit_type_id,
    created_by,
    type_id,
    fabric_kind,
    reference_url,
  ]);

  return (
    <div>
      <div className="table-wrapper-pagination">
        {garmentFitList?.isLoading === true ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <>
            <div className="table-container-pagination">
              <DataTable
                header={Header}
                garments={garmentFitList && garmentFitList?.data?.data}
                itemsPerPage={10}
                current={page}
              />
            </div>
            <div className="mt-3">
              <Pagination
                current={page}
                onChange={onChange}
                total={garmentFitList?.data?.total}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestTable;
