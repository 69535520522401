import React, { useEffect, useState } from "react";
import DetailsAccordion from "./components/DetailsAccordion";
import { useDispatch, useSelector } from "react-redux";
import { getworkloadNodeData } from "../../store/user/actions";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NodeWorkload = () => {
  const workloadNodeData = useSelector((state) => state.workloadNode);
  // const doesAnyHistoryEntryExist = location.key !== "default";
  const navigate = useNavigate();

  const { ids } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getworkloadNodeData({ transactions_ids: JSON.stringify(ids.split(",")) })
    );
  }, []);

  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };
  return (
    <div className="workload-section">
      <div className="d-flex align-items-baseline pb-4">
        <div className="heading">
          <div onClick={handleGoBack}>
            <img src="/Images/arrow-left.svg" />
          </div>
        </div>
        <div className="heading px-2">
          <h2>Current Node Workload</h2>
        </div>
      </div>
      <div className="workload-scroll">
        {workloadNodeData.data.length ? (
          workloadNodeData.data.map((week) => {
            // return user.map((week)=>{
            return (
              <div className="col-lg-4 mb-4">
                <div className="accordion-box">
                  <h3>Week-{week.week}</h3>
                  <div className="hours">
                    <div className="allocate-hour">
                      <h5>Allocated Hours</h5>
                      <h6>{week.allocatedHours} Hr</h6>
                    </div>
                    <div className="allocate-hour">
                      <h5>Available Hours</h5>
                      <h6 style={{ color: "#00BB5A" }}>
                        {week.availableHours} Hr
                      </h6>
                    </div>
                    <div className="allocate-hour">
                      <h5>Pending Work Hours</h5>
                      <h6 style={{ color: "#F00" }}>{week.pandingHours} Hr</h6>
                    </div>
                    <div className="allocate-hour">
                      <h5>Task Completed Worth</h5>
                      <h6 style={{ color: "#00BB5A" }}>
                        {week.completedHours} Hr
                      </h6>
                    </div>
                    <DetailsAccordion data={week.data} />
                  </div>
                </div>
              </div>
            );
            // })
          })
        ) : (
          <>No Data</>
        )}
      </div>
    </div>
  );
};

export default NodeWorkload;
