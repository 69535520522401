import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getnodeTarget } from "../../../store/user/actions";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import TargetList from "./TargetList";
import { Tooltip } from "antd";
import { IMAGE_URL } from "../../../BaseUri/BaseUri";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const TargetReport = ({ selectBox }) => {
  const [show, setShow] = useState(false);
  const [userWorkData, setuserWorkData] = useState([]);
  // const [zoomLevel, setZoomLevel] = useState(1.0);
  const [panning, setPanning] = useState(false);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const nodeTarget = useSelector((state) => state.nodeTarget);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getnodeTarget(selectBox));
    // eslint-disable-next-line
  }, [selectBox]);

  const getInitials = (name) => {
    const words = name?.split(" ");
    const initials = words?.map((word) => word[0]);
    return initials?.join("");
  };

  const [target_name, setTargetName] = useState("");

  const workData = (data, i) => {
    // console.log("WORKDATA: ", data);
    setShow(true);
    setTargetName(i);
    setuserWorkData(data);
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === " ") {
  //       setPanning(true);
  //     }
  //   };

  //   const handleKeyUp = (event) => {
  //     if (event.key === " ") {
  //       setPanning(false);
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);
  //   window.addEventListener("keyup", handleKeyUp);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //     window.removeEventListener("keyup", handleKeyUp);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     if (panning) {
  //       const dx = event.clientX - panPosition.x;
  //       const dy = event.clientY - panPosition.y;
  //       containerRef.current.scrollTop -= dy;
  //       containerRef.current.scrollLeft -= dx;
  //       setPanPosition({ x: event.clientX, y: event.clientY });
  //     }
  //   };

  //   const handleMouseUp = () => {
  //     setPanning(false);
  //   };

  //   document.addEventListener("mousemove", handleMouseMove);
  //   document.addEventListener("mouseup", handleMouseUp);

  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //     document.removeEventListener("mouseup", handleMouseUp);
  //   };
  // }, [panning, panPosition]);

  // const handleZoom = (event) => {
  //   event.preventDefault();
  //   const delta = event.deltaY > 0 ? -0.1 : 0.1;
  //   setZoomLevel((prevZoomLevel) =>
  //     Math.max(0.5, Math.min(1.0, prevZoomLevel + delta))
  //   );
  //   // Lock horizontal scrolling
  //   containerRef.current.scrollLeft = 0;
  // };

  // const handleClick = () => {};

  // const [scales, setScales] = useState(1);

  // const handleScaleUp = () => {
  //   if (scales >= 1) {
  //     return;
  //   } else {
  //   setScales(scales + 0.05);
  //   }
  // };

  // const handleScaleDown = () => {
  //   setScales(scales - 0.05);
  // };

  // console.log(selectBox.percentage, target.target_complete);
  return (
    <div>
      {/* <div className="scale-icons">
        <button onClick={handleScaleUp}>
          <FaPlus />
        </button>
        <button onClick={handleScaleDown}>
          <FaMinus />
        </button>
      </div> */}
      {/* <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          positionX,
          positionY,
          ...rest
        }) => ( */}
      <React.Fragment>
        {/* <TransformComponent> */}
        {/* <div> */}
        {nodeTarget &&
          Array.isArray(nodeTarget?.data) &&
          nodeTarget?.data?.map((item) => (
            <div className="collar-flex">
              <div className="coller-column">
                <div className="pt-3">
                  <div className="cm" title={item.nodeName}>
                    <h6 style={{ textTransform: "capitalize" }}>
                      {getInitials(item?.nodeName)}
                    </h6>
                  </div>
                  <Tooltip title={item.nodeName}>
                    <p>{item.nodeName}</p>
                  </Tooltip>
                  <h6 className="text-ideals">
                    Ideal Target:{item.ideal_target}
                  </h6>
                  <div className="image-container">
                    {/* {item.users.map(user=><img className="image-rbc" src={process.env.REACT_APP_IMG_URL + user.profile_image_thumbnail}/>)} */}
                    {item?.users?.slice(0, 4).map((user, index) => (
                      <img
                        key={index}
                        className="image-rbc"
                        title={user.firstname}
                        src={IMAGE_URL + user.profile_image_thumbnail}
                        alt={`User ${index + 1}`}
                      />
                    ))}
                    {/* If there are more than four images, display the count of remaining images */}
                    {item?.users?.length > 4 && (
                      <div
                        className="remaining-count"
                        onClick={() => workData(item.users, item.nodeName)}
                      >
                        +{item?.users?.length - 4}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {item?.target?.map((target) => {
                // console.log(
                //   "PERCENTAGE VALUE: ",
                //   selectBox.percentage,
                //   selectBox.Topercentage,
                //   target.target_complete
                // );
                return (
                  <div
                    className={
                      target.target_complete >= selectBox.percentage &&
                      target.target_complete <= selectBox.Topercentage
                        ? "coller-column active"
                        : "coller-column"
                    }
                  >
                    <div className="target_complete">
                      <h5>{target.date}</h5>
                      <div className="d-flex pt-2">
                        <div>
                          <span>{target.assign_target}</span>
                          <h6>Assign Target</h6>
                        </div>
                        <div className="border_center"></div>
                        <div>
                          <span>{target.achieve_target}</span>
                          <h6>Achieve Target</h6>
                        </div>
                      </div>
                      <div className="target_but">
                        <Link>{target.target_complete}% Target complete</Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        <DefaultModal
          show={show}
          onHide={() => setShow(false)}
          className="person-modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <div className="modal-heading">
            <h4>{target_name}</h4>
            <div className="close-icon">
              <img
                src="/Images/closeBlack.svg"
                onClick={() => setShow(false)}
                alt=""
              />
            </div>
          </div>

          <TargetList userWorkData={userWorkData} />
        </DefaultModal>
        {/* </div> */}
        {/* </TransformComponent> */}
      </React.Fragment>
      {/* )} */}
      {/* </TransformWrapper> */}
    </div>
  );
};

export default TargetReport;
