import React, { useState, useEffect } from "react";
import SideBarLinks from "../constants/SideBarLinksAdmin.json";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "./Icon/Icon";

const LeftSideBar = () => {
  const location = useLocation();
  const [linkRefs, setLinkRefs] = useState(SideBarLinks);

  const active = (i) => {
    const updatedLinks = linkRefs.map((link, index) => ({
      ...link,
      active: index === i, // Set active for the clicked link
    }));
    setLinkRefs(updatedLinks);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const index = linkRefs.findIndex((item) => item.link === currentPath);
    if (currentPath === "/") {
      active(0); // Set the first link active for the root path
    } else if (index >= 0) {
      active(index); // Set the matched link active
    }
  }, [location.pathname]); // Add location.pathname as dependency

  return (
    <div className="sidebar_bg">
      <div className="sidbaar-scroll">
        <div className="logo-im-sidebbar">
          <Link to="/">
            <img src="/Images/sidebaar-logo.svg" alt="Sidebar Logo" />
          </Link>
        </div>
        <div className="left-side-scroll">
          {linkRefs.map((item, i) => (
            <div key={item.name} className="li-content">
              <NavLink
                to={item.link}
                onClick={() => active(i)}
                className={item.active ? "activeClass" : ""}
              >
                <Icon
                  iconName={item.icon}
                  width="22px"
                  height="22px"
                  color={item.active ? "#fff" : "#D0004B"}
                />
                <h5>{item.name}</h5>
              </NavLink>
              <div className="border_bootom"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
