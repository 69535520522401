import React, { useEffect, useState, useCallback } from "react";
import ReactFlow, {
  Controls,
  useEdgesState,
  Position,
  useNodesState,
  // addEdge,
  // fitView,
} from "reactflow";
// import { Link, useParams } from "react-router-dom";
import ToggleSwitch from "./ToggleSwitch";
// import AndOrToggleSwitch from './AndOrToggleSwitch'
import ButtonEdge from "./ButtonEdge";
import { useDispatch, useSelector } from "react-redux";
import {
  getcreateTrailData,
  getcriticalityList,
  getsampleNodeEdgeConnect,
} from "../../../store/user/actions";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../../util/Common";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { Button, Select } from "antd";
import DesignationModal from "./Modal/DesignationModal";
import { BASE_URL } from "../../../BaseUri/BaseUri";

function Flow({
  sampleNode,
  trailChange,
  categoryNodeRefresh,
  addNode,
  designations,
  TrailList,
}) {
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [prevSelectedTrailId, setPrevSelectedTrailId] = useState(null);
  const [data, setData] = useState(sampleNode);
  const [boxHeight, setBoxHeight] = useState(100);
  const [selectedTrail, setSelectedTrail] = useState(null);

  const sampleNodeEdgeConnect = useSelector(
    (state) => state.sampleNodeEdgeConnect
  );
  const dispatch = useDispatch();

  const [isRefreshed, setIsRefreshed] = useState(false);

  const ShowModal = () => {
    // toast.success("OK1");
    addNode(true);
  };

  const [isDesignationModal, setIsDesignationModal] = useState(false);
  const [id, setId] = useState("");

  const handleModal = (val, id) => {
    // console.log("HANDLE VALUE", val, id);
    setIsDesignationModal(val);
    setId(id);
  };

  // useEffect(() => {
  //   if (sampleNodeEdgeConnect.isSuccess && isRefreshed) {
  //     categoryNodeRefresh();
  //     setIsRefreshed(false);
  //   }
  // }, [sampleNodeEdgeConnect.isSuccess, isRefreshed]);

  useEffect(() => {
    setData(sampleNode);
  }, [sampleNode]);

  const criticalityList = useSelector((state) => state.criticalityList.data);

  useEffect(() => {
    dispatch(getcriticalityList({}));
  }, [dispatch]);

  console.log("CRITICALLY LIST: ", criticalityList);

  useEffect(() => {
    setNodes([]);
    setEdges([]);
    const group = [];
    var edgesgroup = [];
    var yaxis = 100;
    var i = 1;
    for (let [trail, node] of Object.entries(data)) {
      console.log("NODE DATA: ", data);
      const isSelected = selectedTrail === trail;
      group.push({
        id: "TrailTitle-" + trail,
        trailId: trail,
        data: { label: "" + trail },
        position: { x: 10, y: 5 },
        parentNode: "Trail-" + i,
        extent: "parent",
        className: isSelected ? "selected" : "",
        labelElement: <h6 className="label-style">{trail}</h6>,
      });
      var nodexaxis = 10;
      node.map((nodeData) => {
        // const leadtime = JSON.parse(nodeData?.leadtime);
        const leadtime = JSON.parse(nodeData?.leadtime || "{}");

        console.log("NODE 2 DATA: ", nodeData);
        group.push({
          id: "nodeGroup" + trail + nodeData.id,
          type: "group",
          trailId: trail,
          position: { x: nodexaxis, y: 60 },
          parentNode: "Trail-" + i,
          extent: "parent",
          selected: false,
          className: "light",
          style: {
            width: 150,
            height: 280,
            backgroundColor: "rgba(240,240,240,0.25)",
            boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
            paddingRight: 0,
            paddingLeft: 0,
            borderWidth: 0,
          },
        });
        nodexaxis += 220;
        group.push({
          id: "N" + nodeData.id,
          // type: "input",
          trailId: trail,
          data: {
            label: (
              <div
                className={`trail-col ${isSelected ? "selected" : ""}`}
                onClick={() => onElementClick(null, { trailId: trail })}
              >
                <div className="trail-img">
                  <UserNameImage
                    onData={handleModal}
                    nodeId={nodeData.id}
                    isDesignationModal={isDesignationModal}
                    name={nodeData.node.name}
                    image={nodeData.image}
                    width="150px"
                    height="150px"
                  />
                  {/* <img src={nodeData.image} /> */}
                  <h6 className="trail-status">{nodeData.node_message}</h6>
                </div>
              </div>
            ),
          },
          position: { x: 0, y: 0 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          parentNode: "nodeGroup" + trail + nodeData.id,
          extent: "parent",
          className: "light",
          style: {
            paddingRight: 0,
            paddingLeft: 0,
            borderWidth: 0,
          },
        });

        // Format the leadtime as required
        const leadtimeFormatted = Object.keys(leadtime)
          .map((key) => {
            const item = criticalityList?.find(
              (obj) => parseInt(obj.id) === parseInt(key)
            );
            const value = leadtime[key];
            return item ? `(${item.name.charAt(0).toUpperCase()}${value})` : "";
          })
          .filter((result) => result) // Filter out any empty results
          .join(", ");

        console.log("LeadTime: ", leadtimeFormatted);

        group.push({
          id: "nodebody" + nodeData.id,
          type: "input",
          trailId: trail,
          data: {
            label: (
              <div className="trail-category-text sample-nodes-category-fit">
                <h5 className="pb-2">{nodeData.node.name}</h5>
                {/* Code Here */}
                {/* <p>{nodeData?.leadtime}</p> */}

                <p className="mb-0">LT-{leadtimeFormatted}</p>
                <ToggleSwitch
                  refreshNodeData={categoryNodeRefresh}
                  id={nodeData.id}
                  status={nodeData.status == 1}
                  label={"t" + nodeData.id}
                />
              </div>
            ),
          },
          position: { x: 0, y: 160 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          parentNode: "nodeGroup" + trail + nodeData.id,
          extent: "parent",
          className: "light",
        });
        var tempedgesgroup = nodeData.todos.map((edge) => {
          var tata = [...edgesgroup];
          tata.push({
            id: "e-" + trail + nodeData.id,
            // actual_id: edge,
            source: "N" + edge.todo_after,
            target: "N" + edge.sample_category_node_id,
            animated: false,
            label: edge.type == 1 ? "AND" : "OR",
            // sourceHandle: 'a',
            style: { stroke: "#000", strokeWidth: "3px" },
            // type:"smoothstep",
            data: { label: edge.type, id: edge.id, status: edge.type == 1 },
            type: "buttonedge",
          });
          edgesgroup = tata;
          setEdges(tata);
        });
      });
      group.push({
        id: "Trail-" + i,
        type: "group",
        trailId: trail,
        position: { x: 0, y: yaxis },
        style: {
          width: nodexaxis + 220,
          height: 350,
          borderWidth: "",
          // borderWidth: selectedTrail === trail ? '2px' : '1px',
          // border: selectedTrail === trail ? '2px solid #00f' : '1px solid #f00',
          border: "1px solid #D6D6D6 !important",
          backgroundColor: "#fff !important",
          borderRadius: "4px",
          boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
          paddingRight: 15,
          paddingLeft: 15,
        },
      });
      group.push({
        id: "nodeGroup-new" + i,
        type: "group",
        trailId: trail,
        position: { x: nodexaxis, y: 60 },
        parentNode: "Trail-" + i,
        extent: "parent",
        className: "light",
        selected: false,
        style: {
          width: 150,
          height: 280,
          backgroundColor: "rgba(240,240,240,0.25)",
          boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
          paddingRight: 0,
          paddingLeft: 0,
          borderWidth: 0,
        },
      });

      group.push({
        id: "N-new" + i,
        // type: "input",
        trailId: trail,
        data: {
          label: (
            <div className="w-100">
              <div className="node-default">
                <div className="circle-bg" onClick={() => ShowModal()}>
                  <img src="/Images/plus.svg" />
                </div>
              </div>
            </div>
          ),
        },
        position: { x: 0, y: 0 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        parentNode: "nodeGroup-new" + i,
        extent: "parent",
        className: "light",
        style: {
          paddingRight: 0,
          paddingLeft: 0,
          borderWidth: 0,
        },
      });

      yaxis += 500;

      i++;
    }
    setBoxHeight(yaxis + 100);
    setNodes(group);
  }, [data, selectedTrail, criticalityList]);

  const createTrailData = useSelector((state) => state.createTrailData);

  const onElementClick = async (event, object) => {
    const selectedTrailId = object.trailId;
    // console.log("HELLO WORLD: ", object);

    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id.startsWith("Trail-")) {
          // Remove 'selected' class from the previously selected trail
          if (node.trailId === prevSelectedTrailId) {
            return {
              ...node,
              className: "notselected",
            };
          }
          // Toggle the 'selected' class for the clicked trail
          if (node.trailId === selectedTrailId) {
            return {
              ...node,
              className: "selected",
            };
          }
        }
        return node;
      })
    );

    setPrevSelectedTrailId(selectedTrailId);
    let filterId = await TrailList?.filter((i) => i.label === selectedTrailId);
    // console.log("FILTER ID: ", filterId);
    if (filterId[0]?.value === undefined) {
      dispatch(getcreateTrailData({ name: selectedTrailId }));
    } else {
      trailChange(filterId[0]?.value);
    }
  };

  // console.log("CREATE TRAIL DATA: ", createTrailData);

  useEffect(() => {
    if (createTrailData && createTrailData?.isSuccess) {
      trailChange(createTrailData && createTrailData?.data?.id);
    }
  }, [createTrailData]);

  const onConnect = useCallback((params) => {
    if (
      params.source.startsWith("N-new") ||
      params.target.startsWith("N-new")
    ) {
      // alert("Connected with not a node");
      toast.error("Connected with not a node");
    } else {
      const source = params.source.replace("N", "");
      const target = params.target.replace("N", "");
      // setIsRefreshed(true);

      axios({
        method: "POST",
        url: BASE_URL + `/sample_node_todo_connect`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        data: { source, target },
      })
        .then((response) => {
          // console.log("RESPONSE: ", response);
          if (response && response?.data?.status === 1) {
            categoryNodeRefresh();
          } else if (response && response?.data?.status === 0) {
            toast.error(response && response?.message);
          }
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          toast.error(error?.response?.data?.message);
        });

      // dispatch(getsampleNodeEdgeConnect({ source, target }));
    }
  }, []);
  const edgeTypes = {
    buttonedge: ButtonEdge,
  };

  console.log("SAMPLE NODE: ", sampleNode);

  return (
    <div>
      <div
        className="flow-section flow-section-category"
        style={{ height: boxHeight + "px" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          // minZoom={1}
          // maxZoom={1}
          onNodeClick={onElementClick}
          onConnect={onConnect}
          edgeTypes={edgeTypes}
          // fitView
        >
          <Controls />
        </ReactFlow>
      </div>

      <DefaultModal
        show={isDesignationModal}
        onHide={() => setIsDesignationModal(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Select Designation</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setIsDesignationModal(false)}
            />
          </div>
        </div>

        <DesignationModal
          setIsDesignationModal={setIsDesignationModal}
          designations={designations}
          id={id}
        />
      </DefaultModal>
    </div>
  );
}

export default Flow;
