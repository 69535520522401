import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getcostRangeWiseSample } from "../../../store/user/actions";
import Loader from "../../../component/Loader";

const CostRangeWiseSample = () => {
  const costRangeWiseSample = useSelector((state) => state.costRangeWiseSample);
  const dispatch = useDispatch();

  const [chartData, setChartData] = useState({
    series: [], // Initialize with empty array
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "26%",
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: [], // Initialize with empty array
        labels: {
          rotate: -90,
          rotateAlways: true,
          style: {
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
      },
      grid: {
        padding: {
          bottom: 30,
        },
      },
      colors: ["#962DFF", "#4A3AFF", "#E0C6FD"],
      legend: {
        position: "bottom",
        offsetY: 10,
        horizontalAlign: "center",
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    },
  });

  const optionsSelect = [
    { value: "Today", label: "Today" },
    { value: "This Week", label: "This Week" },
    { value: "Last Week", label: "Last Week" },
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
    { value: "Last 3 Month", label: "Last 3 Month" },
  ];

  const persionValue = (value) => {
    dispatch(getcostRangeWiseSample({ period: value }));
  };

  useEffect(() => {
    dispatch(getcostRangeWiseSample({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      costRangeWiseSample?.data?.costsData &&
      costRangeWiseSample?.data?.categories
    ) {
      setChartData((prevData) => ({
        ...prevData,
        series: costRangeWiseSample.data.costsData || [],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: costRangeWiseSample.data.categories || [],
          },
        },
      }));
    }
  }, [costRangeWiseSample]);

  return (
    <div className="node_wise_graph">
      <div className="wordload-card">
        <h6>Statistics</h6>
        <div className="heading_select">
          <h2>Cost Range Wise Samples</h2>
          <div className="custom-select">
            <select
              className="selectArea"
              onChange={(e) => persionValue(e.target.value)}
            >
              {optionsSelect &&
                optionsSelect.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="border-wordload"></div>
        {costRangeWiseSample?.isLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Loader />
          </div>
        ) : (
          <ReactApexChart
            options={chartData?.options}
            series={chartData?.series}
            type="bar"
            height={350}
          />
        )}
      </div>
    </div>
  );
};

export default CostRangeWiseSample;
