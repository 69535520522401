import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { apiCall, setUserSession } from "../../util/Common";
import { Helmet } from "react-helmet";
import { Button, Select } from "antd";
import LinkIcon from "@mui/icons-material/Link";
import DefaultModal from "../../component/DefaultModal/DefaultModal";

const uris__arr = [
  {
    label: "Test",
    value: "https://test-laravel.sevenrocks.in/api",
  },
  {
    label: "Staging",
    value: "https://staging-laravel.sevenrocks.in/api",
  },
];

const LoginPage = () => {
  const initialValues = {
    username: "",
    password: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const [show, setShow] = useState(false);

  const [base_uri, setBaseUri] = useState("");

  const handleBaseURL = (val) => {
    // toast.success(val);
    setBaseUri(val);
  };

  const handleSaveBaseURI = async () => {
    await localStorage.setItem("sample__module__react__uri", base_uri);
    setShow(!show);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const handleLogin = async () => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);

    if (check.isValid) {
      const response = await apiCall("/login", "POST", formValues);
      setLoading(false);
      if (response.status === 1) {
        setUserSession(response.data.token, response.data);
        history("/");
      } else if (response.status === 2) {
        history("/login");
      } else {
        setError(response.message);
        setFormErrors(response.data);
      }
    } else {
      setFormErrors(check.errors);
    }
  };

  // validation-start
  // useEffect(() => {
  //   if (Object.keys(formErrors).length > 0) {
  //   }
  // }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.username) {
      isValid = false;
      errors.username = "name is required";
    }
    if (!values.password) {
      isValid = false;
      errors.password = "password is required";
    }
    return {
      isValid,
      errors,
    };
  };
  const handleChange = (keyName, keyValue) => {
    let data = { ...formValues };
    data[keyName] = keyValue;
    setFormValue(data);
  };
  return (
    <div className="loginBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | Sample Dashboard</title>
      </Helmet>
      <div className="login-form">
        <div className="open__uri__modal">
          <Button onClick={() => setShow(!show)}>
            <LinkIcon />
          </Button>
        </div>
        <p className="base__uri__low">
          {localStorage.getItem("sample__module__react__uri") ||
            "https://staging-laravel.sevenrocks.in/api"}
        </p>
        <div className="logo-im">
          <img src="Images/logo.svg" alt="Logo" />
        </div>
        <h1>Welcome!</h1>
        <small>Please enter your log in details below......</small>
        <div>{error}</div>
        <div className="mt-4">
          <label>Username </label>
          <input
            value={formValues.username}
            onChange={(e) => handleChange("username", e.target.value)}
            type="text"
            placeholder="Username"
            className="input-box"
          />
          <span>{formErrors?.username}</span>
        </div>
        <div className="mt-4">
          <label> Password </label>
          <input
            value={formValues.password}
            onChange={(e) => handleChange("password", e.target.value)}
            type="password"
            placeholder="Enter Password"
            className="input-box"
          />
          <span>{formErrors?.password}</span>
        </div>
        <input
          className="login-but mt-4"
          type="button"
          value={loading ? "Loading..." : "Login"}
          onClick={handleLogin}
          disabled={loading}
        />
      </div>

      {/* Modal Component starts here */}
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>CHANGE BASE URL</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setShow(false)}
            />
          </div>
        </div>

        <div className="base__uri__modal__container mb-5">
          <div className="mt-4">
            <label className="label-row" htmlFor="">
              Base URL
            </label>
            <br />
            <Select
              className="mt-1"
              placeholder="Choose URL"
              options={uris__arr}
              onChange={(val) => handleBaseURL(val)}
            />
          </div>
          <Button onClick={handleSaveBaseURI}>Submit</Button>
        </div>
      </DefaultModal>
      {/* Modal Component ends here */}
    </div>
  );
};

export default LoginPage;
