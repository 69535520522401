import React, { Fragment, useEffect, useState } from "react";
import { Button, Select } from "antd";
import "../../../css/setting.css";
import { useDispatch, useSelector } from "react-redux";
import {
  MainDataTriggerAddActions,
  // MainDataTriggerListActions,
  getfilterData,
} from "../../../store/user/actions";
import { convertDropdown } from "../../CreateFit/CreateFit";
import Loader from "../../../component/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../../util/Common";
import { BASE_URL } from "../../../BaseUri/BaseUri";

const MainData = () => {
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state.filterData);

  // Main Data Add State
  const MainDataAdd = useSelector((state) => state.MainDataAdd);

  // Main Data List State
  // const MainDataList = useSelector((state) => state.MainDataList);
  const [MainDataList, setMainDataList] = useState([]);

  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  const [Sample_data, setSampleData] = useState([]);
  const [node_data, setNodeData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let Sample_form_arr = convertDropdown(filterData?.data?.sample_form);
      setSampleData(Sample_form_arr);

      let Node_Data_arr = convertDropdown(filterData?.data?.sample_nodes);
      setNodeData(Node_Data_arr);
    }
  }, [filterData]);

  const handleSelectChange = (type, nodeId, value) => {
    const newValue = {
      form_id: nodeId,
      node_id: value,
      type: type === "data" ? 0 : 1,
    };

    setSelectedValues((prevSelectedValues) => {
      // Filter out the old value for the same form_id and type
      const filteredValues = prevSelectedValues.filter(
        (val) => !(val.form_id === nodeId && val.type === newValue.type)
      );

      return [...filteredValues, newValue];
    });
  };

  const [isCreated, setisCreated] = useState(false);

  const handleSubmit = () => {
    // toast.error("FINE");
    // console.log("SELECTED VALUES: ", selectedValues);
    // const isComplete = Sample_data.every((item) =>
    //   selectedValues.find((val) => val.form_id === item.id)
    // );

    // if (!isComplete) {
    //   toast.error("Please fill in all required fields.");
    //   return;
    // }

    // console.log("SELECTED VALUES: ", selectedValues);

    // Check if any node_id is empty
    const hasEmptyNodeId = selectedValues.some((item) => !item.node_id);

    if (hasEmptyNodeId) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    const nodeIdMap = new Map();
    let hasDuplicateNodeId = false;

    selectedValues.forEach((item) => {
      if (parseInt(item.node_id) !== "") {
        if (nodeIdMap.has(parseInt(item.node_id))) {
          const existingFormId = nodeIdMap.get(parseInt(item.node_id));
          // console.log("EXISTING FORM ID: ", existingFormId, item.form_id);
          if (existingFormId !== item.form_id) {
            hasDuplicateNodeId = true;
          }
        } else {
          nodeIdMap.set(parseInt(item.node_id), item.form_id);
        }
      }
    });

    if (hasDuplicateNodeId) {
      toast.error("Each node_id must be unique across different form_id.");
      return;
    }

    setisCreated(true);
    dispatch(MainDataTriggerAddActions({ data: selectedValues }));
  };

  useEffect(() => {
    if (MainDataAdd && MainDataAdd?.isSuccess === true && isCreated) {
      toast.success("Data Created Successfully");
      // dispatch(MainDataTriggerListActions());
      setisCreated(false);
    }
  }, [MainDataAdd, isCreated]);

  const [Main_Data_Loading, setMain_Data_Loading] = useState(false);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    const FetchMainData = () => {
      setMain_Data_Loading(true);
      axios({
        method: "POST",
        url: BASE_URL + `/sample/trigger_function_node_list`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          if (response?.data?.status === 0) {
            toast.error(response?.data?.message);
            setMain_Data_Loading(false);
          } else if (response?.data?.status === 1) {
            // console.log("RESPINSE: ", response?.data);
            // toast.success("Data Saved Successfully");
            setMainDataList(response?.data?.data);
            setMain_Data_Loading(false);

            if (Array.isArray(response?.data?.data)) {
              setNewData(response?.data?.data);

              const transformedArray = response?.data?.data?.map((item) => ({
                form_id: item.form_key,
                node_id: parseInt(item.nodes),
                type: item.type,
              }));

              setSelectedValues(transformedArray);
            }
          }
        })
        .catch((error) => {
          setMain_Data_Loading(false);
          toast.error(error?.response?.data?.message);
        });
    };
    FetchMainData();
    // dispatch(MainDataTriggerListActions());
  }, [dispatch]);

  // useEffect(() => {
  //   if (MainDataList && MainDataList?.isSuccess === true) {
  //   }
  // }, [MainDataList]);

  // console.log("TRANSFORMED ARRAY: ", selectedValues);
  // console.log("MAIN DATA: ", MainDataList);

  const getInitialValue = (form_id, type) => {
    // console.log("FORM KEY: ", form_id, selectedValues);
    const item = newData.find(
      (val) => val.form_key === form_id && val.type === type
    );
    // console.log("ITEM: ", item.nodes);
    if (!item) return undefined;

    // console.log(node_data);
    // console.log(item);

    // Find the corresponding label for the node_id
    const node = node_data.find(
      (node) => parseInt(node.id) === parseInt(item.nodes)
    );
    return node ? node.label : undefined;
  };

  // console.log("SELECTED VALUES: ", selectedValues);

  return (
    <div>
      <div className="remark-buttons category-size-row">
        {filterData?.isLoading || Main_Data_Loading ? (
          <Loader />
        ) : (
          <div className="row">
            {Sample_data &&
              Sample_data.map((i) => (
                <Fragment key={i.id}>
                  <div className="col-md-4 col-lg-4 col-12 mb-2 px-4">
                    <label className="select-label-bold">{i.label} Data</label>
                    <Select
                      options={node_data}
                      defaultValue={getInitialValue(i.id, 0)}
                      placeholder={i.label + " Data"}
                      onChange={(value) =>
                        handleSelectChange("data", i.id, value)
                      }
                    />
                  </div>
                  <div className="col-md-4 col-lg-4 col-12 mb-2 px-4">
                    <label className="select-label-bold">
                      {i.label} Submit
                    </label>
                    <Select
                      options={node_data}
                      placeholder={i.label + " Submit"}
                      defaultValue={getInitialValue(i.id, 1)}
                      onChange={(value) =>
                        handleSelectChange("submit", i.id, value)
                      }
                    />
                  </div>
                </Fragment>
              ))}
            <div className="remark-buttons category-size-row">
              <Button className="dark-but" type="button" onClick={handleSubmit}>
                {MainDataAdd?.isLoading === true ? <Loader /> : "Submit"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainData;
