import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImageRequestActions } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../BaseUri/BaseUri";

const ImageViewModal = ({ mClose, imageId }) => {
  const dispatch = useDispatch();

  //   state for Image View
  const ImagerequestView = useSelector((state) => state.ImagerequestView);

  useEffect(() => {
    if (imageId) {
      dispatch(ImageRequestActions(imageId));
    }
  }, [dispatch, imageId]);

  // console.log("IMAGE DATA: ", ImagerequestView);

  return (
    <Fragment>
      {ImagerequestView?.isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <Fragment>
          <div className="image-view-grid-row">
            {ImagerequestView?.data?.image?.map((i) => (
              <Fragment key={i.id}>
                <div>
                  <img src={IMAGE_URL + i.file} alt="" />
                </div>
              </Fragment>
            ))}
          </div>
          <div className="reference-url-row">
            {ImagerequestView?.data?.reference_url &&
              ImagerequestView.data.reference_url
                .split(",")
                .map((url, index) => {
                  const trimmedUrl = url.trim();
                  // Check if the URL already starts with http:// or https://
                  const isExternal =
                    trimmedUrl.startsWith("http://") ||
                    trimmedUrl.startsWith("https://");

                  return (
                    <Link
                      key={index}
                      to={isExternal ? trimmedUrl : `http://${trimmedUrl}`} // Only add http:// if not present
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Adds security for external links
                      style={{ marginRight: "10px" }}
                    >
                      {trimmedUrl},
                    </Link>
                  );
                })}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ImageViewModal;
