import { createSlice } from "@reduxjs/toolkit";
// import { getUserList } from "./actions"
import {
  getdashboardCard,
  getcollectionData,
  getfilterData,
  getpendingData,
  getallpendingData,
  getworkloadData,
  getworkloadNodeData,
  getcollectionDelay,
  getcollectionGraph,
  getremarkData,
  getsampleNodeData,
  gethistoryData,
  getinactiveNode,
  getcategoryNode,
  getcreateTrailData,
  gettrailData,
  getaddNode,
  getresponsiblePerson,
  getsampleNodeFlowData,
  getsubmissionData,
  getassignNodeData,
  getnodeFormData,
  getprevReSubmission,
  getcalendarData,
  getmonthPending,
  getactiveNode,
  getnodeDateChange,
  getnodeSubmitData,
  getsampleNodeEdgeConnect,
  getsampleNodeEdgeType,
  getstatusSampleNode,
  getpersionWiseData,
  getnodeWiseCompletions,
  getcollectionWiseCompletion,
  getuserlistWithWorkload,
  getsubmissionDataDetails,
  getuserWorkLoadData,
  getnodeUserUpdateBulk,
  getuserListByDesignation,
  getrequestList,
  getnewCalendar,
  getnodeCriticalityUpdateBulk,
  getsampleLog,
  getrejectionList,
  getpreNodeSubmission,
  getcriticalityList,
  getcurrentNodeSubmission,
  getresubmissionRequiestStatus,
  getaddTranNode,
  getsampleTranNodeTodoConnect,
  getnodeBufferTimeChange,
  getnodeLeadTimeChange,
  getnodeStatusChange,
  getcalenderNewDetail,
  getnodeGroup,
  getrequestStatusHold,
  getimportSubmission,
  getdeadline,
  getsampleRequestAdd,
  getsampleRequestCheck,
  getsampleRequestPreview,
  getsampleCategoryList,
  getsettingParameter,
  getsampleRequestCreate,
  getsampleCategoryParameterAdd,
  getsettingNodeTarget,
  getnodeTarget,
  getsettingParameterAdd,
  getsettingParameterDelete,
  getsettingNodeTargetList,
  getGarmentList,
  getCostRangeMasterList,
  createCategorySizeRatio,
  nodeAutoSubmission,
  getnodeAutoSubmissionList,
  SendDatesRelationshipData,
  getCategorySizeDataActions,
  getSizeMasterFitActions,
  ImageColorAdditionActions,
  MainDataTriggerAddActions,
  MainDataTriggerListActions,
  getImageSelectionDatActions,
  GetProductDataActions,
  GetPreviewSkuActions,
  GetBatchLevelDataActions,
  ProductDataSubmitActions,
  IdealConsumptionsListActions,
  IdealConsumptionsAddActions,
  getcostRangeWiseSample,
  ImageRequestActions,
  getGarmentFitShow,
  getSampleRequestShow,
  getMasterFitDataShow,
  SampleRequestUpdate,
  getMasterGarmentFitUpdateActions,
  getNewLaunchesReportActions,
  AddSampleCollectionActions,
  getSingleGarmentFitActions,
} from "../user/actions";

const loaders = createSlice({
  name: "loader",
  initialState: {
    isLoading: true,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
// .reducer
export const { setLoading } = loaders.actions;
export const { loaderSlice } = loaders.reducer;

export const cardSlice = createSlice({
  name: "dashboardCard",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      totalCompletedCollection: 0,
      totalPandingCollection: 0,
      totalSampleRequestTime: 0,
      totalPartiallyPandingCollection: 0,
      totalPandingTask: 0,
      totalDelayTask: 0,
      totalCompletedTask: 0,
      totalTask: 0,
      totalReward: 0,
      totalStrike: 0,
      totalIncentive: 0,
      totalRejections: 0,
      totalCollection: 0,
      totalSampleRequest: 0,
      totalCompleteSampleRequest: 0,
      totalPandingSampleRequest: 0,
      totalDetalySampleRequest: 0,
    },
  },
  extraReducers: {
    [getdashboardCard.pending]: (state) => {
      state.isLoading = true;
    },
    [getdashboardCard.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getdashboardCard.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const tableSlice = createSlice({
  name: "collectionData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: { list: [], total_request: 0 },
  },
  extraReducers: {
    [getcollectionData.pending]: (state) => {
      state.isLoading = true;
    },
    [getcollectionData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcollectionData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const filterSlice = createSlice({
  name: "filterData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      user: {},
      sample_request: {},
      sample_collection: {},
      sample_categories: {},
      sample_nodes: {},
      sample_trail: {},
      trail: {},
      sample_form: {},
      designations: {},
      sample_bundle: {},
    },
  },
  extraReducers: {
    [getfilterData.pending]: (state) => {
      state.isLoading = true;
    },
    [getfilterData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getfilterData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const pendingSlice = createSlice({
  name: "pendingData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      task: [],
      userTask: [],
      totalTask: 0,
    },
  },
  extraReducers: {
    [getpendingData.pending]: (state) => {
      state.isLoading = true;
    },
    [getpendingData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getpendingData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const allpendingSlice = createSlice({
  name: "allpendingData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      task: [],
      userTask: [],
      totalTask: 0,
    },
  },
  extraReducers: {
    [getallpendingData.pending]: (state) => {
      state.isLoading = true;
    },
    [getallpendingData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getallpendingData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const workloadSlice = createSlice({
  name: "workloadData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getworkloadData.pending]: (state) => {
      state.isLoading = true;
    },
    [getworkloadData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getworkloadData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const workloadNodeSlice = createSlice({
  name: "workloadData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getworkloadNodeData.pending]: (state) => {
      state.isLoading = true;
    },
    [getworkloadNodeData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getworkloadNodeData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const collectionDelaySlice = createSlice({
  name: "collectionDelay",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      list: [],
      total_collection: 0,
    },
  },
  extraReducers: {
    [getcollectionDelay.pending]: (state) => {
      state.isLoading = true;
    },
    [getcollectionDelay.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcollectionDelay.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const collectionGraphSlice = createSlice({
  name: "collectionGraph",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      completedCount: 0,
      delayCount: 0,
      delayByUser: [],
    },
  },
  extraReducers: {
    [getcollectionGraph.pending]: (state) => {
      state.isLoading = true;
    },
    [getcollectionGraph.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcollectionGraph.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const RemarkSlice = createSlice({
  name: "remarkData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      sample_category_node_transaction_id: "1",
      remark: "",
      file: [],
      voice_note: "",
      modified: "",
      created: "",
      id: "",
    },
  },
  extraReducers: {
    [getremarkData.pending]: (state) => {
      state.isLoading = true;
    },
    [getremarkData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getremarkData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const sampleNodeSlice = createSlice({
  name: "sampleNodeData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsampleNodeData.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleNodeData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleNodeData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const historySlice = createSlice({
  name: "historyData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [gethistoryData.pending]: (state) => {
      state.isLoading = true;
    },
    [gethistoryData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [gethistoryData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const inactiveNodeSlice = createSlice({
  name: "inactiveNode",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getinactiveNode.pending]: (state) => {
      state.isLoading = true;
    },
    [getinactiveNode.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getinactiveNode.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const activeNodeSlice = createSlice({
  name: "activeNode",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getactiveNode.pending]: (state) => {
      state.isLoading = true;
    },
    [getactiveNode.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getactiveNode.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
const trailSlices = createSlice({
  name: "trailData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resettrailDataState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: {},
      };
    },
  },
  extraReducers: {
    [gettrailData.pending]: (state) => {
      state.isLoading = true;
    },
    [gettrailData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [gettrailData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});
export const { resettrailDataState } = trailSlices.actions;
export const trailSlice = trailSlices.reducer;

export const createTrailSlice = createSlice({
  name: "createTrailData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getcreateTrailData.pending]: (state) => {
      state.isLoading = true;
    },
    [getcreateTrailData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcreateTrailData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const categorySliceDate = createSlice({
  name: "categoryNode",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetcategoryNodeState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getcategoryNode.pending]: (state) => {
      state.isLoading = true;
    },
    [getcategoryNode.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcategoryNode.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});
export const { resetcategoryNodeState } = categorySliceDate.actions;
export const categorySlice = categorySliceDate.reducer;

export const addNodeSlice = createSlice({
  name: "addNode",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getaddNode.pending]: (state) => {
      state.isLoading = true;
    },
    [getaddNode.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getaddNode.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

const responsibleSlices = createSlice({
  name: "responsiblePerson",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      user: {},
      sample_request: {},
      sample_collection: {},
      sample_categories: {},
      sample_nodes: {},
      sample_trail: {},
      trail: {},
      sample_form: {},
      designations: {},
      sample_bundle: {},
    },
  },
  reducers: {
    resetresponsiblePersonState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: {},
      };
    },
  },
  extraReducers: {
    [getresponsiblePerson.pending]: (state) => {
      state.isLoading = true;
    },
    [getresponsiblePerson.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getresponsiblePerson.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});
export const { resetresponsiblePersonState } = responsibleSlices.actions;
export const responsibleSlice = responsibleSlices.reducer;

export const submissionSlice = createSlice({
  name: "submissionData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: { list: [], total_submission: 0, userTask: [] },
  },
  extraReducers: {
    [getsubmissionData.pending]: (state) => {
      state.isLoading = true;
    },
    [getsubmissionData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsubmissionData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

const sampleNodeFlowSliceData = createSlice({
  name: "sampleNodeFlowData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetsampleNodeFlowDataState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getsampleNodeFlowData.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleNodeFlowData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleNodeFlowData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export const { resetsampleNodeFlowDataState } = sampleNodeFlowSliceData.actions;
export const sampleNodeFlowSlice = sampleNodeFlowSliceData.reducer;

export const assignNodeSlice = createSlice({
  name: "assignNodeData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      sample_node_fields: [],
      sample_request: {},
      node: {},
      sampleNodeValues: {
        outer_fields: [],
        group_fields: [],
      },
      sample_node_values: [],
      sampleNodeFields: {
        outer_fields: [],
        group_fields: [],
      },
    },
  },
  extraReducers: {
    [getassignNodeData.pending]: (state) => {
      state.isLoading = true;
    },
    [getassignNodeData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getassignNodeData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const nodeFormSlice = createSlice({
  name: "nodeFormData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      custom_data: [],
    },
  },
  extraReducers: {
    [getnodeFormData.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeFormData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeFormData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
const prevReSubmissionSlices = createSlice({
  name: "prevReSubmission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetprevReSubmissionState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: {},
      };
    },
  },
  extraReducers: {
    [getprevReSubmission.pending]: (state) => {
      state.isLoading = true;
    },
    [getprevReSubmission.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getprevReSubmission.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});
export const { resetprevReSubmissionState } = prevReSubmissionSlices.actions;
export const prevReSubmissionSlice = prevReSubmissionSlices.reducer;

export const monthPendingSlice = createSlice({
  name: "monthPending",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getmonthPending.pending]: (state) => {
      state.isLoading = true;
    },
    [getmonthPending.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getmonthPending.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const calendarSlice = createSlice({
  name: "calendarData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getcalendarData.pending]: (state) => {
      state.isLoading = true;
    },
    [getcalendarData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcalendarData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const nodeDateChangeSlice = createSlice({
  name: "nodeDateChange",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeDateChange.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeDateChange.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeDateChange.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
const nodeSubmitDataSlices = createSlice({
  name: "nodeSubmitData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {},
  },
  reducers: {
    resetNodeSubmitDataState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: {},
      };
    },
  },
  extraReducers: {
    [getnodeSubmitData.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeSubmitData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeSubmitData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});
export const { resetNodeSubmitDataState } = nodeSubmitDataSlices.actions;
export const nodeSubmitDataSlice = nodeSubmitDataSlices.reducer;

export const statusSampleNodeSlice = createSlice({
  name: "statusSampleNode",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getstatusSampleNode.pending]: (state) => {
      state.isLoading = true;
    },
    [getstatusSampleNode.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getstatusSampleNode.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const sampleNodeEdgeTypeSlice = createSlice({
  name: "sampleNodeEdgeType",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsampleNodeEdgeType.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleNodeEdgeType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleNodeEdgeType.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const sampleNodeEdgeConnectSlice = createSlice({
  name: "sampleNodeEdgeConnect",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsampleNodeEdgeConnect.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleNodeEdgeConnect.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleNodeEdgeConnect.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const persionWiseDataSlice = createSlice({
  name: "persionWiseData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getpersionWiseData.pending]: (state) => {
      state.isLoading = true;
    },
    [getpersionWiseData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getpersionWiseData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const nodeWiseCompletionsDataSlice = createSlice({
  name: "nodeWiseCompletions",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeWiseCompletions.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeWiseCompletions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeWiseCompletions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const collectionWiseCompletionSlice = createSlice({
  name: "collectionWiseCompletion",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getcollectionWiseCompletion.pending]: (state) => {
      state.isLoading = true;
    },
    [getcollectionWiseCompletion.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcollectionWiseCompletion.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const userlistWithWorkloadSlice = createSlice({
  name: "userlistWithWorkload",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getuserlistWithWorkload.pending]: (state) => {
      state.isLoading = true;
    },
    [getuserlistWithWorkload.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getuserlistWithWorkload.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const submissionDataDetailsSlice = createSlice({
  name: "submissionDataDetails",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsubmissionDataDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getsubmissionDataDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsubmissionDataDetails.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const requestListSlice = createSlice({
  name: "requestList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      list: [],
      designation_list: [],
    },
  },
  extraReducers: {
    [getrequestList.pending]: (state) => {
      state.isLoading = true;
    },
    [getrequestList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getrequestList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const userListByDesignationSlice = createSlice({
  name: "userListByDesignation",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      list: [],
      index: 0,
    },
  },
  extraReducers: {
    [getuserListByDesignation.pending]: (state) => {
      state.isLoading = true;
    },
    [getuserListByDesignation.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getuserListByDesignation.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const userWorkLoadDataSlice = createSlice({
  name: "userWorkLoadData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      list: [],
      index: 0,
    },
  },
  extraReducers: {
    [getuserWorkLoadData.pending]: (state) => {
      state.isLoading = true;
    },
    [getuserWorkLoadData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getuserWorkLoadData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const nodeUserUpdateBulkSlice = createSlice({
  name: "nodeUserUpdateBulk",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      list: [],
      index: 0,
    },
  },
  extraReducers: {
    [getnodeUserUpdateBulk.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeUserUpdateBulk.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeUserUpdateBulk.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

const newCalendarData = createSlice({
  name: "newCalendar",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetnewCalendarState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getnewCalendar.pending]: (state) => {
      state.isLoading = true;
    },
    [getnewCalendar.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnewCalendar.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});
export const { resetnewCalendarState } = newCalendarData.actions;
export const newCalendarSlice = newCalendarData.reducer;

export const nodeCriticalityUpdateBulkSlice = createSlice({
  name: "nodeCriticalityUpdateBulk",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      list: [],
      index: 0,
    },
  },
  extraReducers: {
    [getnodeCriticalityUpdateBulk.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeCriticalityUpdateBulk.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeCriticalityUpdateBulk.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const sampleLogSlice = createSlice({
  name: "sampleLog",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      data: [],
      total: 0,
    },
  },
  extraReducers: {
    [getsampleLog.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleLog.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleLog.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const rejectionListSlice = createSlice({
  name: "rejectionList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      data: [],
      total: 0,
    },
  },
  extraReducers: {
    [getrejectionList.pending]: (state) => {
      state.isLoading = true;
    },
    [getrejectionList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getrejectionList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const preNodeSubmissionSlice = createSlice({
  name: "preNodeSubmission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getpreNodeSubmission.pending]: (state) => {
      state.isLoading = true;
    },
    [getpreNodeSubmission.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getpreNodeSubmission.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const currentNodeSubmissionSlice = createSlice({
  name: "currentNodeSubmission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getcurrentNodeSubmission.pending]: (state) => {
      state.isLoading = true;
    },
    [getcurrentNodeSubmission.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcurrentNodeSubmission.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const resubmissionRequiestStatusSlice = createSlice({
  name: "resubmissionRequiestStatus",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getresubmissionRequiestStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [getresubmissionRequiestStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getresubmissionRequiestStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const criticalityListSlice = createSlice({
  name: "criticalityList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getcriticalityList.pending]: (state) => {
      state.isLoading = true;
    },
    [getcriticalityList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcriticalityList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const addTranNodeSlice = createSlice({
  name: "addTranNode",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getaddTranNode.pending]: (state) => {
      state.isLoading = true;
    },
    [getaddTranNode.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getaddTranNode.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const sampleTranNodeTodoConnectSlice = createSlice({
  name: "sampleTranNodeTodoConnect",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsampleTranNodeTodoConnect.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleTranNodeTodoConnect.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleTranNodeTodoConnect.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const nodeBufferTimeChangeSlice = createSlice({
  name: "nodeBufferTimeChange",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeBufferTimeChange.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeBufferTimeChange.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeBufferTimeChange.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const nodeLeadTimeChangeSlice = createSlice({
  name: "nodeLeadTimeChange",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeLeadTimeChange.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeLeadTimeChange.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeLeadTimeChange.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const nodeStatusChangeSlice = createSlice({
  name: "nodeStatusChange",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeStatusChange.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeStatusChange.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeStatusChange.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const calenderNewDetailSlice = createSlice({
  name: "calenderNewDetail",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: {
      data: {},
    },
  },
  extraReducers: {
    [getcalenderNewDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [getcalenderNewDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcalenderNewDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const nodeGroupSlice = createSlice({
  name: "nodeGroup",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeGroup.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeGroup.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const requestStatusHoldSlice = createSlice({
  name: "requestStatusHold",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getrequestStatusHold.pending]: (state) => {
      state.isLoading = true;
    },
    [getrequestStatusHold.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getrequestStatusHold.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const importSubmissionSlice = createSlice({
  name: "importSubmission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getimportSubmission.pending]: (state) => {
      state.isLoading = true;
    },
    [getimportSubmission.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getimportSubmission.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;
export const deadlineSlice = createSlice({
  name: "deadline",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getdeadline.pending]: (state) => {
      state.isLoading = true;
    },
    [getdeadline.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getdeadline.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const sampleRequestPreviewSlice = createSlice({
  name: "sampleRequestPreview",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsampleRequestPreview.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleRequestPreview.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleRequestPreview.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

const sampleRequestAddSlices = createSlice({
  name: "sampleRequestAdd",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetsampleRequestAddState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getsampleRequestAdd.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleRequestAdd.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleRequestAdd.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export const { resetsampleRequestAddState } = sampleRequestAddSlices.actions;
export const sampleRequestAddSlice = sampleRequestAddSlices.reducer;

const sampleRequestCheckSlices = createSlice({
  name: "sampleRequestCheck",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetsampleRequestCheckState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getsampleRequestCheck.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleRequestCheck.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleRequestCheck.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export const { resetsampleRequestCheckState } =
  sampleRequestCheckSlices.actions;
export const sampleRequestCheckSlice = sampleRequestCheckSlices.reducer;

export const sampleCategoryListSlice = createSlice({
  name: "sampleCategoryList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: { data: [] },
  },
  extraReducers: {
    [getsampleCategoryList.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleCategoryList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleCategoryList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const settingParameterSlice = createSlice({
  name: "settingParameter",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsettingParameter.pending]: (state) => {
      state.isLoading = true;
    },
    [getsettingParameter.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsettingParameter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

const sampleRequestCreateSlices = createSlice({
  name: "sampleRequestCreate",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetsampleRequestCreateState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getsampleRequestCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleRequestCreate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleRequestCreate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export const { resetsampleRequestCreateState } =
  sampleRequestCreateSlices.actions;
export const sampleRequestCreateSlice = sampleRequestCreateSlices.reducer;

const sampleCategoryParameterAddSlices = createSlice({
  name: "sampleCategoryParameterAdd",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetsampleCategoryParameterAddState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getsampleCategoryParameterAdd.pending]: (state) => {
      state.isLoading = true;
    },
    [getsampleCategoryParameterAdd.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsampleCategoryParameterAdd.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export const { resetsampleCategoryParameterAddState } =
  sampleCategoryParameterAddSlices.actions;
export const sampleCategoryParameterAddSlice =
  sampleCategoryParameterAddSlices.reducer;

const settingNodeTargetSlices = createSlice({
  name: "settingNodeTarget",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    resetsettingNodeTargetState: (state) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
        data: [],
      };
    },
  },
  extraReducers: {
    [getsettingNodeTarget.pending]: (state) => {
      state.isLoading = true;
    },
    [getsettingNodeTarget.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsettingNodeTarget.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export const { resetsettingNodeTargetState } = settingNodeTargetSlices.actions;
export const settingNodeTargetSlice = settingNodeTargetSlices.reducer;

export const nodeTargetSlice = createSlice({
  name: "nodeTarget",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeTarget.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeTarget.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeTarget.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const settingParameterAddSlice = createSlice({
  name: "settingParameterAdd",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [{ id: null, text_value: "", input_type: "", value: "" }],
  },
  extraReducers: {
    [getsettingParameterAdd.pending]: (state) => {
      state.isLoading = true;
    },
    [getsettingParameterAdd.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsettingParameterAdd.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const settingParameterDeleteSlice = createSlice({
  name: "settingParameterDelete",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [{ id: null, text_value: "", input_type: "", value: "" }],
  },
  extraReducers: {
    [getsettingParameterDelete.pending]: (state) => {
      state.isLoading = true;
    },
    [getsettingParameterDelete.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsettingParameterDelete.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const settingNodeTargetListSlice = createSlice({
  name: "settingNodeTargetList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getsettingNodeTargetList.pending]: (state) => {
      state.isLoading = true;
    },
    [getsettingNodeTargetList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getsettingNodeTargetList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

// Get Garment List Reducer
export const getGarmentFitListSlice = createSlice({
  name: "garmentFitList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getGarmentList.pending]: (state) => {
      state.isLoading = true;
    },
    [getGarmentList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getGarmentList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

// Get Cost Range Master List
export const getCostRangeMasterListSlice = createSlice({
  name: "costRangeList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getCostRangeMasterList.pending]: (state) => {
      state.isLoading = true;
    },
    [getCostRangeMasterList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getCostRangeMasterList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

// Create Category Size Ratio
export const createCategorySizeRatioSlice = createSlice({
  name: "categoryCreate",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [createCategorySizeRatio.pending]: (state) => {
      state.isLoading = true;
    },
    [createCategorySizeRatio.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [createCategorySizeRatio.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

// Get Category Size Ratio List
// export const getCategorySizeRatioListSlice = createSlice({
//   name: "categoryRatioList",
//   initialState: {
//     isLoading: false,
//     isSuccess: false,
//     errorMessage: "",
//     data: [],
//   },
//   extraReducers: {
//     [getcategorySizeRatioList.pending]: (state) => {
//       state.isLoading = true;
//     },
//     [getcategorySizeRatioList.fulfilled]: (state, { payload }) => {
//       state.isLoading = false;
//       state.isSuccess = true;
//       state.data = payload;
//     },
//     [getcategorySizeRatioList.rejected]: (state, { payload }) => {
//       state.isLoading = false;
//       state.isSuccess = false;
//       state.errorMessage = payload;
//     },
//   },
// }).reducer;

export const nodeAutoSubmissionSlice = createSlice({
  name: "nodeAuto",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [nodeAutoSubmission.pending]: (state) => {
      state.isLoading = true;
    },
    [nodeAutoSubmission.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [nodeAutoSubmission.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getnodeAutoSubmissionSlice = createSlice({
  name: "nodeAutoList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getnodeAutoSubmissionList.pending]: (state) => {
      state.isLoading = true;
    },
    [getnodeAutoSubmissionList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getnodeAutoSubmissionList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const SendDatesRelationshipDataSlice = createSlice({
  name: "AddDatesRelationshipData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [SendDatesRelationshipData.pending]: (state) => {
      state.isLoading = true;
    },
    [SendDatesRelationshipData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [SendDatesRelationshipData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getCategorySizeDataSlice = createSlice({
  name: "getCategorySizeData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getCategorySizeDataActions.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategorySizeDataActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getCategorySizeDataActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getSizeMasterFitSlice = createSlice({
  name: "MasterSize",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getSizeMasterFitActions.pending]: (state) => {
      state.isLoading = true;
    },
    [getSizeMasterFitActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getSizeMasterFitActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const ImageColorAdditionSlice = createSlice({
  name: "ImageColorAddition",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [ImageColorAdditionActions.pending]: (state) => {
      state.isLoading = true;
    },
    [ImageColorAdditionActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [ImageColorAdditionActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const TriggerFunctionMainDataAddSlice = createSlice({
  name: "MainDataAdd",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [MainDataTriggerAddActions.pending]: (state) => {
      state.isLoading = true;
    },
    [MainDataTriggerAddActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [MainDataTriggerAddActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const TriggerFunctionMainDataListSlice = createSlice({
  name: "MainDataList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [MainDataTriggerListActions.pending]: (state) => {
      state.isLoading = true;
    },
    [MainDataTriggerListActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [MainDataTriggerListActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getImageSelectionDataSlice = createSlice({
  name: "ImageSelectionData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getImageSelectionDatActions.pending]: (state) => {
      state.isLoading = true;
    },
    [getImageSelectionDatActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getImageSelectionDatActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getProductDataSlice = createSlice({
  name: "ProductDataList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [GetProductDataActions.pending]: (state) => {
      state.isLoading = true;
    },
    [GetProductDataActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [GetProductDataActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const GetPreviewSKUSlice = createSlice({
  name: "previewSKU",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [GetPreviewSkuActions.pending]: (state) => {
      state.isLoading = true;
    },
    [GetPreviewSkuActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [GetPreviewSkuActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const GetBatchLevelDataSlice = createSlice({
  name: "BatchLevelData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [GetBatchLevelDataActions.pending]: (state) => {
      state.isLoading = true;
    },
    [GetBatchLevelDataActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [GetBatchLevelDataActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const ImageRequestViewSlice = createSlice({
  name: "ImagerequestView",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [ImageRequestActions.pending]: (state) => {
      state.isLoading = true;
    },
    [ImageRequestActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [ImageRequestActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const GetGarmentFitShowSlice = createSlice({
  name: "garmentFitShow",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getGarmentFitShow.pending]: (state) => {
      state.isLoading = true;
    },
    [getGarmentFitShow.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getGarmentFitShow.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getSampleRequestSlice = createSlice({
  name: "sampleRequestShow",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getSampleRequestShow.pending]: (state) => {
      state.isLoading = true;
    },
    [getSampleRequestShow.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getSampleRequestShow.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getMasterFitDataShowSlice = createSlice({
  name: "MasterFitShow",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getMasterFitDataShow.pending]: (state) => {
      state.isLoading = true;
    },
    [getMasterFitDataShow.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getMasterFitDataShow.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const SampleRequestUpdateSlice = createSlice({
  name: "SampleReqUpdate",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [SampleRequestUpdate.pending]: (state) => {
      state.isLoading = true;
    },
    [SampleRequestUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [SampleRequestUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const ProductDataSubmitSlice = createSlice({
  name: "ProductDataSubmit",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [ProductDataSubmitActions.pending]: (state) => {
      state.isLoading = true;
    },
    [ProductDataSubmitActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [ProductDataSubmitActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const IdealConsumptionListSlice = createSlice({
  name: "IdealConsumptionList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [IdealConsumptionsListActions.pending]: (state) => {
      state.isLoading = true;
    },
    [IdealConsumptionsListActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [IdealConsumptionsListActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const IdealConsumptionAddProductSlice = createSlice({
  name: "IdealConsumptionAddProduct",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [IdealConsumptionsAddActions.pending]: (state) => {
      state.isLoading = true;
    },
    [IdealConsumptionsAddActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [IdealConsumptionsAddActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const costRangeWiseSampleSlice = createSlice({
  name: "costRangeWiseSample",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: { costsData: [], categories: [] },
  },
  extraReducers: {
    [getcostRangeWiseSample.pending]: (state) => {
      state.isLoading = true;
    },
    [getcostRangeWiseSample.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getcostRangeWiseSample.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getMasterFitGarmentUpdateSlice = createSlice({
  name: "masterGarmentFitUpdateGet",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: { costsData: [], categories: [] },
  },
  extraReducers: {
    [getMasterGarmentFitUpdateActions.pending]: (state) => {
      state.isLoading = true;
    },
    [getMasterGarmentFitUpdateActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getMasterGarmentFitUpdateActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const getNewLaunchesReportSlice = createSlice({
  name: "newLaunchesConsolidated",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getNewLaunchesReportActions.pending]: (state) => {
      state.isLoading = true;
    },
    [getNewLaunchesReportActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getNewLaunchesReportActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
}).reducer;

export const AddSampleCollectionSlice = createSlice({
  name: "addsamplecollect",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [AddSampleCollectionActions.pending]: (state) => {
      state.isLoading = true;
    },
    [AddSampleCollectionActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [AddSampleCollectionActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      // state.errorMessage = payload;
    },
  },
}).reducer;

export const GetgarmentFitSlice = createSlice({
  name: "getgarmentFits",
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
  extraReducers: {
    [getSingleGarmentFitActions.pending]: (state) => {
      state.isLoading = true;
    },
    [getSingleGarmentFitActions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getSingleGarmentFitActions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      // state.errorMessage = payload;
    },
  },
}).reducer;
