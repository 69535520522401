import React from "react";
import Accordion from "react-bootstrap/Accordion";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import { IMAGE_URL } from "../../../BaseUri/BaseUri";

const WeekAccordion = (props) => {
  return (
    <div className="custom-accordion">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="task-icon">
            Task Details
          </Accordion.Header>
          <Accordion.Body>
            <div className="accordion_body">
              {props.data.map((collection) => {
                return (
                  <div className="child-icons">
                    <Accordion defaultActiveKey="">
                      <Accordion.Item eventKey="artical">
                        <Accordion.Header>
                          <div className="accordion_inside">
                            <div className="d-flex align-items-center">
                              <img
                                src={IMAGE_URL + collection.collectionImage}
                                alt=""
                              />
                              <div>
                                <small>Collection Name</small>
                                <h5>{collection.collectionName}</h5>
                              </div>
                            </div>
                            <h6>{collection.collectionTime} Hr</h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {collection.requests.map((request, i) => {
                            return (
                              <div>
                                <div className="border-im"></div>
                                <Accordion defaultActiveKey="">
                                  <Accordion.Item eventKey="artical-data">
                                    <Accordion.Header className="ac-but">
                                      {" "}
                                      <div className="accordion_inside">
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={
                                              IMAGE_URL + request.sampleImage
                                            }
                                            alt=""
                                          />
                                          <div>
                                            <small>Article-{i + 1}</small>
                                            <h5>{request.name}</h5>
                                          </div>
                                        </div>
                                        <h6>{request.sampleTime} Hr</h6>
                                      </div>
                                    </Accordion.Header>
                                    <div className="border-im"></div>
                                    <Accordion.Body>
                                      {request.transactions.map(
                                        (transaction) => {
                                          return (
                                            <div>
                                              <div>
                                                <div className="pattern-design">
                                                  <div
                                                    className={
                                                      transaction.status == 1 &&
                                                      transaction.intervalType ==
                                                        "before"
                                                        ? "green-border"
                                                        : transaction.status ==
                                                            1 &&
                                                          transaction.intervalType ==
                                                            "delay"
                                                        ? "red-border"
                                                        : transaction.status ==
                                                          0
                                                        ? "yellow-border"
                                                        : "gray-border"
                                                    }
                                                  ></div>
                                                  <p>{transaction.name}</p>
                                                  <div className="d-flex justify-content-between accordion-row-week">
                                                    <div className="d-flex align-items-center">
                                                      <UserNameImage
                                                        name={
                                                          transaction.userName +
                                                          " - " +
                                                          transaction.user_id
                                                        }
                                                        image={
                                                          transaction.userImage
                                                        }
                                                        width="20px"
                                                        height="20px"
                                                        margin="0 auto"
                                                        fontSize="8px"
                                                      />
                                                      {/* <img src={transaction.userImage} alt=""/> */}
                                                      <h5>
                                                        {transaction.userName +
                                                          " - " +
                                                          transaction.user_id}
                                                      </h5>
                                                    </div>
                                                    <h6>
                                                      <div
                                                        className={
                                                          transaction.status ==
                                                            1 &&
                                                          transaction.intervalType ==
                                                            "before"
                                                            ? "green-cir"
                                                            : transaction.status ==
                                                                1 &&
                                                              transaction.intervalType ==
                                                                "delay"
                                                            ? "red-cir"
                                                            : transaction.status ==
                                                              0
                                                            ? "yellow-border"
                                                            : "gray-cir"
                                                        }
                                                      >
                                                        <span>
                                                          {transaction.interval}
                                                        </span>
                                                      </div>
                                                      {transaction.time} Hr
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default WeekAccordion;
