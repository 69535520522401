import React, { useState } from "react";
import RequestSelect from "./component/RequestSelect";
import RequestTable from "./component/RequestTable";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import CreateCostRangeModal from "../Dashboard/component/CreateCostRangeModal";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const CostRangeMaster = () => {
  const [selectBox, setSelectBox] = useState({});

  const [show, setShow] = useState(false);

  // const [fit_name, setfit_name] = useState("");
  const [costrange_id, setcostrange_id] = useState("");
  const [categorie_id, setcategorie_id] = useState("");
  const [created_by, setcreated_by] = useState("");
  const [created_from, setcreated_from] = useState("");
  const [created_to, setcreated_to] = useState("");

  const [isRefreshed, setIsRefreshed] = useState(false);

  const handleRefreshedTable = () => {
    // toast.success("OK");
    setIsRefreshed(true);
    // setTimeout(() => setIsRefreshed(false), 0);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cost Range Master | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Cost Range Master</h2>
        </div>
        <div className="download-but-excel">
          <button onClick={() => setShow(!show)}>Create</button>
        </div>
      </div>
      <RequestSelect
        filterValue={setSelectBox}
        setfit_name={setcostrange_id}
        setcategorie_id={setcategorie_id}
        setcreated_by={setcreated_by}
        setcreated_from={setcreated_from}
        setcreated_to={setcreated_to}
        fit_name={costrange_id}
        categorie_id={categorie_id}
        created_by={created_by}
        created_from={created_from}
        created_to={created_to}
      />
      <RequestTable
        selectBox={selectBox}
        fit_name={costrange_id}
        categorie_id={categorie_id}
        created_by={created_by}
        created_from={created_from}
        created_to={created_to}
        isRefreshed={isRefreshed}
        setIsRefreshed={setIsRefreshed}
      />

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Create Cost Range</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              alt=""
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <CreateCostRangeModal
          handleRefreshedTable={handleRefreshedTable}
          mClose={setShow}
        />
      </DefaultModal>
    </div>
  );
};

export default CostRangeMaster;
